<script setup>
  import { reactive, defineProps, defineEmits } from "vue";

  const props = defineProps({
    array:  { type: Array  , default: () => []   },
    branch: { type: Boolean, default: () => true }
  });

  const emit = defineEmits([
    "begin", "change"
  ]);

  const location = reactive({ items: [], target: false });

  function location_none  (  ) { location.items = [];                            };
  function location_before(id) { location_none(); location.items[id] = "before"; };
  function location_after (id) { location_none(); location.items[id] = "after" ; };

  function dragstart(event, node) {
    location.target = true;

    event.dataTransfer.dropEffect    = "move";
    event.dataTransfer.effectAllowed = "move";
    event.target.style.opacity = "0.5";
    emit("begin", event, node);
  };

  function dragend(event) {
    event.target.style.opacity = "1";
    location.target = false;
    location_none();
  };
</script>

<template>
  <div class="widgets-regions-vertical">
    <div v-for="(item, index) in props.array"
      :key="item.id"
      class="wrapper p-relative"

      :data-id="item.id"
      :data-branch="props.branch"
      :data-index="index + 1"

      draggable="true"
      @dragenter.prevent
      @dragleave.prevent
      @dragover.prevent
      @dragend="dragend($event)"
      @dragstart="dragstart($event, item)"

      @click.prevent
    >
      <div v-if="location.target"
        class="p-absolute w-100 h-100 z-10"
      >
        <div v-if="item.id"
          class="w-100 h-50"

          @dragenter.prevent
          @dragleave="location_none"
          @dragover="location_before(item.id)"
          @drop="emit('change', $event, item, -0.5)"
        />

        <div v-if="item.id"
          class="w-100 h-50"

          @dragenter.prevent
          @dragleave="location_none"
          @dragover="location_after(item.id)"
          @drop="emit('change', $event, item, 0.5)"
        />
      </div>

      <div class="d-flex p-absolute w-100 h-1px">
        <div class="w-100" :class="{'border-top-1-dashed': location.items[item.id] && location.items[item.id] == 'before'}" />
      </div>

      <div class="d-flex">
        <slot name="item" :item="item" />
      </div>

      <div class="d-flex p-absolute w-100 h-1px">
        <div class="w-100" :class="{'border-top-1-dashed': location.items[item.id] && location.items[item.id] == 'after'}" />
      </div>
    </div>
  </div>
</template>