<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    action: { type: Object , default: () => {}    },
    expand: { type: Boolean, default: () => false }
  });

  const emit = defineEmits([
    "redirect"
  ]);
</script>

<template>
  <div v-if="props.action.type == 'unit'"
    class="d-flex flex-column align-items-center hover-base-highlight c-pointer"
    :class="props.action.type"
    @click="emit('redirect', props.action.path)"
  >
    <div class="d-flex align-items-center toolbar-box-shadow w-100--28px h-100 py-8 c-pointer">
      <div
        class="icon large my-8"
        :class="props.action.icon"
      />

      <div v-if="props.expand"
        class="font-neutral font-memo ml-8"
      >
        {{ $t(props.action.name) }}
      </div>
    </div>
  </div>

  <div v-else class="flex-grow" />
</template>