<template>
  <div class="widget d-flex flex-column p-relative">
    <div v-if="label" class="d-flex font-soft font-memo mb-8">
      <div class="lh-18 mr-8">
        {{ label }}
      </div>

      <div
        class="d-flex justify-content-center align-items-center border-1 color-border-highlight border-radius-7 w-24px h-24px c-pointer base-neutral"
        @click="dropdown = !dropdown"
      >
        <div class="icon icon-add-highlight" />
      </div>
    </div>

    <div class="avatar value d-flex flex-column">
      <div v-if="selected.length == 0"
        class="placeholder font-memo font-italic"
      >
        {{ placeholder }}
      </div>

      <div
        v-for="item in selected"
        :key="item.id"
        class="d-flex my-8"
      >
        <img v-if="avatar(item)"
          class="item object-fit-cover"
          :src="url(item.watcher)"
        >

        <div v-else
          class="item base-selection"
        >
          {{ title(item.watcher) }}
        </div>

        <div class="ml-16 lh-54">
          {{ name(item.watcher) }}
        </div>
      </div>
    </div>

    <div v-if="dropdown"
      class="avatar avatar-cards flex-column p-absolute base-neutral border-radius-8 box-shadow-bottom p-8 max-h-200px overflow-y-scroll scrollbar z-10"
      @mouseleave="dropdown = !dropdown"
    >
      <div v-for="user in users"
        :key="user.id"
        class="item c-pointer my-4"
        :class="{
          'base-highlight': selected.find(item => item.watcher == user.id),
          'font-neutral': selected.find(item => item.watcher == user.id)
        }"
        @click="handler(user.id)"
      >
        <img v-if="user.avatar"
          class="user base-neutral object-fit-cover"
          :src="url(user.id)"
        >

        <div v-else
          class="user base-highlight color-border-highlight font-neutral"
          :class="{ 'base-selection': selected.find(item => item.watcher == user.id) }"
        >
          {{ user.name.charAt(0) + user.lastname.charAt(0) }}
        </div>

        <div class="ml-8 mr-8">
          {{ user.name + ' ' + user.lastname }}
        </div>
      </div>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  import Config from "../../../../config.js";
  import Server from "../../../../system/server/server.js";

  export default {
    props: {
      label      : { type: String, default: () => "" },
      users      : { type: Array , default: () => [] },
      selected   : { type: Array , default: () => [] },
      placeholder: { type: String, default: () => "" },
      description: { type: String, default: () => "" }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const dropdown = ref(false);

      const api = ref(Config.API);

      return {
        dropdown,

        title: (selected) => {
          let user = props.users.find(user => selected == user.id);
          return user.name.charAt(0) + user.lastname.charAt(0);
        },

        name: (selected) => {
          let user = props.users.find(user => selected == user.id);
          return user.name + " " + user.lastname;
        },


        avatar: (item) => {
          let watcher = props.users.find(user => user.id == item.watcher);
          return watcher && watcher.avatar || "";
        },

        url: (id) => api.value + '/api/profile/download?id=' + id + '&token=' + Server.token(),

        handler: (id) => {
          context.emit('submit', id);
          dropdown.value = !dropdown.value;
        }
      };
    }
  };
</script>