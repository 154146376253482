<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import WidgetProjects      from "../../../../visual/widgets/projects/Projects.vue";
  import WidgetFilterStatus  from "../../../../visual/widgets/filters/Status.vue";
  import WidgetFilterCombo   from "../../../../visual/widgets/filters/Combo.vue";
  import WidgetFilterDefault from "../../../../visual/widgets/filters/Default.vue";

  // import WidgetFilterSort from "../../../../visual/widgets/filters/Sort.vue";
  // import FieldSearch      from "../../../../visual/fields/search/Default.vue";
</script>

<template>
  <div class="d-flex justify-content-between w-100">
    <widget-projects
      :projects="source.get_visible"
      @activate="(id) => source.project_activate(id)"
      @priority="(event) => source.project_priority(event)"
    />

    <div class="d-flex align-items-center">
      <widget-filter-status v-if="source.get_active"
        name="ideas"
        icon="idea"
        :item="{ id: 'filter_idea', status: source.filters.idea }"
        @handler="(filter, status) => source.filter_status(filter, status)"
      />

      <widget-filter-status v-if="source.get_active"
        class="ml-8"
        name="tasks"
        icon="work"
        :item="{ id: 'filter_work', status: source.filters.work }"
        @handler="(filter, status) => source.filter_status(filter, status)"
      />

      <widget-filter-status v-if="source.get_active"
        class="ml-8"
        name="completed"
        icon="finish"
        :item="{ id: 'filter_done', status: source.filters.done }"
        @handler="(filter, status) => source.filter_status(filter, status)"
      />

      <!-- <field-search class="max-w-400px ml-8" :value="keyword" @search="(keyword) => $emit('search', keyword)" /> -->

      <widget-filter-default v-if="source.get_active"
        class="ml-8"
        icon="empty-folder"
        :item="{ id: source.get_active.id, status: !!source.filters.empty }"
        @handler="(status) => source.filter_empty(status)"
      />

      <widget-filter-default v-if="source.get_active"
        class="ml-8"
        icon="structure"
        :item="{ id: source.get_active.id, status: !!source.tree.show }"
        @handler="(status) => source.tree_show(status)"
      />

      <widget-filter-default v-if="source.get_active"
        class="ml-8"
        icon="schema"
        :item="{ id: source.get_active.id, status: !!source.get_active.mode_view }"
        @handler="(status) => source.mode_view(status)"
      />

      <!-- <widget-filter-sort v-if="source.get_active" class="ml-8" :item="{ id: source.get_active.id, sort: source.get_active.sort }" @handler="(id, sort) => source.filter_sorting(id, sort)" /> -->

      <widget-filter-combo
        class="ml-8"
        :workers="{ selected: source.filters.workers, users: source.squad }"
        :projects="source.projects"
        @workers_handler="(id) => source.filter_workers(id)"
        @projects_handler="(id) => source.project_visible(id)"
      />
    </div>
  </div>
</template>