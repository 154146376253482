<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
</script>

<template>
  <div class="d-flex flex-column w-20 overflow-y-scroll scrollbar-disable">
    <div v-for="user in source.users"
      :key="user.id"

      class="d-flex flex-column border-radius-primary border-1 color-border-soft base-neutral box-shadow-card my-4 p-4"
      :class="{ 'color-border-highlight': source.get_active.user_pick == user.id }"

      @click="source.user_pick(user.id)"
    >
      <div class="font-dark font-weight-500 mb-4">
        {{ user.name }} {{ user.lastname }}
      </div>

      <div class="font-memo font-soft">
        {{ user.email }}
      </div>
    </div>
  </div>
</template>