<script setup>
  // import { defineAsyncComponent } from "vue";

  // import { useSourceStore } from "../../stores/SourceStore.js";
  // const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

</script>

<template>
  <div class="d-flex">
    <div :class="{ 'c-pointer': state.select }" @click="state.$patch({ select: '' })">Dashboard</div>

    <div v-if="state.select" class="d-flex">
      <span class="mx-4">></span> {{ state.select }}
    </div>
  </div>
</template>