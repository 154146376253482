export default class Comment {
  constructor(response) {
    this.status  = {};
    this.threads = [];

    if (response) {
      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.threads = response.data.threads;
      }
    }
  }
}