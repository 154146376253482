export default {
  ru: {
    People          : "Сотрудники",
    Team            : "Команда",
    Teams           : "Команды",
    teams           : "команды",
    Workload        : "Загрузка",
    Work_schedule   : "График",
    Work_booking    : "Бронь",
    insert_role     : "Укажите роль",
    role_is_empty   : "Пустая роль",
    add_user        : "Добавит пользователя",
    search_user     : "Поиск пользователя",
    access_is_closed: "Доступ закрыт",
    Choose_space    : "Выберите организацию",
  },
  en: {
    People          : "People",
    Team            : "Team",
    Teams           : "Teams",
    teams           : "teams",
    Workload        : "Workload",
    Work_schedule   : "Schedule",
    Work_booking    : "Booking",
    insert_role     : "Insert Role",
    role_is_empty   : "Role is empty",
    add_user        : "Add user",
    search_user     : "Search user",
    access_is_closed: "Access is closed",
    Choose_space    : "Choose organization",
  }
};