export default {
  ru: {
    Factors          : "Факторы",
    Formulas         : "Формулы",
    add_factor       : "Добавить фактор",
    add_formula      : "Добавить формулу",
    insert_variable  : "Укажите переменную",
    insert_value     : "Укажите значение",
    insert_formula   : "Укажите формулу",
    variable_is_empty: "Пустая переменная",
    value_is_empty   : "Пустое значение",
    formula_is_empty : "Пустая формула",
  },
  en: {
    Factors          : "Factors",
    Formulas         : "Formulas",
    add_factor       : "Add factor",
    add_formula      : "Add formula",
    insert_variable  : "Insert variable",
    insert_value     : "Insert value",
    insert_formula   : "Insert formula",
    variable_is_empty: "Variable is empty",
    value_is_empty   : "Value is empty",
    formula_is_empty : "Formula is empty",
  }
};