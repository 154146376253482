<script setup>
  import { reactive, onMounted, defineProps } from "vue";

  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";
  import FieldInput    from "../../../_visual/fields/input/Input.vue";

  import DetailEntity  from "../entities/Detail.js";
  import DetailService from "../services/Detail.js";

  const props = defineProps({
    id: { type: String, default: () => "" }
  });

  const source = reactive(new DetailEntity);

  const fields = reactive({ name: ""    });
  const errors = reactive({ name: false });

  onMounted(async () => {
    Object.assign(source, await DetailService.load(props.id));
    fields.name = source.space.name;
  });

  const attach_errors = () => errors.name = fields.name == "" ? true : false;
  const remove_errors = () => errors.name = false;

  async function submit() {
    if (fields.name != "") {
      remove_errors();
      Object.assign(source, await DetailService.update(Object.assign(fields, { id: props.id })));
    }
    else {
      attach_errors();
    }
  };
</script>

<template>
  <layout-web>
    <div>
      <partial-header
        :id="$route.params.id"
        :active="0"
      />

      <div class="base-neutral border-radius-8 p-8">
        <div class="form">
          <field-input
            v-model="fields.name"
            class="mb-10"
            :label="$t('name_required')"
            :placeholder="$t('name')"
            :message="$t('name_is_empty')"
            :failure="errors.name"
          />

          <div class="d-flex">
            <div class="button primary" @click="submit">
              {{ $t("save") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-web>
</template>