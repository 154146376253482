<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    item: { type: Object, default: () => {} },
    type: { type: String, default: () => "" }
  });

  function format(date) {
    return new Date(date).toLocaleTimeString("ru-RU", { timeStyle: "medium" }) + " " + new Date(date).toLocaleDateString("ru-RU", { dateStyle: "medium" });
  }
</script>

<template>
  <div class="d-flex justify-content-between w-100 mb-8">
    <div class="font-dark">
      <span class="font-dark font-bold">[{{ props.type }}]</span> [#{{ props.item.code || props.item.nodes_id }}] [{{ props.item.projects_name }}] {{ props.item.nodes_title }}
    </div>

    <div>
      <span class="font-dark font-bold">{{ format(props.item.date) }}</span>
    </div>
  </div>
</template>