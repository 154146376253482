import SYSTEM from "../system.js";

export default class Roster {
  constructor(response) {
    this.access       = false;
    this.errors       = {};
    this.status       = {};
    this.spaces       = [];
    this.projects     = [];
    this.users        = [];
    this.list         = [];
    this.invoice_list = [];
    this.total_cost   = [];

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.spaces       = response.data.spaces;
        this.projects     = response.data.projects;
        this.users        = response.data.users;
        this.list         = response.data.list;
        this.invoice_list = response.data.invoice_list;
        this.total_cost   = response.data.total_cost;
      }
    }
  }
}