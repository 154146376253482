<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import HeaderPartial from "./events/Header.vue";

  const redirect = (code) => window.location.href = "/node/" + code;

  function background(item) {
    if (item.type == 'state' && item.onward && !item.stage_name) return "#B0FFE9";
    if (item.type == 'state' && item.onward && item.stage_name)  return "#B0C0FF";
    if (item.type == 'state' && !item.onward) return "#BDEDFF";
    if (item.type != 'state') return "#B66AA3";
  }

  async function more() {
    state.$patch({ offset: state.offset++ });
    await source.load(state.offset, state.search);
  };
</script>

<template>
  <div>
    <div v-for="item in source.events"
      :key="item"
      class="base-neutral flex-column p-relative mb-8 p-8 w-initial border-left-3 border-radius-8"
      :style="'border-color:' + background(item) + ';'"
    >
      <div v-if="item.type == 'state'">
        <div v-if="!item.onward">
          <div v-if="item.user_name && item.worker_name"
            class="c-pointer"

            @click="redirect(item.code)"
          >
            <header-partial :item="item" :type="$t('performer_appointed')" />

            {{ $t("step") }}: {{ item.stage_step }} ({{ item.stage_name }}). {{ item.user_name }} {{ $t("appointed_executor") }} {{ item.worker_name }}.
          </div>

          <div v-else-if="item.user_name && !item.worker_name"
            class="c-pointer"

            @click="redirect(item.code)"
          >
            <header-partial :item="item" :type="$t('remove_worker')" />

            {{ $t("step") }}: {{ item.stage_step }} ({{ item.stage_name }}). {{ item.user_name }} {{ $t("removed_the_performer") }}.
          </div>
        </div>

        <div v-else>
          <div v-if="item.stage_name"
            class="c-pointer"

            @click="redirect(item.code)"
          >
            <header-partial :item="item" :type="$t('stage_change')" />

            {{ item.user_name }} {{ $t("moved_next_step_process") }} {{ item.stage_step }} ({{ item.stage_name }}).
          </div>

          <div v-if="!item.stage_name"
            class="c-pointer"

            @click="redirect(item.code)"
          >
            <header-partial :item="item" :type="$t('completed_task')" />

            {{ item.user_name }} {{ $t("completed_the_process") }}.
          </div>
        </div>
      </div>

      <div v-if="item.type == 'comment'"
        class="c-pointer"

        @click="redirect(item.code)"
      >
        <header-partial :item="item" :type="$t('new_comment')" />

        <div>{{ item.user_name }}: {{ item.comment }}</div>
      </div>
    </div>

    <div class="button primary"
      @click="more"
    >
      {{ $t("show_more") }}
    </div>
  </div>
</template>