<script setup>
  import { defineAsyncComponent } from "vue";

  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import PopupSidebar from "../../../../visual/popups/sidebar/Sidebar.vue";

  const NodePlugin = defineAsyncComponent(() => import('../../../node/plugins/Roster.vue'));

  const redirect = () => { if (state.detail.code) window.location.href = `/node/${state.detail.code}` }
</script>

<template>
  <popup-sidebar v-if="Object.keys(state.detail).length">
    <template #header>
      <div
        class="font-title c-pointer"
        @click="redirect"
      >
        #{{ state.detail.code }}
      </div>

      <div
        class="font-highlight lh-16 c-pointer"
        @click="state.node_deactivate()"
      >
        {{ $t("close") }}
      </div>
    </template>

    <template #content>
      <node-plugin
        :code_id="state.detail.code"
        :node_id="state.detail.id"
        :actions="state.detail.action"
      />
    </template>

    <template #footer />
  </popup-sidebar>
</template>