<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    links : { type: Array , default: () => [] },
    layout: { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "fold"
  ]);
</script>

<template>
  <svg class="charts-canvas-links">
    <path v-for="link in props.links"
      :key="link.entity.id"

      :class="'link link-' + link.entity.id"
      :d="props.layout.links && props.layout.links[link.entity.id]"

      fill="none"
      stroke="#0CAEFF"
      stroke-width="2"
    />

    <circle v-for="(node, index) in props.layout.folds"
      :key="index"

      class="c-pointer"

      :cx="node.x"
      :cy="node.y"
      :r="node.r"

      :fill="node.f ? '#232D31' : '#0CAEFF'"
      stroke="none"

      @click="emit('fold', index)"
    />
  </svg>
</template>