<script setup>
  import { defineProps, defineEmits } from "vue";

  import FieldTextarea from "../../../fields/textarea/Default.vue";

  const props = defineProps({
    edit: { type: Boolean, default: () => false },
    text: { type: String , default: () => ""    }
  });

  const emit = defineEmits([
    "handler", "switch"
  ]);
</script>

<template>
  <div v-if="!props.edit"
    class="short-text font-dark font-weight-500 h-inherit border-bottom-1 border-color-transparent"
  >
    {{ props.text }}
  </div>

  <field-textarea v-else
    classes="font-dark font-weight-500 font-normal font-type h-28px base-transparent"

    :text="props.text"
    :autofocus="true"

    @submit="title => emit('handler', title)"
    @close="emit('switch')"
  />
</template>
