import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SERVICE from "../services/Tracker.js";

export const useSourceStore = defineStore("tracker_source", () => {
  const status    = ref([]);
  const active    = ref([]);
  const assignee  = ref([]);
  const paths     = ref([]);
  const timings   = ref([]);
  const estimates = ref([]);
  const follow    = ref(0);

  const node = computed(() => {
    return new Node(active.value, paths.value[active.value.id], timings.value[active.value.id], estimates.value[active.value.id]);
  });

  const pull = computed(() => {
    return assignee.value.map(node => new Node(node, paths.value[node.id], timings.value[node.id], estimates.value[node.id]));
  });

  const timeoutID = ref();

  function reload(response) {
    if (response.data) {
      status.value    = response.status;
      active.value    = response.data.active;
      assignee.value  = response.data.assignee;
      paths.value     = response.data.paths;
      timings.value   = response.data.timings;
      estimates.value = response.data.estimates;
      follow.value    = response.data.launch;

      if (follow.value) {
        timeoutID.value = setTimeout(async () => {
          reload(await SERVICE.assignee());
        }, 60000);
      }
      else {
        if (timeoutID.value)
          clearTimeout(timeoutID.value);
      }
    }
  };

  return {
    status,
    active,
    assignee,
    paths,
    timings,
    estimates,
    follow,

    node,
    pull,

    list: async () => reload(await SERVICE.assignee()),

    activate: async (state_id) => reload(await SERVICE.activate(state_id)),

    launch: async (role_state_id) => reload(await SERVICE.launch(role_state_id)),

    finish: async (role_state_id) => reload(await SERVICE.finish(role_state_id))
  }
})

import moment from "moment";
moment.locale("ru");

const PER_HOUR   = 3600;
const PER_MINUTE = 60;

class Node {
  constructor(node = {}, path = [], timing = false, estimate = false) {
    this.id        = node.id                                || 0;
    this.code      = node.code                              || 0;
    this.title     = node.title                             || "Нет активной задачи"; // "No active task";
    this.period_id = node.period_id                         || 1;
    this.type      = node.type                              || "";
    this.deadline  = moment(node.deadline).format("D MMMM") || "";
    this.time      = moment(node.deadline).format("HH:mm")  || "";

    this.spent     = timing   ? format_time(timing.duration) : "00:00";
    this.estimate  = estimate ? format_time(estimate)        : "00:00";

    this.path = node.projects_name;
    if (path.length != 0) {
      this.path += " > " + Object.values(path).join(" > ");
    }
  }
}

function format_time(time) {
  let hour   = parseInt(time / PER_HOUR);
  time       = time - (hour * PER_HOUR);
  let minute = parseInt(time / PER_MINUTE);

  return format_time_value(hour) + ":" + format_time_value(minute);
}

function format_time_value(value) {
  return String(value).length < 2 && ('0' + String(value)) || value;
}