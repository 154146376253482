<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import { Hotkeys, Action } from "../../../../packages/hotkeys/Hotkeys";

  import WidgetHorizontal from "../../../../visual/widgets/regions/Horizontal.vue";
  import WidgetCard       from "../../../../visual/widgets/cards/Milestone.vue";
  import FieldInput       from "../../../../visual/fields/input/Default.vue";

  const REGION_CHART = "chart";
  const REGION_LIST  = "list";

  onMounted(async () => {
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ENTER , (nodes) => nodes.forEach((node) => pick(node))),
      new Action(Hotkeys.KEYCODE_ESC   , (nodes) => nodes.forEach((node) => jump(node))),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => move(node, "left" ))),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => move(node, "right"))),

      new Action(Hotkeys.KEYCODE_M  , (nodes) => nodes.forEach((node) => state.switch_context(+node.closest("[data-id]").dataset.id, REGION_LIST)), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_F2, (nodes) => nodes.forEach((node) => state.switch_heading(+node.closest("[data-id]").dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_F2, (nodes) => nodes.forEach((node) => state.switch_period(+node.closest("[data-id]").dataset.id, REGION_LIST)), { shift: true }),

      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => state.switch_done_show(+node.closest("[data-id]").dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_I, (nodes) => nodes.forEach((node) => state.switch_work_show(+node.closest("[data-id]").dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.closest("[data-id]").dataset.id, REGION_LIST))),
    ], REGION_LIST);
  });

  onUnmounted(() => Hotkeys.reinit(REGION_LIST));

  async function pick(node) {
    state.$patch({ sprint_pick: node.id || node.closest("[data-id]").dataset.id });

    if (Hotkeys.change(REGION_CHART)) {
      Hotkeys.toggle(node);
      Hotkeys.focused();
    }
  }

  function jump(node) {
    if (Hotkeys.change(REGION_CHART)) {
      Hotkeys.toggle(node);
      Hotkeys.focused();
    }

    state.node_deactivate();
  };

  function move(node, action) {
    let target = action == "right" ?
      node.closest("[data-id]").nextSibling :
      node.closest("[data-id]").previousSibling
    ;

    target = target && target.childNodes.length && target.getElementsByClassName("list-item")[0];

    if (target) {
      Hotkeys.toggle(node);
      Hotkeys.toggle(target);

      target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    }
  }

  const menu = () => { return [
    { title: "Finish" , separator: true },
    { title: "Remove" , separator: false },
  ]; };

  const part = () => { return {
    actions: { menu: true, finish: true },
    diagram: { done: true, work: true, time: true }
  }; };

  const form = ref("");

  function sprint_break() {
    source.sprint_break(source.get_active.road_pick, form.value);
    state.$patch({ sprint_break: false });
  }
</script>

<template>
  <div
    :data-region="REGION_LIST"
    class="p-relative base-neutral p-8"
  >
    <div v-if="!source.sprints.length"
      @keyup.stop
      @keydown.stop
      @click="state.$patch({ sprint_break: true })"
    >
      <div v-if="!state.sprint_break"
        class="d-flex c-pointer font-memo align-items-center"
      >
        {{ $t("break_sprints") }}
      </div>

      <div v-else class="d-flex c-pointer align-items-center">
        <field-input
          v-model="form"
          :autofocus="true"
          :placeholder="$t('duration_sprint')"

          @keyup.enter="sprint_break"
          @keyup.esc="state.$patch({ sprint_break: false })"
        />
      </div>
    </div>

    <div v-else
      class="d-flex"
    >
      <div class="w-100 overflow-hidden">
        <widget-horizontal :array="source.sprints">
          <template #item="element">
            <widget-card
              class="list-item slave my-4 base-neutral w-222px"
              :class="{ 'base-soft': element.item.shipped }"

              :item="element.item"
              :menu="menu()"
              :part="part()"

              :selected="state.sprint_pick == element.item.id"
              :region="REGION_LIST"

              :context="state.get_state(element.item.id, REGION_LIST, 'context')"
              :heading="state.get_state(element.item.id, REGION_LIST, 'heading')"
              :period="state.get_state(element.item.id, REGION_LIST, 'period')"
              :done_show="state.get_state(element.item.id, REGION_LIST, 'done_show')"
              :time_show="state.get_state(element.item.id, REGION_LIST, 'time_show')"
              :work_show="state.get_state(element.item.id, REGION_LIST, 'work_show')"

              @switch_context="state.switch_context"
              @switch_heading="state.switch_heading"
              @switch_period="state.switch_period"
              @switch_done_show="state.switch_done_show"
              @switch_time_show="state.switch_time_show"
              @switch_work_show="state.switch_work_show"

              @pick="(node) => pick(node)"
              @finish="(id) => source.milestone_shipped(id)"
              @remove="(id) => source.milestone_remove(id)"
              @update="(option, id, value) => source.milestone_update(option, id, value)"

              @dragover.prevent
              @dragenter.prevent
              @dragleave.prevent
              @dragend.prevent
              @drop="source.node_move($event, element.item.id)"
            />
          </template>
        </widget-horizontal>
      </div>

      <div v-if="source.get_active.road_pick"
        class="d-flex w-24px h-24px font-soft hover-font-highlight justify-content-center align-items-center align-self-center border-1 color-border-soft hover-border-highlight border-radius-12 my-4 ml-8 c-pointer"

        @click="source.sprint_create(source.get_active.road_pick)"
      >
        +
      </div>
    </div>
  </div>
</template>