<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import { reactive } from "vue";

  import WidgetTabs          from "../../../../_visual/widgets/tabs/Tabs.vue";
  import WidgetCommentsCombo from "../../../../_visual/widgets/comments/combo/Combo.vue";

  const PER_HOUR   = 3600;
  const PER_MINUTE = 60;

  const tabs = reactive([
    { id: 1, name: "Comments", active: true  },
    { id: 2, name: "History" , active: false },
    { id: 3, name: "Timings" , active: false }
  ]);

  function activate(tab) {
    tabs.map(tab => tab.active = false);
    tab.active = tab.id;
  };

  function format(date) {
    return new Date(date).toLocaleTimeString("ru-RU", { timeStyle: "short" }) + " " + new Date(date).toLocaleDateString("ru-RU", { dateStyle: "medium" });
  };

  function format_time(time) {
    let hour   = parseInt(time / PER_HOUR);
    time       = time - (hour * PER_HOUR);
    let minute = parseInt(time / PER_MINUTE);

    if(minute < 0) {
      return "- " + format_time_value(hour) + ":" + format_time_value(-minute);
    } else {
      return format_time_value(hour) + ":" + format_time_value(minute);
    }
  };

  function format_time_value(value) {
    return String(value).length < 2 && ('0' + String(value)) || value;
  };
</script>

<template>
  <div class="d-flex flex-column base-neutral border-radius-16 mx-27 px-27 py-18">
    <widget-tabs
      :tabs="tabs"
      @activate="activate"
    />

    <div class="my-16">
      <widget-comments-combo v-if="tabs[0].active"
        :nodes_id="source.node.id"
      />

      <div v-if="tabs[1].active"
        class="my-5"
      >
        <div v-for="item in source.history"
          :key="item"
          class="mb-10"
        >
          <div v-if="!item.onward"
            class="d-flex"
          >
            <div v-if="item.user_name && item.worker_name">
              {{ format(item.date) }} - {{ $t("step") }}: {{ item.stage_step }} ({{ item.stage_name }}). {{ item.user_name }} {{ $t("appointed_executor") }} {{ item.worker_name }}.
            </div>

            <div v-else-if="item.user_name && !item.worker_name">
              {{ format(item.date) }} - {{ $t("step") }}: {{ item.stage_step }} ({{ item.stage_name }}). {{ item.user_name }} {{ $t("removed_the_performer") }}.
            </div>
          </div>

          <div v-else
            class="d-flex"
          >
            <div v-if="item.stage_name">
              {{ format(item.date) }} - {{ item.user_name }} {{ $t("moved_next_step_process") }} {{ item.stage_step }} ({{ item.stage_name }}).
            </div>

            <div v-if="!item.stage_name">
              {{ format(item.date) }} - {{ item.user_name }} {{ $t("completed_the_process") }}.
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabs[2].active"
        class="my-5"
      >
        <div v-for="item in source.timings"
          :key="item"
          class="d-flex mb-10"
          :class="{ 'base-light': item.manual }"
        >
          <div class="mr-10">
            {{ format(item.start) }}
          </div>

          <div class="mr-5">
            {{ item.name }}
          </div>

          <div class="mr-10">
            {{ item.lastname }}
          </div>

          <div class="mr-10">
            {{ format_time(item.duration) }}
          </div>

          <div>
            {{ item.reason }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>