<script setup>
  import { ref, watchEffect, onMounted, defineProps, defineEmits } from "vue";

  import Default from "./Default.vue";

  const props = defineProps({
    view: { type: Boolean, default: () => false },
    idea: { type: Number , default: () => 0     },
    work: { type: Number , default: () => 0     }
  });

  const emit = defineEmits([
    "switch_show"
  ]);

  const percent = ref(0);

  onMounted(() => recount(props.idea, props.work));
  watchEffect(() => recount(props.idea, props.work));

  function recount(idea, work) {
    if (idea + work) {
      percent.value = Math.ceil(work / (work + idea) * 100);
    }
  };
</script>

<template>
  <div class="d-flex" @click.exact.stop.prevent="emit('switch_show')">
    <default entity="complexity" :percent="percent">
      <template #content>
        <div v-if="props.view"
          class="d-flex justify-content-center align-items-center ml-2 mr-4"
        >
          <div class="font-section">
            {{ props.work }}
          </div>
          /
          <div class="font-soft">
            {{ props.work + props.idea }}
          </div>
        </div>
      </template>
    </default>
  </div>
</template>