<template>
  <div class="d-flex flex-column">
    <div class="value">
      <div
        v-for="comment in source.threads"
        :key="comment.id"
        class="comment d-flex justify-content-between my-10"
      >
        <div @click="show_popups_detail(comment)">
          <div class="user font-light mb-5">
            {{ comment.name }} {{ comment.lastname }} ( {{ format(comment.created_at) }} )
          </div>

          <blockquote v-if="comment.reply" class="content">
            {{ comment.reply }}
          </blockquote>

          <div class="content">
            {{ comment.content }}
          </div>

          <div class="font-memo font-light c-pointer" @click.stop="show_popups_reply(comment.id)">
            {{ $t("reply") }}
          </div>

          <div v-if="popups.reply && popups.reply == comment.id" class="input">
            <field-input
              v-model="fields.reply"
              type="text"
              :failure="failures.reply"
              :placeholder="$t('add_comment')"
              :message="$t('comment_is_empty')"
              @keyup="create_reply"
            />
          </div>
        </div>

        <div>
          <div class="icon icon-close icon-hover-white c-pointer" @click="remove(comment.id)" />
        </div>
      </div>
    </div>

    <div class="input">
      <field-input
        v-model="fields.value"
        type="text"
        :failure="failures.value"
        :placeholder="$t('add_comment')"
        :message="$t('comment_is_empty')"
        @keyup="create"
      />
    </div>
  </div>
</template>

<script>
  import {
    reactive,
    onMounted,
    watch
  } from "vue";

  import FieldInput from "../../../fields/input/Input.vue";

  import ThreadEntity  from "../entities/Thread.js";
  import ThreadService from "../services/Thread.js";

  const KEYCODE_ENTER = 13;
  const KEYCODE_ESC   = 27;

  export default {
    components: {
      FieldInput
    },

    props: {
      comments_id: { type: String, default: () => "" }
    },

    setup(props) {
      const source   = reactive(new ThreadEntity);
      const fields   = reactive({ value: "", reply: "" });
      const failures = reactive({ value: false, reply: false });

      const popups = reactive({ detail: false, reply: false });

      watch(() => props.comments_id, async (comments_id) => Object.assign(source, await ThreadService.load(comments_id)));
      onMounted(async () => Object.assign(source, await ThreadService.load(props.comments_id)));

      let show_popups_detail = (data) => popups.detail = data;
      let hide_popups_detail = () => popups.detail = false;

      let show_popups_reply = (id) => popups.reply = id;
      let hide_popups_reply = () => popups.reply = false;

      return {
        source,
        fields,
        failures,
        popups,

        show_popups_detail,
        hide_popups_detail,
        show_popups_reply,
        hide_popups_reply,

        format: (date) => {
          return new Date(date).toLocaleDateString("ru-RU", { dateStyle: "long" });
        },

        create: async (event) => {
          if (event.keyCode == KEYCODE_ENTER) {
            if (fields.value != "") {
              failures.value = false;

              Object.assign(source, await ThreadService.create({
                comments_id: props.comments_id,
                content    : fields.value
              }));

              fields.value = "";
            }
            else {
              failures.value = fields.value == "" ? true : false;
            }
          }
          else if (event.keyCode == KEYCODE_ESC) {
            fields.value = "";
          }
        },

        create_reply: async (event) => {
          if (event.keyCode == KEYCODE_ENTER) {
            if (fields.reply != "") {
              failures.reply = false;

              Object.assign(source, await ThreadService.create({
                comments_id: props.comments_id,
                parent_id  : popups.reply,
                content    : fields.reply
              }));

              fields.reply = "";
            }
            else {
              failures.reply = fields.reply == "" ? true : false;
            }
          }
          else if (event.keyCode == KEYCODE_ESC) {
            fields.reply = "";
            hide_popups_reply();
          }
        },

        remove: async (id) => {
          Object.assign(source, await ThreadService.remove(id));
        }
      };
    },
  };
</script>
