import SERVER from "../../../system/server/server";

export default class Roster {
  static async load(code) {
    return await SERVER.get("node/" + code);
  }

  static async load_by_projects(projects_id) {
    return await SERVER.get("node/projects" + "?projects_id=" + projects_id);
  }

  static async load_by_users(users_id) {
    return await SERVER.get("node/users" + "?users_id=" + users_id);
  }

  static async create(projects_id, root_id, title) {
    return await SERVER.post("node", {
      title      : title,
      projects_id: projects_id,
      root_id    : root_id
    });
  }

  static async remove(node_id) {
    return await SERVER.delete("node/" + node_id);
  }

  static async finish(node_id) {
    return await SERVER.get("node/finish/" + node_id);
  }

  static async onward(node_id, status_state_id) {
    return await SERVER.put("node/onward/" + node_id, {
      status_state_id: status_state_id
    });
  }

  static async backward(node_id) {
    return await SERVER.get("node/backward/" + node_id);
  }

  static async update(node_id, field, value) {
    return await SERVER.put("node/update/" + node_id, {
      field: field,
      value: value
    });
  }

  static async complexities(node_id, value, route_id) {
    return await SERVER.put("node/complexities/" + node_id, {
      value   : value,
      route_id: route_id
    });
  }

  static async workers(node_id, route_id, worker_id) {
    return await SERVER.put("node/workers/" + node_id, {
      route_id : route_id,
      worker_id: worker_id
    });
  }

  static async watcher(node_id, watcher) {
    return await SERVER.put("node/watcher/" + node_id, {
      watcher: watcher
    });
  }

  static async timing(manual_timing, reason, created, node_id, states_id) {
    return await SERVER.put("node/manual/" + node_id, {
      manual_timing : manual_timing,
      reason        : reason,
      created       : created,
      states_id     : states_id
    });
  }
}