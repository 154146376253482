export default {
  ru: {
    Role             : "Роль",
    Roles            : "Роли",
    roles            : "роли",
    Rate             : "Ставка",
    add_role         : "Создать роль",
    insert_rate      : "Укажите ставку",
    rate_is_empty    : "Ставка не указана",
    add_qualification: "Создать квалификацию",
  },
  en: {
    Role             : "Role",
    Roles            : "Roles",
    roles            : "roles",
    Rate             : "Rate",
    add_role         : "Add role",
    insert_rate      : "Insert rate",
    rate_is_empty    : "Rate is empty",
    add_qualification: "Add qualification",
  }
};