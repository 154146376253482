<script setup>
  import {  defineProps, defineEmits } from "vue";

  import FieldDatetime from "../../fields/datetime/Default.vue";

  const props = defineProps({
    autofocus: { type: Boolean, default: () => false },
    start    : { type: String , default: () => ""    },
    finish   : { type: String , default: () => ""    }
  });

  const emit = defineEmits([
    "submit", "close"
  ]);

  function jump_start(value) {
    emit("submit", "start", value);
  }

  function jump_finish(value) {
    emit("submit", "finish", value);
    emit("close");
  }
</script>

<template>
  <div class="d-flex align-items-center">
    <field-datetime
      classes="font-dark font-weight-500 font-normal font-type h-28px base-transparent p-5"

      :autofocus="props.autofocus"
      :value="props.start"

      @submit="jump_start"
      @close="emit('close')"
    />

    <div class="lh-18 mx-4 font-soft">
    -
    </div>

    <field-datetime
      classes="font-dark font-weight-500 font-normal font-type h-28px base-transparent"

      :autofocus="false"
      :value="props.finish"

      @submit="jump_finish"
      @close="emit('close')"
    />
  </div>
</template>