<template>
  <layout-web>
    <partial-header :active="3" />

    <div class="box-grid">
      <div
        v-for="item in source.excuses"
        :key="item.id"
        class="d-flex"
      >
        <div
          class="card dark flex-row p-relative my-10 px-0"
          :class="{ color: !!item.color }"
        >
          <div v-if="!!item.color"
            class="color"
            :style="{'background-color': item.color}"
          />

          <div class="d-flex flex-column flex-grow">
            <router-link :to="'/excuses/' + item.id" class="text-decoration-none flex-column w-100">
              <div class="font-dark">
                {{ item.title }}
              </div>
            </router-link>

            <hr class="dark">

            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="item.solution" />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="button confirm bottom" @click="show_popups_create">
        {{ $t("add") }}
      </div>
    </div>

    <!-- Popup: Create excuse -->
    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_excuse") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popups_create" />
      </template>

      <template #content>
        <field-input
          v-model="fields.title"
          type="text"
          :placeholder="$t('insert_title')"
          :message="$t('title_is_empty')"
          :failure="errors.title"
        />

        <field-editor
          v-model="fields.solution"
          :placeholder="$t('insert_solution')"
          :message="$t('solution_is_empty')"
          :failure="errors.solution"
        />

        <field-input
          v-model="fields.color.hex"
          type="text"
          :placeholder="$t('insert_color')"
          :message="$t('color_is_empty')"
          :failure="errors.color"
          @click="show_popups_picker"
        />

        <!-- <compact v-if="popups.picker"
          v-model="fields.color"
          class="max-w-100"
          @click="hide_popups_picker"
        /> -->
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb        from "../../../layouts/Web.vue";
  import PartialHeader    from "./partials/Header.vue";
  import PopupBase        from "../../../visual/popups/base/Base.vue";
  import FieldInput       from "../../../_visual/fields/input/Input.vue";
  import FieldEditor      from "../../../_visual/fields/editor/Editor.vue";

  import ExcusesEntity  from "../entities/Excuses.js";
  import ExcusesService from "../services/Excuses.js";

  // import { Compact } from "@ckpack/vue-color";

export default {
    components: {
      LayoutWeb,
      PartialHeader,
      PopupBase,
      FieldInput,
      FieldEditor,
      // Compact
    },

    setup() {
      const source = reactive(new ExcusesEntity);
      const popups = reactive({ create: false, picker: false });

      const fields = reactive({ title: ""   , solution: ""   , color: "" });
      const errors = reactive({ title: false, solution: false, color: false     });

      onMounted(async () => Object.assign(source, await ExcusesService.load()));

      let show_popups_create = function () {
        popups.create = true;
      };

      let hide_popups_create = function () {
        popups.create   = false;
        fields.title    = "";
        fields.solution = "";
        fields.color    = "";
      };

      let show_popups_picker = function () {
        popups.picker = true;
      };

      let hide_popups_picker = function () {
        popups.picker = false;
      };

      let attach_errors = function () {
        errors.title    = fields.title    == "" ? true : false;
        errors.solution = fields.solution == "" ? true : false;
        errors.color    = fields.color    == "" ? true : false;
      };

      let remove_errors = function () {
        errors.title    = false;
        errors.solution = false;
        errors.color    = false;
      };

      return { source, errors, fields, popups,
        show_popups_create,
        hide_popups_create,
        show_popups_picker,
        hide_popups_picker,

        create: async () => {
          if (fields.title != "" && fields.solution != "" && fields.color.hex != "") {
            remove_errors();

            fields.color = fields.color.hex;

            Object.assign(source, await ExcusesService.create(fields));

            hide_popups_create();
          }
          else {
            attach_errors();
          }
        },

        remove: async (id) => Object.assign(source, await ExcusesService.delete({ id: id }))
      };
    }
  };
</script>