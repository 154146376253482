<script setup>
  import { ref, onMounted, nextTick, defineProps, defineEmits } from "vue";

  const props = defineProps({
    text       : { type: String , default: () => ""    },
    classes    : { type: String , default: () => ""    },
    label      : { type: String , default: () => ""    },
    placeholder: { type: String , default: () => ""    },
    description: { type: String , default: () => ""    },
    autofocus  : { type: Boolean, default: () => false }
  });

  const emit = defineEmits([
    "submit", "close"
  ]);

  const value  = ref();
  const string = ref();

  onMounted(async() => {
    string.value = props.text;

    if (props.autofocus) {
      await nextTick();
      value.value.focus();
    }
  });

  function handler(event) {
    emit("submit", event.target.value);
    emit("close");
  };
</script>

<template>
  <div
    class="field w-100"

    @click.stop
    @keyup.stop
    @keydown.stop
  >
    <div v-if="props.label" class="font-soft font-memo mb-8">
      {{ props.label }}
    </div>

    <textarea :ref="(element) => { value = element }"
      v-model="string"

      :placeholder="props.placeholder"
      :class="props.classes"

      @keyup.enter.exact.stop.prevent="handler"
      @keyup.esc.exact.stop.prevent="emit('close')"
    />

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>
  </div>
</template>