<script setup>
  import { ref, reactive, computed, watch, defineExpose, defineProps, defineEmits } from "vue";

  import Motion from "../Motion.js";

  import ChartsLink  from "./elements/Link.vue";
  import ChartsNode  from "./elements/Node.vue";
  import ChartsZone  from "./elements/Zone.vue";
  import ChartsBlank from "./elements/Blank.vue";

  const SCROLL_STEP = 100;

  const MOTION_TYPE_NODE   = "node";
  const MOTION_TYPE_CANVAS = "canvas";

  const props = defineProps({
    nodes   : { type: Array  , default: () => []    },
    links   : { type: Array  , default: () => []    },
    pool    : { type: Array  , default: () => []    },
    lock    : { type: Boolean, default: () => false },
    layout  : { type: Object , default: () => {}    },
    region  : { type: Object , default: () => {}    },
    viewport: { type: Object , default: () => {}    },
    scale   : { type: Number , default: () => 1     }
  });

  const emit = defineEmits([
    "chart-root",
    "chart-child",
    "chart-sibling",
    "chart-cancel-placeholder",
    "chart-move",
    "chart-fold",
    "chart-crumb",
    "chart-update",
    "location"
  ]);

  const canvas   = ref(null);
  const elements = ref({}); // @INFO: Required by Charts component to calculate node sizes.
  const states   = reactive({ target: undefined });

  defineExpose({ elements });

  const cursor = reactive({ x: 0, y: 0 });

  const region = reactive({
    width : props.region.width,
    height: props.region.height
  });

  watch(props.region, size => {
    region.width  = size.width;
    region.height = size.height;
  });

  const viewport = reactive({
    top   : props.viewport.top,
    left  : props.viewport.left,
    width : props.viewport.width,
    height: props.viewport.height,
  });

  watch(props.viewport, location => {
    viewport.top    = location.top;
    viewport.left   = location.left;
    viewport.width  = location.width;
    viewport.height = location.height;
  });

  const location = computed(() => ({
    top   : viewport.top  + "px",
    left  : viewport.left + "px",
    width : region.width  + "px",
    height: region.height + "px",
  }));

  const motion = reactive({
    id  : undefined,
    node: undefined,
    type: undefined,
  });

  const drag = (event, id) => {
    if (motion.node) return;

    cursor.x = event.x;
    cursor.y = event.y;

    motion.id   = id;
    motion.node = elements.value[id];
    motion.type = id ? MOTION_TYPE_NODE : MOTION_TYPE_CANVAS;

    if (motion.type === MOTION_TYPE_CANVAS)
      Motion.launch(event, canvas.value);

    if (motion.node && motion.node.dataset && motion.node.dataset.id)
      event.dataTransfer.setData("node_id", motion.node.dataset.id);
  };

  const over_slow = (event, id) => {
    if (motion.type === MOTION_TYPE_NODE) {
      if (id && id !== motion.id) states.target = id;
    }
    else if (motion.type === MOTION_TYPE_CANVAS) {
      let position = Motion.handle(viewport, cursor, event, {
        left: -(region.width  - viewport.width  * (1 / props.scale)),
        top : -(region.height - viewport.height * (1 / props.scale))
      });

      emit("location", position.left, position.top);
    }

    cursor.x = event.x;
    cursor.y = event.y;
  };

  var limitExecByInterval = function(fn, time) {
    var lock, execOnUnlock, args;
    return function() {
      args = arguments;
      if (!lock) {
        lock = true;
        // var scope = this;
        setTimeout(function(){
          lock = false;
          if (execOnUnlock) {
            // args.caller.apply(scope, args);
            execOnUnlock = false;
          }
        }, time);
        return fn.apply(this, args);
      } else execOnUnlock = true;
    }
  }

  var over = limitExecByInterval(over_slow, SCROLL_STEP);

  const over_x = (event) => {
    viewport.left = viewport.left + (event.deltaY > 0 ? -SCROLL_STEP : SCROLL_STEP);
    if (viewport.left > -SCROLL_STEP) { viewport.left = 0; }
    if (viewport.width * (1 / props.scale) + -viewport.left > region.width) { viewport.left = viewport.width * (1 / props.scale) - region.width; }
    emit("location", viewport.left, viewport.top);
  };

  const over_y = (event) => {
    viewport.top = viewport.top + (event.deltaY > 0 ? -SCROLL_STEP : SCROLL_STEP);
    if (viewport.top > -SCROLL_STEP) { viewport.top = 0; }
    if (viewport.height * (1 / props.scale) + -viewport.top > region.height) { viewport.top = viewport.height * (1 / props.scale) - region.height; }
    emit("location", viewport.left, viewport.top);
  };

  const drop = () => {
    if (!motion.node) return;

    if      (motion.type === MOTION_TYPE_NODE  ) { states.target = undefined;   }
    else if (motion.type === MOTION_TYPE_CANVAS) { Motion.finish(canvas.value); }

    motion.id   = undefined;
    motion.node = undefined;
    motion.type = undefined;
  };

  function classes(node) {
    return `node-${node.entity.id} w-250px ${node.entity.branch ? "complex" : ""} ${states.target == node.entity.id ? "focused" : ""}`;
  };

  function position(node, index) {
    return `
      position: absolute;
      z-index: ${1000000000 - (10000 * node.option.level) - index};
      ${props.layout.nodes && props.layout.nodes[node.entity.id] || "opacity: 0; "}
    `;
  };
</script>

<template>
  <div ref="canvas"
    class="charts-canvas z-20"
    draggable="true"

    :style="location"

    @dragstart="drag"
    @dragend="drop"
    @dragover.prevent="over"
    @dragenter.prevent

    @wheel.exact.prevent="over_y"
    @wheel.shift.exact.prevent="over_x"
  >
    <charts-link
      :links="props.links"
      :layout="props.layout"

      @fold="id => emit('chart-fold', id)"
    />

    <div v-for="(node, index) in props.nodes"
      :key="node.entity.id"
      :ref="element => elements[node.entity.id] = (element)"
      :data-id="node.entity.id"
      :data-level="node.option.level + 1"
      :data-offset="node.option.offset"
      :data-index="index + 1"
      :data-branch="node.entity.branch"

      class="node base-neutral border-1 border-radius-4 box-shadow-bottom"
      :class="classes(node)"
      :style="position(node, index)"

      draggable="true"
      @dragstart.stop="drag($event, node.entity.id)"
      @dragover.stop="over($event, node.entity.id)"
      @dragend.stop="drop()"

      @click.shift.exact.stop.prevent="() => { if (node.entity.branch) { emit('chart-crumb', node.entity.id) } }"
    >
      <slot v-if="node.entity.id != 0"
        name="node"
        :node="node"
      >
        <charts-node :node="node" />
      </slot>

      <slot v-else
        name="blank"
        :node="node"
        :pool="props.pool"
        :lock="props.lock"
      >
        <charts-blank
          class="h-100"
          tabindex="1"

          :node="node"
          :pool="props.pool"
          :lock="props.lock"

          @root="data => emit('chart-root', data)"
          @child="data => emit('chart-child', data)"
          @sibling="data => emit('chart-sibling', data)"
          @cancel="emit('chart-cancel-placeholder')"
        />
      </slot>
    </div>

    <charts-zone
      :source="motion.node && motion.node.dataset.id"
      :target="states.target"
      @transfer="data => emit('chart-move', data)"
    />
  </div>
</template>

<style lang="scss">
  .charts-canvas {
    position: absolute;

    &:focus-visible {
      outline: none;
    }

    .charts-canvas-links{
      width: 100%;
      height: 100%;
      position:absolute;
    }

    .node {
      border-color: #DEDEDE;

      &[data-focused]  {
        outline: none;
        border-color: #0CAEFF;
      }
    }
  }
</style>