import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM  from "../system.js";
import SERVICE from "../services/Roster.js";

export const useSourceStore = defineStore("node_source", () => {
  const access        = ref(false);
  const errors        = ref({});
  const status        = ref({});
  const node          = ref({});
  const actions       = ref({});
  const project       = ref({});
  const subject       = ref({});
  const states        = ref([]);
  const path          = ref([]);
  const category      = ref({});
  const categories    = ref([]);
  const complexities  = ref([]);
  const leaves        = ref([]);
  const users         = ref([]);
  const workers       = ref([]);
  const watchers      = ref([]);
  const chains        = ref([]);
  const steps         = ref([]);
  const history       = ref([]);
  const timing        = ref([]);
  const timings       = ref([]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  function reload(response) {
    if (response.data) {
      access.value        = response.access;
      errors.value        = response.errors;
      status.value        = response.status;
      node.value          = response.data.node;
      actions.value       = response.data.actions;
      project.value       = response.data.project;
      subject.value       = response.data.subject;
      states.value        = response.data.states;
      path.value          = response.data.path;
      category.value      = response.data.category;
      categories.value    = response.data.categories;
      complexities.value  = response.data.complexities;
      leaves.value        = response.data.leaves;
      users.value         = response.data.users;
      workers.value       = response.data.workers;
      watchers.value      = response.data.watchers;
      chains.value        = response.data.chains;
      steps.value         = response.data.steps;
      history.value       = response.data.history;
      timing.value        = response.data.timing;
      timings.value       = response.data.timings;
    }
  };

  return {
    access,
    errors,
    status,
    node,
    actions,
    project,
    subject,
    states,
    path,
    category,
    categories,
    complexities,
    leaves,
    users,
    workers,
    watchers,
    chains,
    steps,
    history,
    timing,
    timings,

    is_access,

    load: async (code) => reload(await SERVICE.load(code)),

    create: async (projects_id, root_id, title) => reload(await SERVICE.create(projects_id, root_id, title)),

    remove: async (id) => reload(await SERVICE.remove(id)),

    finish: async (id) => reload(await SERVICE.finish(id)),

    onward: async (id, status_state_id) => reload(await SERVICE.onward(id, status_state_id)),

    backward: async (id) => reload(await SERVICE.backward(id)),

    update: async (id, field, value) => reload(await SERVICE.update(id, field, value)),

    update_complexities: async (id, value, route_id) => reload(await SERVICE.complexities(id, value, route_id)),

    update_workers: async (id, route_id, worker_id) => reload(await SERVICE.workers(id, route_id, worker_id)),

    update_watcher: async (id, watcher) => reload(await SERVICE.workers(id, watcher)),

    update_timing: async (manual_timing, reason, created, id, states_id) => reload(await SERVICE.timing(manual_timing, reason, created, id, states_id)),
  };
})