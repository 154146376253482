import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(fields) {
    return new Entity(await Server.post("expenses", {
      user_id    : fields.user,
      date_start : fields.date_start,
      date_finish: fields.date_finish,
      price      : fields.price
    }));
  }

  static async states(project_id, option, value) {
    return new Entity(await Server.post("expenses/states", {
      id    : project_id,
      option: option,
      value : value
    }));
  }

  static async priority(projects) {
    return new Entity(await Server.post("expenses/priority", {
      list: projects
    }));
  }
}