<script setup>
  import { ref, watchEffect, onMounted, defineProps, defineEmits } from "vue";

  import Default from "./Default.vue";

  import FieldInput from "../../../../fields/input/Default.vue";

  const props = defineProps({
    view: { type: Boolean, default: () => false },
    edit: { type: Boolean, default: () => false },
    plan: { type: Number , default: () => 0     },
    fact: { type: Number , default: () => 0     }
  });

  const emit = defineEmits([
    "switch_show", "switch_edit", "handler"
  ]);

  const percent = ref(0);

  const value = ref(0);

  onMounted(() => {
    value.value = props.plan
    recount(props.plan, props.fact)
  });

  watchEffect(() => recount(props.plan, props.fact));

  function recount(plan, fact) {
    if (plan) {
      percent.value = Math.ceil(fact / (plan * 3600) * 100);
    }
  };

  function handler() {
    emit("handler", value.value);
    emit("switch_edit");
  }
</script>

<template>
  <div class="d-flex"
    @click.exact.stop.prevent="emit('switch_show')"
    @dblclick.exact.stop.prevent="emit('switch_edit')"
  >
    <default entity="estimate" :percent="percent" :defined="!!props.plan">
      <template #content>
        <div v-if="props.view && !props.edit"
          class="d-flex justify-content-center align-items-center ml-2 mr-4"
        >
          <div class="font-section">
            {{ Math.round(props.fact / 360) / 10 }}
          </div>
          /
          <div class="font-soft">
            {{ props.plan }}
          </div>
        </div>

        <div v-if="props.edit"
          class="d-flex w-75 mt--1 mr-4"

          @click.stop
          @keydown.stop
          @keyup.stop
        >
          <field-input v-model="value"
            class="min"

            :autofocus="true"

            @keyup.esc.exact.stop.prevent="emit('switch_edit')"
            @keyup.enter.exact.stop.prevent="handler"
          />
        </div>
      </template>
    </default>
  </div>
</template>