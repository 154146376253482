<script setup>
  import { ref, defineProps, defineEmits } from "vue";

  import FieldInput from "../../../fields/input/Default.vue";

  const props = defineProps({
    autofocus  : { type: Boolean, default: () => false  },
    placeholder: { type: String , default: () => "name" }
  });

  const emit = defineEmits([ "close", "create" ]);

  const title = ref("");
  const clear = () => title.value = "";

  function close() {
    emit("close");
    clear();
  };

  function submit() {
    if (title.value != "") {
      emit("create", title.value);
      clear();
    }
  };
</script>

<template>
  <div
    class="card default p-relative d-flex border-radius-primary border-1 color-border-soft box-shadow-card"
    tabindex="1"

    @click.stop
    @keydown.stop
    @keyup.stop
  >
    <div class="d-flex flex-column justify-content-between w-100 p-4">
      <field-input
        v-model="title"
        :autofocus="props.autofocus"
        :placeholder="$t(props.placeholder)"

        @keyup.left.stop.prevent
        @keyup.right.stop.prevent
        @keyup.up.stop.prevent
        @keyup.down.stop.prevent
        @keyup.esc.exact.stop.prevent="close"
        @keyup.enter.exact.stop.prevent="submit"

        @close="close"
      />
    </div>
  </div>
</template>