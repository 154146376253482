export class Blank {
  constructor (id, region) {
    this.id     = id >= 0 ? id    : -1;
    this.region = region  ? region: "";
  }
}

export class States {
  constructor (states, Class) {
    this.states = states;

    this.class = Class;
    this.blank = new Class();
  }

  get_state(id, region, field) {
    let state = this.states.find(state =>
      state.id == id && state.region == region
    );

    if (state)
      return state[field];

    return this.blank[field];
  }

  cleanup(fields, id) {
    this.states.forEach(state => {
      if (id === undefined || state.id == id)
        fields.forEach(field => state[field] = false)
    });
  }

  switch_state(id, region, field, value) {
    let state = this.states.find(state =>
      state.id == id && state.region == region
    );

    if (state) {
      state[field] = value !== undefined ? value : !state[field];
    }
    else {
      this.states.push(new this.class(id, region, field, value !== undefined ? value : true));
    }
  }
}