<script setup>
  import { onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import { Hotkeys, Action } from "../../../../packages/hotkeys/Hotkeys";

  import WidgetCard      from "../../../../visual/widgets/cards/Default.vue";
  import WidgetCardBlank from "../../../../visual/widgets/cards/default/Blank.vue";

  const REGION_TREE  = "tree";
  const REGION_LIST  = "list";
  const REGION_CHART = "chart";

  onMounted(async () => {
    Hotkeys.change(REGION_CHART, REGION_LIST);
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ESC  , (nodes) => nodes.forEach((node) => jump_prev(node))),
      new Action(Hotkeys.KEYCODE_ENTER, (nodes) => nodes.forEach((node) => jump_next(node))),
      new Action(Hotkeys.KEYCODE_D    , (nodes) => nodes.forEach((node) => jump_next(node, "desc")), { ctrl: true, shift: true, stop: true }),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => move(node, "left") )),
      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => move(node, "up")   )),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => move(node, "right"))),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => move(node, "down") )),

      new Action(Hotkeys.KEYCODE_INSERT, (nodes) => nodes.forEach((node) => sibling(node))),
      new Action(Hotkeys.KEYCODE_ENTER , (nodes) => nodes.forEach((node) => sibling(node)), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_X, (nodes) => nodes.forEach((node) => source.node_clone(node.dataset.id, "cut" )), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_C, (nodes) => nodes.forEach((node) => source.node_clone(node.dataset.id, "copy")), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_V, (nodes) => nodes.forEach((node) => source.node_paste(node.dataset.id        )), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_O  , (nodes) => nodes.forEach((node) => state.switch_process(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_M  , (nodes) => nodes.forEach((node) => state.switch_context(+node.dataset.id, REGION_LIST)), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_F2 , (nodes) => nodes.forEach((node) => state.switch_heading(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_process(-1)),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_context(-1)),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_heading(-1)),

      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_dead_show(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_done_show(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_step_show(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_I, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_work_show(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.dataset.id, REGION_LIST))),
      new Action(Hotkeys.KEYCODE_D, () => state.switch_dead_show(-1, REGION_LIST, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_done_show(-1, REGION_LIST, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_step_show(-1, REGION_LIST, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_T, () => state.switch_time_show(-1, REGION_LIST, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_I, () => state.switch_work_show(-1, REGION_LIST, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => state.switch_dead_edit(+node.dataset.id, REGION_LIST)), { shift: true }),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_edit(+node.dataset.id, REGION_LIST)), { shift: true }),

      new Action(Hotkeys.KEYCODE_B, () => source.tree_show({ id: source.get_active.id, status: !source.tree.show }))
    ], REGION_LIST);
    Hotkeys.focused();
  });

  onUnmounted(() => Hotkeys.reinit(REGION_LIST));

  function get_nodes_ids() {
    return source.chart.nodes.map(node => node.entity.id);
  }

  function pick(node) {
    state.node_activate(node)
    Hotkeys.focused(undefined, node.id);
  }

  function jump_prev(node) {
    if (!state.detail.id) {
      if (Hotkeys.change(REGION_TREE)) {
        Hotkeys.toggle(node);
        Hotkeys.focused("tree-item", source.tree.pick);
      }
    }

    state.node_deactivate();
  };

  function jump_next(node, action = "") {
    let target = source.chart.nodes.find(target =>
      target.entity.id == node.dataset.id
    );

    target  && state.node_activate(target.entity, action);
    !target && document.querySelector('#list-blank input').focus();
  };

  function move(node, action) {
    let offset = { left: -1, right: 1, up: -4, down: 4 };
    let target = document.querySelector(`[data-region="${REGION_LIST}"] [data-index="${(+node.dataset.index + offset[action])}"]`);

    if (target) {
      Hotkeys.toggle(node);
      Hotkeys.toggle(target);

      target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    }
  }

  function sibling(node) {
    let target = document.querySelector('#list-blank')

    Hotkeys.toggle(node);
    Hotkeys.toggle(target);

    document.querySelector('#list-blank input').focus();

    target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
  }

  function close() {
    Hotkeys.focused();
    document.querySelector('#list-blank input').blur();
  }

  function focus() {
    Hotkeys.focused();
    document.querySelector('#list-blank input').focus();
  };

  const menu = (node, stages) => {
    let items = [
      { title: "Details", separator: false },
      { title: "Create" , separator: true  },
      { title: "Cut"    , separator: false },
      { title: "Copy"   , separator: false },
      { title: "Paste"  , separator: true  },
      { title: "Access" , separator: true, items: [ { title: "Limited", separator: false }, { title: "Shared" , separator: false } ] }
    ];

    if (stages[node.id] && stages[node.id][0] && stages[node.id][0].state_id) {
      if (stages[node.id].find(stage => stage.state_status == "current")) {
        items.push({ title: "Finish" , separator: false });
      }
    }
    else {
      items.push({ title: "Remove" , separator: false  });
    }

    return items;
  };

  const part = (node) => { return {
    fold   : false,
    content: true,
    weight : false,
    actions: { menu: true, launch: !!(!node.branch && (!node.state.weight || node.state.weight == 'IDEA')), create: false, finish: false },
    diagram: { done: true, work: true, time: true, dead: true, step: true }
  }; };

  const sort = () => {
    let list = source.chart.nodes;
    return list.sort((x, y) => { return (x.entity.branch === y.entity.branch) ? 0 : (x.entity.branch ? -1 : 1); });
  };

  const GRID = 4;
</script>

<template>
  <div
    :data-region="REGION_LIST"
    class="overflow-y-scroll scrollbar w-100 h-100"
  >
    <div class="box-grid px-8 pb-8" :class="['col-' + GRID]">
      <widget-card-blank
        id="list-blank"
        class="base-neutral"
        :data-id="source.tree.pick || 0"
        :data-index="1"

        :placeholder="'input_name_and_press_enter'"

        @create="(title) => source.node_child({ id: source.tree.pick || 0, title: title, offset: -1 }, false)"
        @close="close"
        @keyup.esc.exact.stop.prevent="close"
      />

      <widget-card v-for="(node, index) in sort()"
        :key="node.entity.id"
        :data-id="node.entity.id"
        :data-code="node.entity.code"
        :data-index="index + 2"
        :data-branch="node.entity.branch"

        class="list-item h-100 base-neutral"
        draggable="true"

        :item="node.entity"
        :menu="menu(node.entity, source.chart.stage)"
        :part="part(node.entity)"
        :chains="source.chains"
        :stages="source.chart.stage[node.entity.id]"

        :selected="state.select == node.entity.id"
        :region="REGION_LIST"

        :context="state.get_state(node.entity.id, REGION_LIST, 'context')"
        :process="state.get_state(node.entity.id, REGION_LIST, 'process')"
        :heading="state.get_state(node.entity.id, REGION_LIST, 'heading')"
        :dead_show="state.get_state(node.entity.id, REGION_LIST, 'dead_show')"
        :done_show="state.get_state(node.entity.id, REGION_LIST, 'done_show')"
        :step_show="state.get_state(node.entity.id, REGION_LIST, 'step_show')"
        :time_show="state.get_state(node.entity.id, REGION_LIST, 'time_show')"
        :work_show="state.get_state(node.entity.id, REGION_LIST, 'work_show')"
        :dead_edit="state.get_state(node.entity.id, REGION_LIST, 'dead_edit')"
        :time_edit="state.get_state(node.entity.id, REGION_LIST, 'time_edit')"

        @switch_context="state.switch_context"
        @switch_process="state.switch_process"
        @switch_heading="state.switch_heading"
        @switch_dead_show="state.switch_dead_show"
        @switch_done_show="state.switch_done_show"
        @switch_step_show="state.switch_step_show"
        @switch_time_show="state.switch_time_show"
        @switch_work_show="state.switch_work_show"
        @switch_dead_edit="state.switch_dead_edit"
        @switch_time_edit="state.switch_time_edit"

        @pick="(item) => pick(item)"
        @create="() => focus()"
        @clone="(id, action) => source.node_clone(id, action)"
        @paste="(id) => source.node_paste(id)"
        @finish="(id) => source.node_finish(id)"
        @launch="(data) => source.node_launch(data)"
        @access="(id, type) => source.node_access(id, type)"
        @update="(option, id, value) => source.node_update(option, id, value)"
        @remove="(id) => source.node_remove(id)"

        @dblclick="node.entity.branch && source.tree_pick(node.entity.id)"

        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
        @dragstart.stop="source.node_move_start($event, node.entity)"
        @drop.stop="source.node_move_drop($event, node.entity)"
      />
    </div>
  </div>
</template>