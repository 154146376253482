export default {
  ru: {
    Stream     : "Лента",
    News       : "Новости",
    Observation: "Наблюдение"
  },
  en: {
    Stream     : "Stream",
    News       : "News",
    Observation: "Observation"
  }
};