<script setup>
  import { computed, defineProps } from "vue";

  import WebHeaderMenu from "./header/Menu.vue";
  import WidgetTracker from "../../visual/widgets/tracker/Default.vue";

  import Header from "./Header.js";

  const props = defineProps({
    user : { type: Object, default: () => {} },
    items: { type: Array , default: () => [] }
  });

  const actions = computed(() => Header.computed(props.items));
</script>

<template>
  <div class="d-flex w-100 h-80 min-h-80 max-h-80 color-base-light">
    <div
      id="header-widget-tracker"
      class="d-flex justify-content-center align-items-center flex-grow p-relative p-8"
    >
      <widget-tracker />
    </div>

    <div class="d-flex justify-content-end w-20 max-w-300px">
      <web-header-menu
        :user="props.user"
        :items="actions"
      />
    </div>
  </div>
</template>