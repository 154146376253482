<script setup>
  import { onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import { Hotkeys, Action } from "../../../../packages/hotkeys/Hotkeys";

  import WidgetVertical  from "../../../../visual/widgets/regions/Vertical.vue";
  import WidgetCard      from "../../../../visual/widgets/cards/Milestone.vue";
  import WidgetCardBlank from "../../../../visual/widgets/cards/milestone/Blank.vue";

  const REGION_TREE  = "tree";
  const REGION_LIST  = "list";
  const REGION_CHART = "chart";

  onMounted(async () => {
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ENTER, (nodes) => nodes.forEach((node) => pick(node))),

      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => jump_sprint(node)), { shift: true }),

      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => move(node, "up")  )),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => move(node, "down"))),

      new Action(Hotkeys.KEYCODE_INSERT, () => state.$patch({ milestone_blank: 1 })),
      new Action(Hotkeys.KEYCODE_ENTER , () => state.$patch({ milestone_blank: 1 }), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_M , (nodes) => nodes.forEach((node) => state.switch_context(+node.closest("[data-id]").dataset.id, REGION_TREE)), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_F2, (nodes) => nodes.forEach((node) => state.switch_heading(+node.closest("[data-id]").dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_F2, (nodes) => nodes.forEach((node) => state.switch_period(+node.closest("[data-id]").dataset.id, REGION_TREE)), { shift: true }),

      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => state.switch_done_show(+node.closest("[data-id]").dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_I, (nodes) => nodes.forEach((node) => state.switch_work_show(+node.closest("[data-id]").dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.closest("[data-id]").dataset.id, REGION_TREE))),
    ], REGION_TREE);
  });

  onUnmounted(() => Hotkeys.reinit(REGION_TREE));

  async function pick(node) {
    await source.milestone_pick(node.id || node.closest("[data-id]").dataset.id);
    Hotkeys.change(REGION_CHART);
    Hotkeys.focused();
  }

  function jump_sprint(node) {
    if (Hotkeys.change(REGION_LIST)) {
      Hotkeys.toggle(node);
      Hotkeys.focused("list-item");
    }

    state.node_deactivate();
  };

  function move(node, action) {
    let target = action == "down" ?
      node.closest("[data-id]").nextSibling :
      node.closest("[data-id]").previousSibling
    ;

    target = target && target.childNodes.length && target.getElementsByClassName("tree-item")[0];

    if (target) {
      Hotkeys.toggle(node);
      Hotkeys.toggle(target);

      target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    }
  }

  const menu = () => { return [
    { title: "Finish" , separator: true },
    { title: "Remove" , separator: false },
  ]; };

  const part = () => { return {
    actions: { menu: true, finish: true },
    diagram: { done: true, work: true, time: true }
  }; };
</script>

<template>
  <div
    id="roadmap-tree"
    :data-region="REGION_TREE"
    class="overflow-y-scroll scrollbar-disable w-300px min-w-300px pr-8 pb-8 pt-10 mt--10"
  >
    <widget-vertical
      :array="source.milestones"
      @change="(target, source, offset) => source.milestone_order(target, source, offset)"
    >
      <template #item="element">
        <widget-card
          class="tree-item slave my-4 base-neutral w-100"
          :class="{ 'base-soft': element.item.shipped }"

          :item="element.item"
          :menu="menu()"
          :part="part()"

          :selected="source.get_active.road_pick == element.item.id"
          :region="REGION_TREE"

          :context="state.get_state(element.item.id, REGION_TREE, 'context')"
          :heading="state.get_state(element.item.id, REGION_TREE, 'heading')"
          :period="state.get_state(element.item.id, REGION_TREE, 'period')"
          :done_show="state.get_state(element.item.id, REGION_TREE, 'done_show')"
          :time_show="state.get_state(element.item.id, REGION_TREE, 'time_show')"
          :work_show="state.get_state(element.item.id, REGION_TREE, 'work_show')"

          @switch_context="state.switch_context"
          @switch_heading="state.switch_heading"
          @switch_period="state.switch_period"
          @switch_done_show="state.switch_done_show"
          @switch_time_show="state.switch_time_show"
          @switch_work_show="state.switch_work_show"

          @pick="(item) => pick(item)"
          @finish="(id) => source.milestone_shipped(id)"
          @remove="(id) => source.milestone_remove(id)"
          @update="(option, id, value) => source.milestone_update(option, id, value)"

          @dragover.prevent
          @dragenter.prevent
          @dragleave.prevent
          @dragend.prevent
          @drop="source.node_move($event, element.item.id)"
        />
      </template>
    </widget-vertical>

    <widget-card-blank v-if="state.milestone_blank"
      class="my-4 base-neutral min-w-200px"

      :autofocus="true"

      @close="state.$patch({ milestone_blank: 0 })"
      @create="(value) => source.milestone_create(value)"
    />

    <div v-else
      class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
      @click.stop.prevent="state.$patch({ milestone_blank: 1 })"
    >
      {{ $t("add_step") }}
    </div>
  </div>
</template>