<script setup>
  import { onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import { Hotkeys, Action } from "../../../../packages/hotkeys/Hotkeys";

  import WidgetHierarchy from "../../../../visual/widgets/regions/Hierarchy.vue";
  import WidgetCard      from "../../../../visual/widgets/cards/Default.vue";
  import WidgetCardBlank from "../../../../visual/widgets/cards/default/Blank.vue";

  const REGION_TREE  = "tree";
  const REGION_LIST  = "list";
  const REGION_CHART = "chart";

  onMounted(async () => {
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ENTER, (nodes) => nodes.forEach((node) => pick(node))),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => fold(node, "hide")), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => fold(node, "show")), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => move(node, "up")  )),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => move(node, "down"))),

      new Action(Hotkeys.KEYCODE_INSERT, (nodes) => nodes.forEach((node) => state.$patch({ blank: node.closest("[data-id]").dataset.id }))),
      new Action(Hotkeys.KEYCODE_ENTER , (nodes) => nodes.forEach((node) => state.$patch({ blank: node.closest("[data-id]").dataset.id })), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_X, (nodes) => nodes.forEach((node) => source.node_clone(node.closest("[data-id]").dataset.id, "cut" )), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_C, (nodes) => nodes.forEach((node) => source.node_clone(node.closest("[data-id]").dataset.id, "copy")), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_V, (nodes) => nodes.forEach((node) => source.node_paste(node.closest("[data-id]").dataset.id        )), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_O  , (nodes) => nodes.forEach((node) => state.switch_process(+node.closest("[data-id]").dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_M  , (nodes) => nodes.forEach((node) => state.switch_context(+node.closest("[data-id]").dataset.id, REGION_TREE)), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_F2 , (nodes) => nodes.forEach((node) => state.switch_heading(+node.closest("[data-id]").dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_process(-1)),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_context(-1)),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_heading(-1)),

      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_dead_show(+node.dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_done_show(+node.dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_step_show(+node.dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_I, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_work_show(+node.dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.dataset.id, REGION_TREE))),
      new Action(Hotkeys.KEYCODE_D, () => state.switch_dead_show(-1, REGION_TREE, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_done_show(-1, REGION_TREE, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_step_show(-1, REGION_TREE, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_T, () => state.switch_time_show(-1, REGION_TREE, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_I, () => state.switch_work_show(-1, REGION_TREE, get_nodes_ids()), { alt: true }),
      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => state.switch_dead_edit(+node.dataset.id, REGION_TREE)), { shift: true }),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_edit(+node.dataset.id, REGION_TREE)), { shift: true })
    ], REGION_TREE);
  });

  onUnmounted(() => Hotkeys.reinit(REGION_TREE));

  function get_nodes_ids() {
    return source.tree.data.nodes.map(node => node.entity.id);
  }

  async function pick(node) {
    await source.tree_pick(typeof node.id !== 'undefined' ? node.id : node.closest("[data-id]").dataset.id);
    Hotkeys.change(REGION_CHART, REGION_LIST);
    Hotkeys.focused();
  }

  function fold(node, action) {
    let target = source.tree.data.nodes.find(target =>
      target.entity.id == node.closest("[data-id]").dataset.id
    );

    if (
      action == "hide" && target.entity.folded.access && !target.entity.folded.status ||
      action == "show" && target.entity.folded.access && target.entity.folded.status
    ) source.tree_fold(target.entity.id);
  }

  function move(node, action) {
    let target = action == "down" ?
      node.closest("[data-id]").nextSibling :
      node.closest("[data-id]").previousSibling
    ;

    target = target && target.childNodes.length && target.getElementsByClassName("tree-item")[0];

    if (target) {
      Hotkeys.toggle(node);
      Hotkeys.toggle(target);

      target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    }
  }

  const menu = (full) => { return full ? [
    { title: "Details", separator: true  },
    { title: "Create" , separator: true  },
    { title: "Cut"    , separator: false },
    { title: "Copy"   , separator: false },
    { title: "Paste"  , separator: true  },
    { title: "Access" , separator: true, items: [ { title: "Limited", separator: false }, { title: "Shared" , separator: false } ] },
    { title: "Remove" , separator: false },
  ] : [
    { title: "Paste"  , separator: true },
    { title: "Create" , separator: true },
    { title: "Access" , separator: false, items: [ { title: "Limited", separator: false }, { title: "Shared" , separator: false } ] }
  ]; };

  const part = (full) => { return {
    fold   : full,
    content: false,
    weight : false,
    actions: { menu: true, launch: true, create: true, finish: false },
    diagram: { done: true, work: true, time: true, dead: false, step: false }
  }; };
</script>

<template>
  <div
    id="schema-tree"
    :data-region="REGION_TREE"
    class="base-light overflow-y-scroll scrollbar-disable w-25 pr-16 pb-16 pt-10 mt--10"
  >
    <div class="w-fit-content min-w-100">
      <widget-hierarchy
        :array="source.tree.data.nodes"
        @change="(target, source, offset) => source.tree_order(target, source, offset)"
      >
        <template #item="element">
          <div class="d-flex min-w-200px">
            <div v-for="n in element.item.option.level + 1"
              :key="n"
              class="w-34px max-w-34px min-w-34px border-left-3 border-highlight-slave"
            />

            <widget-card
              :data-level="element.item.option.level + 1"

              class="tree-item slave base-neutral min-w-200px"
              :class="{ 'my-4' : element.item.entity.id, 'mb-4': !element.item.entity.id }"

              :item="element.item.entity"
              :menu="menu(element.item.entity.id)"
              :part="part(element.item.entity.id)"
              :chains="source.chains"

              :selected="source.tree.pick == element.item.entity.id || (!source.tree.pick && !element.item.entity.id)"
              :region="REGION_TREE"

              :context="state.get_state(element.item.entity.id, REGION_TREE, 'context')"
              :process="state.get_state(element.item.entity.id, REGION_TREE, 'process')"
              :heading="state.get_state(element.item.entity.id, REGION_TREE, 'heading')"
              :dead_show="state.get_state(element.item.entity.id, REGION_TREE, 'dead_show')"
              :done_show="state.get_state(element.item.entity.id, REGION_TREE, 'done_show')"
              :step_show="state.get_state(element.item.entity.id, REGION_TREE, 'step_show')"
              :time_show="state.get_state(element.item.entity.id, REGION_TREE, 'time_show')"
              :work_show="state.get_state(element.item.entity.id, REGION_TREE, 'work_show')"
              :dead_edit="state.get_state(element.item.entity.id, REGION_TREE, 'dead_edit')"
              :time_edit="state.get_state(element.item.entity.id, REGION_TREE, 'time_edit')"

              @switch_context="state.switch_context"
              @switch_process="state.switch_process"
              @switch_heading="state.switch_heading"
              @switch_dead_show="state.switch_dead_show"
              @switch_done_show="state.switch_done_show"
              @switch_step_show="state.switch_step_show"
              @switch_time_show="state.switch_time_show"
              @switch_work_show="state.switch_work_show"
              @switch_dead_edit="state.switch_dead_edit"
              @switch_time_edit="state.switch_time_edit"

              @pick="(node) => pick(node)"
              @fold="(id) => source.tree_fold(id)"
              @create="(id) => state.$patch({ blank: id })"
              @clone="(id, action) => source.node_clone(id, action)"
              @paste="(id) => source.node_paste(id)"
              @launch="(data) => source.node_launch(data)"
              @access="(id, type) => source.node_access(id, type)"
              @update="(option, id, value) => source.node_update(option, id, value)"
              @remove="(id) => source.node_remove(id)"

              @dragover.prevent
              @dragenter.prevent
              @dragleave.prevent
              @drop="source.node_move_drop($event, element.item.entity)"
            />
          </div>

          <div v-if="state.blank == element.item.entity.id" class="d-flex w-100">
            <div v-for="n in element.item.option.level + 2"
              :key="n"
              class="w-34px max-w-34px min-w-34px border-left-3 border-highlight-slave"
            />

            <widget-card-blank
              class="base-neutral mb-4 min-w-200px"
              :autofocus="true"
              @close="state.$patch({ blank: -1 })"
              @create="(title) => source.node_branch({ id: state.blank, title: title, offset: -1 })"
            />
          </div>
        </template>
      </widget-hierarchy>
    </div>
  </div>
</template>