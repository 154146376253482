<script setup>
  import { ref, defineProps, defineEmits } from "vue";

  const props = defineProps({
    tabs: { type: Array, default: () => [] }
  });

  const emit = defineEmits([
    "activate"
  ]);

  const state = ref([]);

  function hide(tab) {
    state.value[tab.id] = false;
  };

  function click(tab) {
    if (tab.list) {
      state.value[tab.id] = !state.value[tab.id];
    }
    else {
      emit("activate", tab);
    }
  }
</script>

<template>
  <ul class="tabs d-flex base-neutral flex-wrap m-0 p-0">
    <li v-for="tab in props.tabs"
      :key="tab.id"

      class="tab d-flex align-items-center"
      :class="{ active: tab.active || state[tab.id]}"

      @click="click(tab)"
      @mouseleave="hide(tab)"
    >
      <div class="item d-flex flex-grow align-items-center justify-content-center c-pointer">
        {{ $t(tab.name) }}

        <div v-if="state[tab.id]"
          class="dropdown"
        >
          <div v-for="item in tab.list"
            :key="item.id"

            class="tab d-flex align-items-center"

            @click="click(item)"
          >
            <div class="item d-flex flex-grow align-items-center justify-content-center c-pointer">
              {{ $t(item.name) }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>