<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    string: { type: String, default: () => "" }
  });
</script>

<template>
  <div
    class="d-flex font-soft text-nowrap"

    @click.exact.stop.prevent
  >
    #{{ props.string }}
  </div>
</template>