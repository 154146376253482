<script setup>
  import { ref, watchEffect, onMounted, defineProps, defineEmits } from "vue";

  import moment from "moment";

  import Default from "./Default.vue";

  import FieldInput from "../../../../fields/input/Default.vue";

  const props = defineProps({
    view    : { type: Boolean, default: () => false },
    edit    : { type: Boolean, default: () => false },
    start   : { type: String , default: () => ""    },
    deadline: { type: String , default: () => ""    }
  });

  const emit = defineEmits([
    "switch_show", "switch_edit", "handler"
  ]);

  const percent = ref(0);

  const value = ref(moment().format("YYYY-MM-DD"));

  onMounted(() => {
    value.value = props.deadline ? moment(props.deadline).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    recount(props.deadline);
  });

  watchEffect(() => recount(props.deadline));

  function recount(deadline) {
    if (deadline) {
      let summary = moment(deadline).diff(moment(props.start), 'days');
      let current = moment().diff(moment(props.start), 'days');

      if (moment() <= moment(props.start)) {
        percent.value = 0;
      }
      else {
        percent.value = summary == 0 ? 0 : Math.ceil(current / summary * 100);
      }
    }
    else {
      percent.value = 0;
    }
  };

  function format(date) {
    return moment(date).format("DD.MM");
  };

  function handler() {
    if (value.value && moment(value.value).isValid()) {
      emit("handler", moment(value.value).format("YYYY-MM-DD"));
    }
    emit("switch_edit");
  };
</script>

<template>
  <div class="d-flex"
    @click.exact.stop.prevent="emit('switch_show')"
    @dblclick.exact.stop.prevent="emit('switch_edit')"
  >
    <default entity="calendar" :percent="percent" :defined="!!props.deadline">
      <template #content>
        <div v-if="props.view && !props.edit"
          class="d-flex justify-content-center align-items-center ml-2 mr-4"
        >
          <div class="font-section">
            {{ format(props.start) }}
          </div>
          /
          <div class="font-soft">
            {{ props.deadline && format(props.deadline) || "-.-" }}
          </div>
        </div>

        <div v-if="props.edit"
          class="d-flex w-75 mt--1 mr-4"

          @click.stop
          @keydown.stop
          @keyup.stop
        >
          <field-input v-model="value"
            class="min"

            :autofocus="true"

            @keyup.esc.exact.stop.prevent="emit('switch_edit');"
            @keyup.enter.exact.stop.prevent="handler"
          />
        </div>
      </template>
    </default>
  </div>
</template>