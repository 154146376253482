<script setup>
  import { ref, defineProps, defineEmits } from "vue";

  import FieldCheckbox from "../../fields/checkbox/Default.vue";

  const props = defineProps({
    status  : { type: Object, default: () => {} },
    workers : { type: Object, default: () => {} },
    projects: { type: Array , default: () => [] },
    spaces  : { type: Array , default: () => [] }
  });

  const emit = defineEmits([
    "status_handler", "workers_handler", "projects_handler", "spaces_handler"
  ]);

  const dropdown = ref(false);

  function selected() {
    return props.projects.find(project => project.hidden) ||
      props.spaces.find(space => space.hidden) ||
      (props.workers && props.workers.selected && props.workers.selected.length) ||
      (props.status && (props.status.progress || props.status.archived))
    ;
  };
</script>

<template>
  <div class="p-relative">
    <div
      class="d-flex p-relative justify-content-center align-items-center border-radius-12 box-shadow-bottom w-32px h-32px c-pointer"
      :class="{
        'base-neutral': !dropdown,
        'base-highlight': dropdown
      }"
      @click.stop="dropdown = !dropdown"
    >
      <div
        class="icon"
        :class="{
          'icon-filter': !dropdown,
          'icon-filter-hover': dropdown
        }"
      />

      <div v-if="selected() && !dropdown"
        class="p-absolute base-highlight w-10px h-10px border-radius-8 t--2px r--2px"
      />
    </div>

    <div v-if="dropdown"
      class="p-absolute t-39px r-0 w-200px p-16 base-neutral z-107 box-shadow-bottom border-radius-16 d-flex flex-column"
    >
      <div v-if="props.status">
        <div class="font-memo font-light mb-16 lh-16">
          {{ $t("Status") }}
        </div>

        <field-checkbox v-if="props.status.hasOwnProperty('progress')"
          lang
          :value="{ id: 0, content: 'in_progress', status: props.status.progress }"
          @submit="(data) => emit('status_handler', 'filter_progress', +data.status)"
        />

        <field-checkbox v-if="props.status.hasOwnProperty('ideas')"
          lang
          :value="{ id: 0, content: 'ideas', status: props.status.ideas }"
          @submit="(data) => emit('status_handler', 'filter_ideas', +data.status)"
        />

        <field-checkbox v-if="props.status.hasOwnProperty('tasks')"
          lang
          :value="{ id: 0, content: 'tasks', status: props.status.tasks }"
          @submit="(data) => emit('status_handler', 'filter_tasks', +data.status)"
        />

        <field-checkbox v-if="props.status.hasOwnProperty('archived')"
          lang
          :value="{ id: 0, content: 'archived', status: props.status.archived }"
          @submit="(data) => emit('status_handler', 'filter_archived', +data.status)"
        />
      </div>

      <div v-if="props.workers">
        <div class="font-memo font-light mb-16 lh-16">
          {{ $t("workers") }}
        </div>

        <div class="avatar list d-flex mb-16">
          <div class="d-flex flex-wrap">
            <div
              v-for="user in props.workers.users"
              :key="user.id"
              class="item default base-highlight-slave c-pointer"
              :class="{'locked': props.workers.selected.includes(user.id)}"
              @click="emit('workers_handler', user.id)"
            >
              {{ user.name.charAt(0) + user.lastname.charAt(0) }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="props.projects.length">
        <div class="font-memo font-light mb-16 lh-16">
          {{ $t("Projects") }}
        </div>

        <div v-for="project in props.projects" :key="project.id">
          <field-checkbox
            :value="{ id: project.id, content: project.name, status: !project.hidden }"
            @submit="(data) => emit('projects_handler', data.id)"
          />
        </div>
      </div>

      <div v-if="props.spaces.length">
        <div class="font-memo font-light mb-16 lh-16">
          {{ $t("Organizations") }}
        </div>

        <div v-for="space in props.spaces" :key="space.id">
          <field-checkbox
            :value="{ id: space.id, content: space.name, status: !space.hidden }"
            @submit="(data) => emit('spaces_handler', data.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>