<script setup>
  import { defineAsyncComponent } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  const StreamPlugin = defineAsyncComponent(() => import("../../../stream/plugins/complex/Projects.vue"));
  const NodePlugin   = defineAsyncComponent(() => import("../../../node/plugins/complex/Projects.vue"));
  const CostPlugin   = defineAsyncComponent(() => import("../../../roadmap/plugins/complex/Cost.vue"));
  const TimePlugin   = defineAsyncComponent(() => import("../../../roadmap/plugins/complex/Time.vue"));
  const TeamPlugin   = defineAsyncComponent(() => import("../../../team/plugins/complex/Time.vue"));
  const RejectPlugin = defineAsyncComponent(() => import("../../../chains/plugins/complex/Projects.vue"));

  const style = "d-flex flex-column h-100 overflow-hidden";
</script>

<template>
  <div class="d-flex overflow-y-scroll scrollbar-disable">
    <div v-if="state.select == 'node'" :class="style">
      <node-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'stream'" :class="style">
      <stream-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'cost'" :class="style">
      <cost-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'time'" :class="style">
      <time-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'team'" :class="style">
      <team-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'test'" :class="style">
      <!-- <test-plugin :projects_id="source.get_active.id" /> -->
      <reject-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'mr'" :class="style">
      <!-- <mr-plugin :projects_id="source.get_active.id" /> -->
      <reject-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'docs'" :class="style">
      <!-- <docs-plugin :projects_id="source.get_active.id" /> -->
      <cost-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'incidents'" :class="style">
      <!-- <Incidents-plugin :projects_id="source.get_active.id" /> -->
      <stream-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'bugs'" :class="style">
      <!-- <bugs-plugin :projects_id="source.get_active.id" /> -->
      <reject-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'reject'" :class="style">
      <reject-plugin :projects_id="source.get_active.id" />
    </div>

    <div v-if="state.select == 'cash'" :class="style">
      <!-- <cash-plugin :projects_id="source.get_active.id"/> -->
      <time-plugin :projects_id="source.get_active.id" />
    </div>
  </div>
</template>