export default {
  ru: {
    Schedule        : "Расписание",
    add_event       : "Создать событие",
    create          : "Создать",
    insert_title    : "Укажите название",
    insert_project  : "Укажите проект",
    insert_chain    : "Укажите процесс",
    insert_time     : "Укажите время",
    title_is_empty  : "Название не указано",
    project_is_empty: "Проект не указан",
    chain_is_empty  : "Процесс не указан",
  },
  en: {
    Schedule        : "Schedule",
    add_event       : "Add event",
    create          : "Create",
    insert_title    : "Insert title",
    insert_project  : "Insert project",
    insert_chain    : "Insert chain",
    insert_time     : "Insert time",
    title_is_empty  : "Title is empty",
    project_is_empty: "Project is empty",
    chain_is_empty  : "chain is empty",
  }
};