<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  function redirect_project()    { window.location.href = "/projects/" + source.project.id; };
  function redirect_schema(code) { window.location.href = "/schema/"   + code;              };
</script>

<template>
  <div class="d-flex px-27 py-18">
    <div class="path font-soft">
      <div
        class="member"

        @click.stop="redirect_project"
      >
        {{ source.project.name }}
      </div>
    </div>

    <div class="path">
      <div v-for="(member, code) in source.path[source.node.id]"
        :key="code"
      >
        <div
          class="member"

          @click.stop="redirect_schema(code)"
        >
          {{ member }}
        </div>
      </div>
    </div>
  </div>
</template>