<script setup>
  import { ref, reactive, onMounted, defineProps } from "vue";

  import PopupBase  from "../../../../visual/popups/base/Base.vue";
  import FieldInput from "../../../../_visual/fields/input/Input.vue";
  import WidgetCard from "../../../../visual/widgets/cards/Simple.vue";

  import RosterEntity  from "../../entities/Roster.js";
  import RosterService from "../../services/Roster.js";

  const props = defineProps({
    spaces_id: { type: String, default: () => "" },
    root_url : { type: String, default: () => "" }
  });

  const source = reactive(new RosterEntity);
  const popups = reactive({ create: false });

  const fields = reactive({ name: ""    });
  const errors = reactive({ name: false });

  const mounted = ref(false);

  onMounted(async () => {
    Object.assign( source, await RosterService.load(props.spaces_id) );
    mounted.value = true;
  });

  const show_popups_create = () => popups.create = true;
  const hide_popups_create = () => {
    popups.create = false;
    fields.name   = "";
  };

  const attach_errors = () => errors.name = fields.name == "" ? true : false;
  const remove_errors = () => errors.name = false;

  const redirect = (id) => { if (id) window.location.href = `/${props.root_url}/${props.spaces_id}/roles/${id}` }

  const menu = (item) => {
    return item.owner == 0 ? [ "Details", "Remove" ] : [ "Details" ];
  };

  const part = () => {
    return { content: true, actions: { menu: true } };
  };

  async function create() {
    if (fields.name != "") {
      remove_errors();

      Object.assign(
        source,
        await RosterService.create(Object.assign(fields, { spaces_id: props.spaces_id }))
      );

      hide_popups_create();
    } else {
      attach_errors();
    }
  };

  async function remove(id) {
    Object.assign(source, await RosterService.delete(id));
  };
</script>

<template>
  <div v-if="source.access">
    <div class="box-grid col-5">
      <widget-card v-for="item in source.roles"
        :key="item.id"

        class="base-neutral"

        :item="item"
        :menu="menu(item)"
        :part="part()"

        @pick="redirect(item.id)"
        @remove="remove"
      />
    </div>

    <div class="d-flex">
      <div class="button confirm bottom" @click="show_popups_create">
        {{ $t("add") }}
      </div>
    </div>

    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_role") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>