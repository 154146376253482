import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM  from "../system.js";
import SERVICE from "../services/Roster.js";

export const useSourceStore = defineStore("stream_source", () => {
  const access   = ref(false);
  const errors   = ref({});
  const status   = ref({});
  const projects = ref([]);
  const events   = ref([]);
  const nodes    = ref([]);
  const stage    = ref([]);
  const paths    = ref([]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  const get_active = computed(() => {
    return projects.value.find(project => project.active && project.hidden != 1);
  });

  const get_visible = computed(() => {
    return projects.value.filter(project => project.hidden != 1);
  });

  function reload(response) {
    if (response.data) {
      access.value   = response.access;
      errors.value   = response.errors;
      status.value   = response.status;
      projects.value = response.data.projects;
      events.value   = response.data.events;
      nodes.value    = response.data.nodes;
      stage.value    = response.data.stage;
      paths.value    = response.data.paths;
    }
  };

  return {
    access,
    errors,
    status,
    projects,
    events,
    nodes,
    stage,
    paths,

    is_access,

    get_active,
    get_visible,

    load: async (offset, keyword) => reload(await SERVICE.load(offset, keyword)),

    project_activate: async (id, offset, keyword) => reload(await SERVICE.states(id, "project_active", id, offset, keyword)),

    project_visible : async (id, offset, keyword) => reload(await SERVICE.states(id, "project_show"  , id, offset, keyword)),

    project_priority: async (event, offset, keyword) => event.moved &&
      reload(await SERVICE.priority(get_visible.value.map(project => { return { id: project.id }; }), offset, keyword))
  };
})