<template>
  <div class="d-flex flex-column justify-content-center align-items-center w-100" @keydown="submit">
    <field-input
      v-model="email"
      class="mb-10"
      :placeholder="$t('email')"
      :message="$t('email_is_empty')"
      :failure="failures.email"
    />

    <field-input
      v-model="password"
      type="password"
      :placeholder="$t('password')"
      :message="$t('password_is_empty')"
      :failure="failures.password"
    />

    <div class="button primary my-10 w-100--16px" @click="submit">
      {{ $t("Login") }}
    </div>

    <div class="button link" @click="forgot">
      {{ $t("forgot_your_password") }}?
    </div>
  </div>
</template>

<script>
  import {
    reactive,
    ref
  } from "vue";

  import FieldInput from "../../../../_visual/fields/input/Input.vue";

  export default {
    components: {
      FieldInput
    },

    emits: [
      "login",
      "forgot"
    ],

    setup (props, context) {
      const email    = ref("");
      const password = ref("");

      const failures = reactive({
        email   : false,
        password: false
      });

      const EVENT_CLICK   = ref("click");
      const KEYCODE_ENTER = ref(13);

      return {
        email,
        password,
        failures,

        submit: (event) => {
          if (event.type == EVENT_CLICK.value || event.keyCode == KEYCODE_ENTER.value) {
            if (email.value != "" && password.value != "") {
              failures.email    = false;
              failures.password = false;

              context.emit("login", {
                email   : email.value,
                password: password.value
              });
            } else {
              failures.email    = email.value    == "" ? true : false;
              failures.password = password.value == "" ? true : false;
            }
          }
        },

        forgot: () => {
          if (email.value != "") {
            failures.email    = false;
            failures.password = false;

            context.emit("forgot", { email: email.value });
          }
          else {
            failures.email    = email.value == "" ? true : false;
            failures.password = false;
          }
        }
      };
    }
  };
</script>