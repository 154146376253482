import SERVER from "../../../system/server/server";

export default class Roster {
  static async load(keyword = "") {
    return await SERVER.get("projects" + "?keyword=" + keyword);
  }

  static async states(project_id, option, value, keyword = "") {
    return await SERVER.post("projects/states" + "?keyword=" + keyword, { id: project_id, option: option, value: value });
  }

  static async priority(projects, keyword = "") {
    return await SERVER.post("projects/priority" + "?keyword=" + keyword, { list: projects });
  }
}