<script setup>
  import { ref, defineProps, defineEmits } from "vue";

  const props = defineProps({
    value: { type: String, default: () => "" }
  });

  const emit = defineEmits([
    "handler"
  ]);

  const input = ref();

  function refresh() {
    input.value.value = "";
    emit("handler", "");
  }
</script>

<template>
  <div class="field w-100">
    <div class="p-relative"
      @click.stop
    >
      <input :ref="(element) => { input = element }"
        type="text"
        class="action box-shadow-bottom"

        :placeholder="$t('search')"
        :value="props.value"

        @keyup.stop
        @keydown.enter.stop="emit('handler', input.value)"
        @keydown.esc.stop="refresh"
      >

      <div
        class="icon icon-search p-absolute c-pointer t-8px r-8px"

        @click="emit('handler', input.value)"
      />
    </div>
  </div>
</template>