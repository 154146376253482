<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    status: { type: Boolean, default: () => false }
  });

  const emit = defineEmits([
    "handler"
  ]);
</script>

<template>
  <div class="d-flex align-items-center h-100 pl-4">
    <div
      class="icon small"
      :class="{
        'icon-plus': props.status,
        'icon-minus': !props.status,
      }"

      @click.exact.stop.prevent="emit('handler')"
    />
  </div>
</template>