import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(chain_id) {
    return new Entity(await Server.get("chains/detail/" + chain_id));
  }

  static async update(chain_id, field) {
    return new Entity(await Server.put("chains/" + chain_id, field ));
  }
}