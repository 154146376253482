<script setup>
  import { onBeforeMount, onMounted } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../stores/StateStore.js";
  const state = useStateStore();

  import Mediator from "../../../system/mediator/Mediator";
  import { Hotkeys } from "../../../packages/hotkeys/Hotkeys";

  import LayoutWeb            from "../../../layouts/Web.vue";
  import CommonPartialFilter  from "../../../visual/partials/Filter.vue";
  import CommonPartialContent from "../../../visual/partials/Content.vue";
  import PartialFilters       from "./partials/Filters.vue";
  import PartialUsers         from "./partials/Users.vue";
  import PartialPaths         from "./partials/Paths.vue";
  import PartialDepth         from "./partials/Depth.vue";
  import PartialBoard         from "./partials/Board.vue";

  onBeforeMount(() => Hotkeys.reinit());

  onMounted(async () => {
    await source.load();
    Mediator.define("projects:reload", async () => await source.load());
  });
</script>

<template>
  <layout-web fullscreen>
    <common-partial-filter>
      <template #content>
        <partial-filters />
      </template>
    </common-partial-filter>

    <common-partial-content :project="source.get_active" :complex="true" :background="false" message="Choose_space">
      <template #content>
        <div class="d-flex w-100">
          <partial-users />

          <div class="d-flex flex-column w-80">
            <partial-paths />

            <partial-depth v-if="state.select" />
            <partial-board v-else />
          </div>
        </div>
      </template>
    </common-partial-content>
  </layout-web>
</template>

<!-- <template>
  <layout-web fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.spaces"
          @activate="space_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <widget-filter
            class="ml-8"
            :projects="source.spaces"
            @projects_handler="space_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <div class="overflow-y-scroll scrollbar-disable w-300px min-w-300px pr-8 pb-8 pt-10 mt--10">
            <widget-card v-for="user in source.units"
              :key="user.id"

              class="base-neutral mb-8 c-pointer"

              :item="{ id: user.id, name: (user.name + ' ' + user.lastname), description: user.email }"
              :menu="['Remove']"
              :part="{fold: false, content: true, weight: false, actions: {menu: true, launch: false, create: false, finish: false}, diagram: {done: false, work: false, time: false, dead: false, step: false}}"

              @click="pick_user(user.users_id)"
              @remove="remove"
            />

            <div
              class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
              @click="show_popups_create"
            >
              {{ $t("add") }}
            </div>
          </div>

          <div v-if="filter.active.user_pick" class="overflow-y-scroll scrollbar-disable d-flex flex-column flex-grow base-neutral overflow-hidden">
            <div class="p-8">
              <div v-for="project in source.user.roles" :key="project.project_id" class="mb-8">
                <div class="mb-4">{{ project.project_name }}</div>

                <div v-for="role in project.items"
                  :key="role.id"
                  class="d-flex flex-column"
                >
                  <div class="box-grid col-2 lh-32">
                    <div>{{ role.role_name }}</div>

                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        {{ $t("Workload") }}(%):

                        <field-input-combo
                          class="pl-8"
                          :object="role"
                          :value="role.squads_workload"
                          :placeholder="$t('insert_workload')"
                          @submit="update_workload"
                        />
                      </div>

                      <div
                        class="d-flex justify-content-center justify-self-end align-items-center align-self-center border-1 border-radius-12 color-border-light hover-border-highlight base-neutral w-18px h-18px c-pointer"
                        @click="remove(role.roles_id)"
                      >
                        -
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-column">
                    <div v-for="item in role.factors"
                      :key="item.id"
                      class="box-grid col-2 lh-32"
                    >
                      <div>{{ item.title }}</div>

                      <div class="d-flex">
                        <field-input-combo
                          :object="get_full_factor_data(role, item)"
                          :value="item.value"
                          :placeholder="$t('insert_value')"
                          @submit="update_factor"
                        />

                        <div v-if="item.factors_values_id"
                          class="d-flex justify-content-center justify-self-end align-items-center align-self-center border-1 border-radius-12 color-border-light hover-border-highlight base-neutral w-18px h-18px c-pointer"
                          @click="remove_factor(item.factors_values_id)"
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <div
                  class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
                  @click="show_popups_create_role"
                >
                  {{ $t("add") }}
                </div>
              </div>

              <div class="d-flex justify-content-center my-8">
                <calendar
                  locale="ru"
                  :masks="{ weekdays: 'WWW' }"
                  :attributes="dates.values"
                  disable-page-swipe
                  trim-weeks
                  show-weeknumbers="right"
                  :first-day-of-week="2"
                  :min-date="new Date()"
                  :columns="3"
                  @dayclick="dropdown_statuses"
                ></calendar>

                <div
                  id="dropdown"
                  class="d-none dropdown p-absolute w-200px p-5 base-neutral z-20 box-shadow-bottom d-flex flex-column"
                  @mouseleave="dropdown_statuses_leave"
                >
                  <div v-for="item in source.statuses"
                    :key="item.id"
                    class="my-5 hover-font-highlight c-pointer"
                    @click.stop="update_holidays(item.id)"
                  >
                    {{ item.name }}
                  </div>

                  <hr>

                  <div
                    class="my-5 hover-font-highlight c-pointer"
                    @click.stop="update_holidays(0)"
                  >
                    {{ $t("clear") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </partial-content>

    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_user") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-autocomplete
          v-model="fields.keyword"
          class="mb-15"
          :items="source_search.users"
          :target="fields.user_id"
          :placeholder="$t('search_user')"
          @handle_search="handle_search_user"
          @handle_choose="handle_choose_user"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create_user">
          {{ $t('ok') }}
        </div>
      </template>
    </popup-base>

    <popup-base v-if="popups.create_role">
      <template #header>
        <div>{{ $t("add_role") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create_role"
        />
      </template>

      <template #content>
        <field-select
          v-model="fields_role.roles_id"
          class="mb-8"
          :items="source.roles"
          :selected="false"
          :placeholder="$t('insert_role')"
          :message="$t('role_is_empty')"
          :failure="errors_role.roles_id"
        />

        <field-select
          v-model="fields_role.projects_id"
          class="mb-8"
          :items="source.projects"
          :selected="false"
          :placeholder="$t('insert_project')"
          :message="$t('project_is_empty')"
          :failure="errors_role.projects_id"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create_role">
          {{ $t('ok') }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template> -->

<!-- <script>
  import {
    reactive,
    onMounted,
  } from "vue";

  import moment from "moment";

  import { Calendar } from "v-calendar";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import WidgetFilter   from "../../../_visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../_visual/widgets/projects/Projects.vue";

  import PopupBase         from "../../../visual/popups/base/Base.vue";
  import FieldAutocomplete from "../../../_visual/fields/autocomplete/Autocomplete.vue";
  import FieldSelect       from "../../../_visual/fields/select/Select.vue";
  import FieldInputCombo   from "../../../_visual/fields/input/combo/Combo.vue";
  import WidgetCard        from "../../../visual/widgets/cards/Default.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";
  import SearchEntity  from "../entities/Search.js";
  import SearchService from "../services/Search.js";

  export default {
    components: {
      Calendar,
      LayoutWeb,
      PartialFilter,
      PartialContent,
      WidgetProjects,
      WidgetFilter,
      PopupBase,
      FieldAutocomplete,
      FieldSelect,
      FieldInputCombo,
      WidgetCard
    },

    setup() {
      const source = reactive(new RosterEntity);
      const filter = reactive({ spaces: [], active: {}, search: "" });

      const source_search = reactive(new SearchEntity);

      const popups  = reactive({ create: false, create_role: false, status_day: false });

      const fields = reactive({ keyword: "", user_id: 0 });

      const fields_role = reactive({ roles_id: 0    , projects_id: 0     });
      const errors_role = reactive({ roles_id: false, projects_id: false });

      const dates = reactive({ values: [] });

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        refresh();
        reload();

        Mediator.define("people:reload", async () => {
          Object.assign(source, await RosterService.load());
          refresh();
          reload();
        });
      });

      let refresh = () => {
        filter.spaces = source.spaces.filter(item => item.hidden != 1);
        filter.active = filter.spaces.find(item => item.hidden != 1 && item.active);
      };

      let show_popups_create = () => popups.create = true;
      let hide_popups_create = () => {
        popups.create  = false;
        fields.keyword = "";
        fields.user_id = 0;
      };

      let show_popups_create_role = () => popups.create_role = true;
      let hide_popups_create_role = () => {
        popups.create_role      = false;
        fields_role.roles_id    = 0;
        fields_role.projects_id = 0;
      };

      let attach_errors = () => {
        errors_role.roles_id    = !fields_role.roles_id;
        errors_role.projects_id = !fields_role.projects_id;
      };
      let remove_errors = () => {
        errors_role.roles_id    = false;
        errors_role.projects_id = false;
      };

      let reload = () => {
        dates.values = [];

        source.weekends.map(weekend => {
          dates.values.push({
            highlight: {
              color: "blue",
              fillMode: "light",
            },
            dates: {
              repeat: {
                every: "weeks",
                weekdays: weekend
              }
            }
          });
        });

        source.holidays.map(holiday => {
          dates.values.push({
            highlight: {
              color: holiday.color
            },
            dates: new Date(holiday.date),
          });
        });
      };

      let dropdown_statuses = (day, event) => {
        let dropdown = document.querySelector("#dropdown");
        dropdown.classList.remove("d-none");
        dropdown.style.left = event.x - 10 + "px";
        dropdown.style.top = event.y - 10 + "px";

        popups.status_day = day;
      };

      let dropdown_statuses_leave = () => {
        let dropdown = document.querySelector("#dropdown");
        dropdown.classList.add("d-none");
        dropdown.style.left = 0 + "px";
        dropdown.style.top = 0 + "px";

        popups.status_day = false;
      };

      let get_full_factor_data = (role, item) => {
        item.squads_id = role.squads_id;
        return item;
      };

      return {
        source,
        filter,
        source_search,
        popups,
        fields,
        fields_role,
        errors_role,
        dates,

        show_popups_create,
        hide_popups_create,
        show_popups_create_role,
        hide_popups_create_role,

        dropdown_statuses,
        dropdown_statuses_leave,

        get_full_factor_data,

        search: (value) => filter.search = value,

        handle_search_user: async (value) => {
          fields.keyword = value;
          Object.assign(source_search, await SearchService.search_user(value));
        },

        handle_choose_user: (item) => {
          fields.user_id = item.id;
          fields.keyword = item.name;
        },

        create_user: async () => {
          Object.assign(source, await RosterService.create({ spaces_id: filter.active.id, users_id: fields.user_id, roles_id: -1 }));
          hide_popups_create();
          reload();
        },

        create_role: async () => {
          if (!!fields_role.roles_id && !!fields_role.projects_id) {
            remove_errors();

            Object.assign(source, await RosterService.create_squads({
              spaces_id  : filter.active.id,
              users_id   : filter.active.user_pick,
              roles_id   : fields_role.roles_id,
              projects_id: fields_role.projects_id
            }));

            hide_popups_create_role();
          }
          else {
            attach_errors();
          }

          reload();
        },

        remove: async (positions_id) => {
          Object.assign(source, await RosterService.delete(positions_id))
          reload();
        },

        remove_factor: async (factors_values_id) => {
          Object.assign(source, await RosterService.delete_factor(factors_values_id))
          reload();
        },

        update_holidays: async (id) => {
          Object.assign(source, await RosterService.update_holidays({ spaces_id: filter.active.id, holiday: moment(popups.status_day.date).format("YYYY-MM-DD"), users_id: filter.active.user_pick, status: id }));
          dropdown_statuses_leave();
          reload();
        },

        update_workload: async (value, role) => {
          Object.assign(source, await RosterService.update_workload(role.squads_id, value));
        },

        update_factor: async (value, factor) => {
          Object.assign(source, await RosterService.update_factor(factor.squads_id, factor.factors_id, factor.factors_values_id, value));
        },

        // confirm_filter_user: (user) => {
        //   let name = user.name + " " +  user.lastname;

        //   return filter.search === "" ||
        //     ( filter.search !== "" && name.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1 )
        //   ;
        // },

        pick_user: async (id) => { Object.assign(source, await RosterService.states(filter.active.id, "user_pick", id)); refresh(); reload(); },

        space_active: async (space) => { Object.assign(source, await RosterService.states(space.id, "space_active", space.id)); refresh(); reload(); },
        space_show  : async (space) => { Object.assign(source, await RosterService.states(space.id, "space_show", space.status)); refresh(); reload(); },
        priority    : async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.spaces.map(project => { return { id: project.id }; })));
            refresh();
          }
        }
      };
    }
  };
</script> -->