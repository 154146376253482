<template>
  <section class="header d-flex justify-content-between w-100-30px p-absolute t-0 z-101 p-15">
    <div class="logo-long" />

    <widget-navbar
      :tabs="tabs"
      @activate="activate"
    />
  </section>
</template>

<script>
  import { reactive } from "vue";

  import WidgetNavbar from "../../../../_visual/widgets/navbar/Navbar.vue";

  export default {
    components: {
      WidgetNavbar
    },
    
    setup() {
      const tabs = reactive([
        { id: 1, name: "services"  , active: false, href: "overview"   },
        { id: 2, name: "consulting", active: false, href: "consulting" },
        { id: 3, name: "seminars"  , active: false, href: "seminars"   },
        { id: 4, name: "reviews"   , active: false, href: "reviews"    },
        { id: 5, name: "about"     , active: false, href: "about"      },
        { id: 6, name: "contacts"  , active: false, href: "contacts"   },
        { id: 7, name: "Login"     , active: false, url : "/planner"   }
      ]);

      return { tabs,
        activate: (tab) => {
          if (tab.url) {
            window.location.href = tab.url;
          }
          else {
            document.querySelector("#" + tab.href).scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
      };
    }
  };
</script>

<style lang="scss">
  .landing {
    section.header {
      background: #FFF;
    }
  }
</style>