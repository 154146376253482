<script setup>
  import { ref, reactive, onMounted, defineProps } from "vue";

  import PopupBase           from "../../../../visual/popups/base/Base.vue";
  import FieldInput          from "../../../../_visual/fields/input/Input.vue";
  import FieldInputCombo     from "../../../../_visual/fields/input/combo/Combo.vue";
  import WidgetCardChecklist from "../../../../_visual/widgets/cards/checklist/Checklist.vue";

  import DetailEntity  from "../../entities/Detail.js";
  import DetailService from "../../services/Detail.js";

  const props = defineProps({
    spaces_id: { type: String, default: () => "" },
    role_id  : { type: String, default: () => "" }
  });

  const source = reactive(new DetailEntity);
  const popups = reactive({ create: false });

  const fields = reactive({ name: ""   , rate: 1     });
  const errors = reactive({ name: false, rate: false });

  const mounted = ref(false);
  onMounted(async () => {
    Object.assign(source, await DetailService.load(props.spaces_id, props.role_id));
    mounted.value = true;
  });

  const show_popups_create = () => popups.create = true;
  const hide_popups_create = () => {
    popups.create = false;
    fields.name   = "";
    fields.rate   = 1;
  };

  const attach_errors = () => {
    errors.name = fields.name == "" ? true : false;
    errors.rate = fields.rate == "" ? true : false;
  };
  const remove_errors = () => {
    errors.name = false;
    errors.rate = false;
  };

  async function change(unit) {
    Object.assign(
      source,
      await DetailService.change(props.role_id, {
        spaces_id : props.spaces_id,
        roles_id  : props.role_id,
        modules_id: unit.modules_id,
      })
    );
  };

  async function create_qualification() {
    if (fields.name != "" && fields.rate != "") {
      remove_errors();

      Object.assign(
        source,
        await DetailService.create_qualification(props.role_id, fields.name, fields.rate)
      );

      hide_popups_create();
    } else {
      attach_errors();
    }
  };

  async function update_qualification(value, qualification) {
    Object.assign(source, await DetailService.update_qualification(qualification.id, "rate", value));
  };

  async function remove_qualification(id) {
    Object.assign(source, await DetailService.delete_qualification(id));
  };
</script>

<template>
  <div v-if="source.access">
    <div class="font-section font-weight-500 mb-16">
      {{ source.role.name }}
    </div>

    <div v-for="qualification in source.qualifications"
      :key="qualification.id"
      class="d-flex lh-32"
    >
      <div class="mr-10">
        {{ qualification.name }}:
      </div>

      <field-input-combo
        class="mb-20"
        :object="qualification"
        :value="qualification.rate"
        :placeholder="$t('insert_rate')"
        @submit="update_qualification"
      />

      <div
        class="d-flex justify-content-center justify-self-end align-items-center align-self-center border-1 border-radius-12 color-border-light hover-border-highlight base-neutral w-18px h-18px c-pointer"
        @click="remove_qualification(qualification.id)"
      >
        -
      </div>
    </div>

    <div class="d-flex justify-content-end mb-16">
      <div
        class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        @click="show_popups_create"
      >
        {{ $t("add_qualification") }}
      </div>
    </div>


    <div class="box-grid col-5">
      <widget-card-checklist
        v-for="unit in source.units"
        :key="unit.id"
        :title="unit.name"
        :items="[unit]"
        @change="change"
      />
    </div>

    <!-- Popup: Create qualification -->
    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_qualification") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          class="mb-10"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />

        <field-input
          v-model="fields.rate"
          :placeholder="$t('insert_rate')"
          :message="$t('rate_is_empty')"
          :failure="errors.rate"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create_qualification">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>

  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>