<script setup>
  import { onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import { Hotkeys, Action } from "../../../../packages/hotkeys/Hotkeys";

  import ChartMindmap from "../../../../system/chart/Chart.vue";
  import WidgetCard   from "../../../../visual/widgets/cards/Default.vue";

  const REGION_TREE  = "tree";
  const REGION_LIST  = "list";
  const REGION_CHART = "chart";

  onMounted(async () => {
    Hotkeys.change(REGION_CHART, REGION_LIST);
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ESC  , (nodes) => nodes.forEach((node) => jump_prev(node))),
      new Action(Hotkeys.KEYCODE_ENTER, (nodes) => nodes.forEach((node) => jump_next(node))),
      new Action(Hotkeys.KEYCODE_D    , (nodes) => nodes.forEach((node) => jump_next(node, "desc")), { ctrl: true, shift: true, stop: true }),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => fold(node, "hide")), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => fold(node, "show")), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => move(node, "left") )),
      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => move(node, "up")   )),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => move(node, "right"))),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => move(node, "down") )),

      new Action(Hotkeys.KEYCODE_INSERT, (nodes) => nodes.forEach((node) => source.chart_blank_child(node.dataset.id))),
      new Action(Hotkeys.KEYCODE_ENTER , (nodes) => nodes.forEach((node) => source.chart_blank_sibling(node.dataset.id)), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_X, (nodes) => nodes.forEach((node) => source.node_clone(node.dataset.id, "cut" )), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_C, (nodes) => nodes.forEach((node) => source.node_clone(node.dataset.id, "copy")), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_V, (nodes) => nodes.forEach((node) => source.node_paste(node.dataset.id        )), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_O  , (nodes) => nodes.forEach((node) => state.switch_process(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_M  , (nodes) => nodes.forEach((node) => state.switch_context(+node.dataset.id, REGION_CHART)), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_F2 , (nodes) => nodes.forEach((node) => state.switch_heading(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_process(-1)),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_context(-1)),
      new Action(Hotkeys.KEYCODE_ESC, () => state.switch_heading(-1)),

      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_dead_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_done_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_step_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_I, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_work_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_D, () => state.switch_dead_show(-1, REGION_CHART, get_nodes_ids()), { alt: true, stop: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_done_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_step_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_T, () => state.switch_time_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_I, () => state.switch_work_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => state.switch_dead_edit(+node.dataset.id, REGION_CHART)), { shift: true }),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_edit(+node.dataset.id, REGION_CHART)), { shift: true }),

      new Action(Hotkeys.KEYCODE_B, () => source.tree_show({ id: source.get_active.id, status: !source.tree.show })),

    ], REGION_CHART);
    Hotkeys.focused();
  });

  onUnmounted(() => Hotkeys.reinit(REGION_CHART));

  function get_nodes_ids() {
    return source.chart.nodes.map(node => node.entity.id);
  }

  function pick(node) {
    state.node_activate(node)
    Hotkeys.focused(undefined, node.id);
  }

  function jump_prev(node) {
    if (!state.detail.id) {
      if (Hotkeys.change(REGION_TREE)) {
        Hotkeys.toggle(node);
        Hotkeys.focused("tree-item", source.tree.pick);
      }
    }

    state.node_deactivate();
  };

  function jump_next(node, action = "") {
    let target = source.chart.nodes.find(target =>
      target.entity.id == node.dataset.id)
    ;

    target && state.node_activate(target.entity, action);
  };

  function move(node, action) {
    let offset = { left: -1, right: 1, up: 0, down: 0 };
    let target = node;

    while (target) {
      target = ["right", "down"].includes(action) ?
        target.nextSibling :
        target.previousSibling
      ;

      if (target && target.dataset && +target.dataset.level == (+node.dataset.level + offset[action])) {
        Hotkeys.toggle(node);
        Hotkeys.toggle(target);

        target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});

        break;
      }
    }
  }

  function fold(node, action) {
    let id = source.chart_fold(node, action);

    if (id)
      source.node_folded(id);
  };

  const menu = (node, stages) => {
    let items = [
      { title: "Details", separator: false },
      { title: "Create" , separator: true, items: [ { title: "Child"  , separator: false }, { title: "Sibling", separator: false } ] },
      { title: "Cut"    , separator: false },
      { title: "Copy"   , separator: false },
      { title: "Paste"  , separator: true  },
      { title: "Access" , separator: true, items: [ { title: "Limited", separator: false }, { title: "Shared" , separator: false } ] }
    ];

    if (stages[node.id] && stages[node.id][0] && stages[node.id][0].state_id) {
      if (stages[node.id].find(stage => stage.state_status == "current")) {
        items.push({ title: "Finish" , separator: false });
      }
    }
    else {
      items.push({ title: "Remove" , separator: false  });
    }

    return items;
  };

  const part = (node) => { return {
    fold   : false,
    content: false,
    weight : false,
    actions: { menu: true, launch: !!(!node.branch && (!node.state.weight || node.state.weight == 'IDEA')), create: false, finish: false },
    diagram: { done: true, work: true, time: true, dead: true, step: true }
  }; };
</script>

<template>
  <chart-mindmap
    :data-region="REGION_CHART"
    class="overflow-hidden z-10"

    :nodes="source.chart.nodes"
    :links="source.chart.links"
    :roots="source.chart.roots"

    @blank_child="(target) => source.node_child(target)"
    @blank_sibling="(target) => source.node_sibling(target)"
    @blank_cancel="() => source.chart_blank_cancel()"

    @node_move="(data) => source.node_move_location(data)"
    @node_folded="(id) => source.node_folded(id)"

    @crumbs_handler="(id) => source.tree_pick(id)"
  >
    <template #node="element">
      <widget-card
        class="chart-item h-100"

        :item="element.node.entity"
        :menu="menu(element.node.entity, source.chart.stage)"
        :part="part(element.node.entity)"
        :chains="source.chains"
        :stages="source.chart.stage[element.node.entity.id]"

        :selected="state.select == element.node.entity.id"
        :region="REGION_CHART"

        :context="state.get_state(element.node.entity.id, REGION_CHART, 'context')"
        :process="state.get_state(element.node.entity.id, REGION_CHART, 'process')"
        :heading="state.get_state(element.node.entity.id, REGION_CHART, 'heading')"
        :dead_show="state.get_state(element.node.entity.id, REGION_CHART, 'dead_show')"
        :done_show="state.get_state(element.node.entity.id, REGION_CHART, 'done_show')"
        :step_show="state.get_state(element.node.entity.id, REGION_CHART, 'step_show')"
        :time_show="state.get_state(element.node.entity.id, REGION_CHART, 'time_show')"
        :work_show="state.get_state(element.node.entity.id, REGION_CHART, 'work_show')"
        :dead_edit="state.get_state(element.node.entity.id, REGION_CHART, 'dead_edit')"
        :time_edit="state.get_state(element.node.entity.id, REGION_CHART, 'time_edit')"

        @switch_context="state.switch_context"
        @switch_process="state.switch_process"
        @switch_heading="state.switch_heading"
        @switch_dead_show="state.switch_dead_show"
        @switch_done_show="state.switch_done_show"
        @switch_step_show="state.switch_step_show"
        @switch_time_show="state.switch_time_show"
        @switch_work_show="state.switch_work_show"
        @switch_dead_edit="state.switch_dead_edit"
        @switch_time_edit="state.switch_time_edit"

        @pick="(node) => pick(node)"
        @create="(id, type) => source[`chart_blank_${type}`](id)"
        @clone="(id, action) => source.node_clone(id, action)"
        @paste="(id) => source.node_paste(id)"
        @finish="(id) => source.node_finish(id)"
        @launch="(data) => source.node_launch(data)"
        @access="(id, type) => source.node_access(id, type)"
        @update="(option, id, value) => source.node_update(option, id, value)"
        @remove="(id) => source.node_remove(id)"
      />
    </template>
  </chart-mindmap>
</template>