import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM  from "../system.js";
import SERVICE from "../services/Roster.js";

export const useSourceStore = defineStore("projects_source", () => {
  const access   = ref(false);
  const errors   = ref({});
  const status   = ref({});
  const projects = ref([]);

  const layout = ref([
    { "x": 0, "y": 0 , "w": 2, "h": 4, "i": "1"  },
    { "x": 2, "y": 0 , "w": 4, "h": 4, "i": "2"  },
    { "x": 6, "y": 0 , "w": 2, "h": 2, "i": "3"  },
    { "x": 6, "y": 2 , "w": 2, "h": 2, "i": "4"  },

    { "x": 0, "y": 4 , "w": 2, "h": 2, "i": "5"  },
    { "x": 2, "y": 4 , "w": 2, "h": 2, "i": "6"  },
    { "x": 4, "y": 4 , "w": 2, "h": 2, "i": "7"  },
    { "x": 6, "y": 4 , "w": 2, "h": 2, "i": "8"  },

    { "x": 0, "y": 6 , "w": 2, "h": 2, "i": "9"  },
    { "x": 2, "y": 6 , "w": 2, "h": 2, "i": "10" },
    { "x": 4, "y": 6 , "w": 2, "h": 2, "i": "11" },
    { "x": 6, "y": 6 , "w": 2, "h": 2, "i": "12" }
  ]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  const get_active = computed(() => {
    return projects.value.find(project => project.active && project.hidden != 1);
  });

  const get_visible = computed(() => {
    return projects.value.filter(project => project.hidden != 1);
  });

  function reload(response) {
    if (response.data) {
      access.value   = response.access;
      errors.value   = response.errors;
      status.value   = response.status;
      projects.value = response.data.projects;
    }
  };

  return {
    access,
    errors,
    status,
    projects,
    layout,

    is_access,

    get_active,
    get_visible,

    load: async () => reload(await SERVICE.load()),

    project_activate: async (id) => reload(await SERVICE.states(id, "project_active", id)),

    project_visible : async (id) => reload(await SERVICE.states(id, "project_show"  , id)),

    project_priority: async (event) => event.moved &&
      reload(await SERVICE.priority(get_visible.value.map(project => { return { id: project.id }; })))
  };
})