<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import WidgetUsersWorkers from "../../../../_visual/widgets/users/workers/Workers.vue";
</script>

<template>
  <div class="d-flex base-neutral border-radius-16 mx-27 mb-16 px-27 py-18">
    <widget-users-workers v-if="source.workers.length"
      :workers="source.workers"

      @submit="(route, value) => source.update_workers(source.node.id, route, value)"
    />
  </div>
</template>