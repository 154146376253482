
<script setup>
  import { ref, reactive, watch, nextTick, onMounted, defineProps, defineEmits } from "vue";

  import Layout from "./Layout.js";

  import ChartsCanvas   from "./markup/Canvas.vue";
  import ChartsOverview from "./markup/Overview.vue";

  const NODE_BORDER = 2;

  const CANVAS_WIDTH  = 2000;
  const CANVAS_HEIGHT = 1000;
  const CANVAS_INDENT = 0.1;

  const props = defineProps({
    nodes: { type: Array  , default: () => []    },
    links: { type: Array  , default: () => []    },
    roots: { type: Array  , default: () => []    },
    pool : { type: Array  , default: () => []    },
    lock : { type: Boolean, default: () => false }
  });

  const emit = defineEmits([
    "blank_root",
    "blank_child",
    "blank_sibling",
    "blank_cancel",
    "node_move",
    "node_folded",
    "crumbs_handler"
  ]);

  const viewport_node  = ref();
  const viewport_size  = reactive({ left: 0, top: 0, width: 0, height: 0 });
  const viewport_scale = ref(1);

  const canvas_node = ref();
  const canvas_size = reactive({ width: CANVAS_WIDTH, height: CANVAS_HEIGHT });

  const overview_node = ref();

  const layout = reactive({ value: {} });

  const teleport_overview = ref("body");

  onMounted(() => {
    teleport_overview.value = "#chart-overview";

    update_viewport_size();
    update_layout(props.nodes, props.links);
  });

  watch([ () => props.nodes, () => props.links ], async ([nodes, links]) => {
    await update_layout(nodes, links);
  });

  function scaling(event) {
    let delta = event.deltaY > 0 ? -0.05 : 0.05;

    if (
      canvas_size.width > viewport_size.width * (1 / (viewport_scale.value + delta)) &&
      canvas_size.height > viewport_size.height * (1 / (viewport_scale.value + delta))
    ) {
      viewport_scale.value += delta;

      canvas_node.value.canvas.style.MozTransform   = viewport_scale.value;
      canvas_node.value.canvas.style.OTransform     = viewport_scale.value;
      canvas_node.value.canvas.style.zoom           = viewport_scale.value;

      overview_node.value.window.style.MozTransform = 1 / viewport_scale.value;
      overview_node.value.window.style.OTransform   = 1 / viewport_scale.value;
      overview_node.value.window.style.zoom         = 1 / viewport_scale.value;

      update_viewport_size();
    }
  }

  function update_viewport_size() {
    viewport_size.width  = viewport_node.value.getBoundingClientRect().width;
    viewport_size.height = viewport_node.value.getBoundingClientRect().height;
  }

  function update_overview(left, top) {
    viewport_size.top  = top;
    viewport_size.left = left;
    update_viewport_size();
  }

  async function update_layout(nodes, links) {
    /**
     * @IMPORTANT!
     *
     * To recalculate layout we need the latest sizes of nodes in the DOM.
     * That's why we need to have actually rendered DOM.
     * This could be achieved by waiting next chain: await nextTick();
     *
     * Example.
     *
     * When we show drop targets for the node the size is increased.
     * Then we drop the moving node outside targets. Targets are hide, but
     * node size for these targets is still big (added heights of targets).
     *
     * If we just recalculate layout then it will be broken as node size is
     * still big. So, we need to await until DOM is re-rendered (await nextTick())
     * and only then make a recalculation.
     */
    await nextTick();

    layout.value = {};

    nodes.forEach(element => {
      let positions = canvas_node.value.elements[element.entity.id].getBoundingClientRect();
      positions.width  = positions.width / viewport_scale.value - NODE_BORDER;
      positions.height = positions.height / viewport_scale.value - NODE_BORDER;

      layout.value[element.entity.id] = JSON.parse(JSON.stringify(positions));
    });

    layout.value = Layout.detect(nodes, links, Array.from(props.roots), layout.value);

    canvas_size.width  = Math.max(layout.value.scene.width, CANVAS_WIDTH) + (Math.max(layout.value.scene.width, CANVAS_WIDTH) * CANVAS_INDENT);
    canvas_size.height = Math.max(layout.value.scene.height, CANVAS_HEIGHT) + (Math.max(layout.value.scene.height, CANVAS_HEIGHT) * CANVAS_INDENT);
  }
</script>

<template>
  <div ref="viewport_node"
    class="p-relative viewport_node flex-grow"
    @wheel.ctrl.stop.prevent="scaling"
  >
    <charts-canvas ref="canvas_node"
      :nodes="props.nodes"
      :links="props.links"

      :pool="props.pool"
      :lock="props.lock"

      :layout="layout.value"
      :region="canvas_size"
      :viewport="viewport_size"
      :scale="viewport_scale"

      @chart-root="data => emit('blank_root', data)"
      @chart-child="data => emit('blank_child', data)"
      @chart-sibling="data => emit('blank_sibling', data)"
      @chart-cancel-placeholder="emit('blank_cancel')"
      @chart-move="data => emit('node_move', data)"
      @chart-fold="id => emit('node_folded', id)"
      @chart-crumb="id => emit('crumbs_handler', id)"
      @chart-update="update_layout"
      @location="update_overview"
    >
      <template #node="element">
        <slot name="node" :node="element.node" />
      </template>

      <template #blank="element">
        <slot name="blank" :pool="element.pool" />
      </template>
    </charts-canvas>

    <teleport :to="teleport_overview">
      <charts-overview ref="overview_node"
        class="base-light"

        :nodes="props.nodes"
        :links="props.links"
        :layout="layout.value"
        :region="canvas_size"
        :viewport="viewport_size"
        :scale="viewport_scale"

        @location="update_overview"
        @wheel.ctrl.stop.prevent="scaling"
      />
    </teleport>
  </div>
</template>