import SERVER from "../../../system/server/server";

export default class Roster {
  static async load(keyword = "") {
    return await SERVER.get("schema" + "?keyword=" + keyword);
  }

  static async states(project_id, option, value, keyword = "") {
    return await SERVER.post("schema/states" + "?keyword=" + keyword, { id: project_id, option: option, value: value });
  }

  static async priority(projects, keyword = "") {
    return await SERVER.post("schema/priority" + "?keyword=" + keyword, { list: projects });
  }

  static async order(target, source, offset, keyword = "") {
    return await SERVER.post("schema/order" + "?keyword=" + keyword, { target: target, source: source, offset: offset });
  }

  static async create_bundle(projects_id, target, keyword = "") {
    return await SERVER.post("schema/bundle?keyword=" + keyword, { projects_id: projects_id, root_id: target.id, title: target.title, offset: target.offset });
  }

  static async create_child(projects_id, target, keyword = "") {
    return await SERVER.post("schema/child" + "?keyword=" + keyword, { projects_id: projects_id, root_id: target.id, title: target.title, offset: target.offset });
  }

  static async create_sibling(projects_id, target, keyword = "") {
    return await SERVER.post("schema/sibling" + "?keyword=" + keyword, { projects_id: projects_id, root_id: target.id, title: target.title, offset: target.offset });
  }

  static async finish(node_id, keyword = "") {
    return await SERVER.get("schema/finish/" + node_id + "?keyword=" + keyword);
  }

  static async enable(nodes_id, chains_id, keyword = "") {
    return await SERVER.put("schema/enable/" + nodes_id + "?keyword=" + keyword, { chains_id: chains_id });
  }

  static async remove(node_id, keyword = "") {
    return await SERVER.delete("schema/" + node_id + "?keyword=" + keyword);
  }

  static async insert(source_id, target_id, project_id, keyword = "") {
    return await SERVER.post("schema/insert" + "?keyword=" + keyword, { source_id : source_id, target_id : target_id, project_id: project_id });
  }

  static async move(target, source, keyword = "") {
    return await SERVER.post("schema/move" + "?keyword=" + keyword, { target_id: target, source_id: source });
  }

  static async move_location(source, target, location, keyword = "") {
    return await SERVER.put("schema/move" + "?keyword=" + keyword, { source_id: source, target_id: target, location: location });
  }

  static async access(id, type, keyword = "") {
    return await SERVER.put("schema/access/" + id + "?keyword=" + keyword, { type: type });
  }

  static async option(option, keyword = "") {
    return await SERVER.put("schema/option" + "?keyword=" + keyword, option);
  }

  static async update(nodes_id, option, value, keyword = "") {
    return await SERVER.put("schema/update/" + nodes_id + "?keyword=" + keyword, { option: option, value: value });
  }
}