<script setup>
  import { defineProps, defineEmits } from "vue";

  import WidgetPeriod from "../../period/Default.vue";

  const props = defineProps({
    edit  : { type: Boolean, default: () => false    },
    start : { type: String , default: () => ""       },
    finish: { type: String , default: () => ""       },
    format: { type: String , default: () => "medium" }
  });

  const emit = defineEmits([
    "handler", "switch"
  ]);

  function formatting(date) {
    return new Date(date).toLocaleDateString("ru-RU", { dateStyle: props.format });
  }
</script>

<template>
  <div class="d-flex">
    <div class="icon mr-4 icon-period" />

    <div v-if="!props.edit" class="d-flex">
      <div class="lh-18 font-soft">
        {{ formatting(start) }}
      </div>

      <div class="lh-18 mx-4 font-soft">
      -
      </div>

      <div class="lh-18 font-soft">
        {{ formatting(finish) }}
      </div>
    </div>

    <widget-period v-else
      :autofocus="true"
      :start="props.start"
      :finish="props.finish"

      @submit="(option, value) => emit('handler', option, value)"
      @close="emit('switch')"
    />
  </div>
</template>