<script setup>
  import { defineProps } from "vue";

  const PERIODS = 16;

  const props = defineProps({
    entity : { type: String , default: () => ""    },
      percent: { type: Number , default: () => 0     },
      defined: { type: Boolean, default: () => false }
  });

  function fill() {
    let period = Math.floor(PERIODS * props.percent / 100);

    return [
      period > PERIODS ? "progress-percent-" + (PERIODS + 1) : "progress-percent-" + period,
      period >= PERIODS + 1 ? 'base-denial' : ( period == PERIODS ? 'base-selection' : 'base-highlight')
    ];
  };

  function icon() {
    if (props.defined) {
      if      (props.percent >= 0 && props.percent < 100) { return `icon-progress-${props.entity}-progress`; }
      else if (props.percent == 100                     ) { return `icon-progress-${props.entity}-full`    ; }
      else if (props.percent > 100                      ) { return `icon-progress-${props.entity}-denial`  ; }
      else if (props.percent < 0                        ) { return `icon-progress-${props.entity}-denial`  ; }
    }
    else {
      if      (props.percent == 0                      ) { return `icon-progress-${props.entity}-empty`   ; }
      else if (props.percent > 0 && props.percent < 100) { return `icon-progress-${props.entity}-progress`; }
      else if (props.percent == 100                    ) { return `icon-progress-${props.entity}-full`    ; }
      else if (props.percent > 100                     ) { return `icon-progress-${props.entity}-denial`  ; }
      else if (props.percent < 0                       ) { return `icon-progress-${props.entity}-denial`  ; }
    }
  };
</script>

<template>
  <div class="d-flex w-100 c-pointer">
    <div class="circle p-relative w-24px h-24px base-soft border-radius-50-percent mt--1 ml--1">
      <div class="outer p-absolute w-24px h-24px base-highlight border-radius-50-percent" :class="fill()" />
      <div class="inner icon medium p-absolute base-neutral border-radius-50-percent transform-scale-0-75" :class="icon()" />
    </div>

    <slot name="content" />
  </div>
</template>

<style lang="scss">
  .outer {
    clip-path: polygon(0 0, 0 0, 0 0);

    &.progress-percent-0  { clip-path: polygon(  0 0,    0 0,    0    0                                 ); } // 0%
    &.progress-percent-1  { clip-path: polygon(50% 0,  75% 0,                                    50% 50%); } // 6.25%
    &.progress-percent-2  { clip-path: polygon(50% 0, 100% 0,                                    50% 50%); } // 12.5%
    &.progress-percent-3  { clip-path: polygon(50% 0, 100% 0, 100%  25%,                         50% 50%); } // 18.75%
    &.progress-percent-4  { clip-path: polygon(50% 0, 100% 0, 100%  50%,                         50% 50%); } // 25%
    &.progress-percent-5  { clip-path: polygon(50% 0, 100% 0, 100%  75%,                         50% 50%); } // 31.25%
    &.progress-percent-6  { clip-path: polygon(50% 0, 100% 0, 100% 100%,                         50% 50%); } // 37.5%
    &.progress-percent-7  { clip-path: polygon(50% 0, 100% 0, 100% 100%, 75% 100%,               50% 50%); } // 43.75%
    &.progress-percent-8  { clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%,               50% 50%); } // 50%
    &.progress-percent-9  { clip-path: polygon(50% 0, 100% 0, 100% 100%, 25% 100%,               50% 50%); } // 56.25%
    &.progress-percent-10 { clip-path: polygon(50% 0, 100% 0, 100% 100%,   0 100%,               50% 50%); } // 62.5%
    &.progress-percent-11 { clip-path: polygon(50% 0, 100% 0, 100% 100%,   0 100%, 0 75%,        50% 50%); } // 68.75%
    &.progress-percent-12 { clip-path: polygon(50% 0, 100% 0, 100% 100%,   0 100%, 0 50%,        50% 50%); } // 75%
    &.progress-percent-13 { clip-path: polygon(50% 0, 100% 0, 100% 100%,   0 100%, 0 25%,        50% 50%); } // 81.25%
    &.progress-percent-14 { clip-path: polygon(50% 0, 100% 0, 100% 100%,   0 100%, 0   0,        50% 50%); } // 87.5%
    &.progress-percent-15 { clip-path: polygon(50% 0, 100% 0, 100% 100%,   0 100%, 0   0, 25% 0, 50% 50%); } // 93.75%
    &.progress-percent-16 { clip-path: polygon(  0 0, 100% 0, 100% 100%,   0 100%                       ); } // 100%

    &.progress-percent-17 { // > 100%
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

    }
  }
</style>