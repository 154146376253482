<script setup>
  import { watchEffect, onUpdated, defineProps, defineEmits } from "vue";

  const KEYCODE_ENTER = 13;
  const KEYCODE_ESC   = 27;
  const KEYCODE_UP    = 38;
  const KEYCODE_DOWN  = 40;

  const props = defineProps({
    active : { type: Boolean, default: () => false },
    item   : { type: Object , default: () => {}    },
    chains : { type: Array  , default: () => []    }
  });

  const emit = defineEmits([
    "handler", "switch"
  ]);

  watchEffect(() => {
    let menu = document.querySelector(".launch-dropdown");
    !props.active && menu && menu.blur();
  });

  onUpdated(() => {
    let menu = document.querySelector(".launch-dropdown");
    props.active && menu && menu.focus();
  });

  function activate(current, sibling) {
    if (current) delete current.dataset.processActive;
    if (sibling && sibling.dataset) sibling.dataset.processActive = true;
  }

  function move(current, direction) {
    if (!current) {
      current = document.querySelector(".launch-dropdown-item" + (direction == "next" ? "" : ":last-of-type"));
      activate(null, current)
    }
    else {
      let sibling = direction == "next" ? current.nextSibling : current.previousSibling;
      if (sibling && sibling.dataset) activate(current, sibling)
    }
  };

  function handler(id) {
    emit("handler", id);
    emit("switch")
  };

  function navigation(event) {
    let item = document.querySelector(".launch-dropdown-item[data-process-active]");

    if      (event.keyCode == KEYCODE_DOWN ) { move(item, "next"); }
    else if (event.keyCode == KEYCODE_UP   ) { move(item, "prev"); }
    else if (event.keyCode == KEYCODE_ESC  ) { emit("switch"); }
    else if (event.keyCode == KEYCODE_ENTER) {
      if (item && item.dataset && item.dataset.processId)
        handler(item.dataset.processId);
    }
  };
</script>

<template>
  <div class="actions p-absolute r--7px b--7px z-104">
    <div v-if="props.item.chains_id"
      class="launch box-shadow-bottom z-104"

      @click.exact.stop.prevent="handler(props.item.chains_id)"
    >
      <div class="icon icon-launch" />
    </div>

    <div v-else-if="props.chains.length == 1"
      class="launch box-shadow-bottom z-104"

      @click.exact.stop.prevent="handler(props.chains[0].id)"
    >
      <div class="icon icon-launch" />
    </div>

    <div v-else
      class="launch box-shadow-bottom z-104"

      @click.exact.stop.prevent="emit('switch')"
    >
      <div class="icon icon-launch" />
    </div>

    <div v-if="props.active"
      class="launch-dropdown d-flex flex-column p-absolute border-radius-16 t--10px r-0 w-125px p-5 base-neutral box-shadow-bottom z-104"
      tabindex="0"

      @keydown.stop.prevent
      @keyup.stop.prevent="navigation"
      @mouseleave="emit('switch')"
    >
      <div v-for="chain in props.chains"
        :key="chain.id"
        :data-process-id="chain.id"
        class="launch-dropdown-item my-5 p-5 border-radius-5 hover-base-light c-pointer short-title"

        @click.exact.stop.prevent="handler(chain.id)"
      >
        {{ chain.name }}
      </div>
    </div>
  </div>
</template>