<template>
  <layout-web v-slot="slotProps" fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.projects"
          @activate="project_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <!-- <field-search
            class="max-w-400px"
            :value="filter.search"
            @search="search"
          /> -->

          <widget-filter
            class="ml-8"
            :projects="source.projects"
            @projects_handler="project_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <calendar
          class="full"
          locale="ru"
          :masks="{ weekdays: 'WWW' }"
          :attributes="dates.values"
          disable-page-swipe
          is-expanded
        >
          <template #day-content="{ day, attributes }">
            <div
              class="dropzone d-flex flex-column min-h-inherit h-100 p-5"
              @drop="on_drop($event, day.id)"
              @dragenter.prevent
              @dragover.prevent="dragover($event)"
              @dragleave.prevent="dragleave($event)"
            >
              <div class="d-flex justify-content-between">
                <div
                  class="day-label text-sm text-gray-900 c-pointer"
                  @click="show_popups_list(day, attributes)"
                >
                  {{ day.day }}
                </div>

                <div
                  class="icon icon-add-event icon-hover c-pointer"
                  @click="show_popups_create(day.id)"
                />
              </div>

              <div
                v-for="attr in attributes"
                :key="attr.key"
                class="d-flex"
              >
                <widget-card
                  class="base-neutral mt-8"

                  :item="attr.customData"
                  :menu="['Details']"
                  :part="{fold: false, content: false, weight: false, actions: {menu: true, launch: false, create: false, finish: false}, diagram: {done: false, work: false, time: false, dead: false, step: false}}"

                  draggable="true"
                  @dragstart="start_drag($event, attr.customData)"
                  @close="clear_node"
                  @finish="finish_node"
                  @click="show_popups_detail({ id: attr.customData.id, code: attr.customData.code, header: '#' + attr.customData.code })"
                />
              </div>
            </div>
          </template>
        </calendar>
      </template>
    </partial-content>

    <!-- Popup: Show list -->
    <popup-base v-if="popups.list">
      <template #header>
        <div class="d-flex justify-content-center w-100">
          <div
            class="icon icon-arrow-back icon-hover c-pointer mr-10"
            @click="subtract_popups_list(popups.list.day.id)"
          />

          <div>{{ popups.list.day.id }}</div>

          <div
            class="icon icon-arrow-next icon-hover c-pointer ml-10"
            @click="add_popups_list(popups.list.day.id)"
          />
        </div>

        <div
          class="icon icon-close icon-hover c-pointer"
          @click="hide_popups_list"
        />
      </template>

      <template #content>
        <div class="overflow-y-scroll scrollbar-disable h-500px">
          <div
            v-for="attr in popups.list.nodes"
            :key="attr.key"
            class="d-flex"
          >
            <widget-card
              class="base-neutral m-10"

              :item="attr.customData"
              :menu="['Details']"
              :part="{fold: false, content: false, weight: false, actions: {menu: true, launch: false, create: false, finish: false}, diagram: {done: false, work: false, time: false, dead: false, step: false}}"

              @close="clear_node"
              @finish="finish_node"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <div class="pt-10 border-top-1 color-border-light w-100">
          <div
            class="d-flex c-pointer justify-content-end"
            @click="switch_popups_list(popups.list.day.id)"
          >
            <div class="icon icon-add-event mr-5" />
            {{ $t("add") }}
          </div>
        </div>
      </template>
    </popup-base>

    <!-- Popup: Node create -->
    <popup-base v-if="popups.create" overview="true">
      <template #header>
        <div>{{ $t("add") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popups_create" />
      </template>

      <template #content>
        <div class="h-60">
          <field-input
            v-model="fields.title"
            class="mb-8"
            :placeholder="$t('insert_title')"
            :message="$t('title_is_empty')"
            :failure="errors.title"
          />

          <field-select v-if="fields.title"
            v-model="fields.project"
            class="mb-8"
            :items="source.projects"
            :selected="false"
            :placeholder="$t('insert_project')"
            :message="$t('project_is_empty')"
            :failure="errors.project"
            @change="change_project"
          />

          <field-select v-if="fields.project"
            v-model="fields.chain"
            :items="chains.chains"
            :selected="false"
            :placeholder="$t('insert_chain')"
            :message="$t('chain_is_empty')"
            :failure="errors.chain"
          />

          <div v-if="!fields.project"
            class="border-top-1 color-border-light pt-8"
          >
            <widget-card v-for="node in source.nodes.filter(item => fields.title && item.title.includes(fields.title) || !fields.title)"
              :key="node.id"

              class="base-neutral mb-8"

              :item="node"
              :menu="['Details']"
              :part="{fold: false, content: false, weight: false, actions: {menu: true, launch: false, create: false, finish: false}, diagram: {done: false, work: false, time: false, dead: false, step: false}}"

              @click="update_node(node.id)"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <div v-if="fields.chain"
          class="button primary"
          @click="create_node"
        >
          {{ $t("create") }}
        </div>
      </template>
    </popup-base>

    <!-- Popup: Node details -->
    <popup-sidebar v-if="popups.detail">
      <template #header>
        <div
          class="font-title c-pointer"
          @click="redirect(popups.detail.code)"
        >
          {{ popups.detail.header }}
        </div>

        <div
          class="font-highlight lh-16 c-pointer"
          @click="hide_popups_detail"
        >
          {{ $t("close") }}
        </div>
      </template>

      <template #content>
        <node-plugin
          :node_id="popups.detail.id"
          :code_id="popups.detail.code"
          :user_id="slotProps.user.id"
        />
      </template>

      <template #footer />
    </popup-sidebar>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted,
    defineAsyncComponent
  } from "vue";

  import moment from "moment";

  import { Calendar } from "v-calendar";
  import "v-calendar/style.css";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import PopupSidebar from "../../../visual/popups/sidebar/Sidebar.vue";

  // import FieldSearch from "../../../_visual/fields/search/Search.vue";

  import WidgetFilter   from "../../../_visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../_visual/widgets/projects/Projects.vue";

  import WidgetCard from "../../../visual/widgets/cards/Default.vue";

  import PopupBase   from "../../../visual/popups/base/Base.vue";
  import FieldInput  from "../../../_visual/fields/input/Input.vue";
  import FieldSelect from "../../../_visual/fields/select/Select.vue";

  import ChainEntity   from "../entities/Chain.js";
  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      Calendar,
      LayoutWeb,
      PartialFilter,
      PartialContent,
      PopupSidebar,
      PopupBase,
      FieldInput,
      FieldSelect,
      // FieldSearch,
      WidgetFilter,
      WidgetProjects,
      WidgetCard,
      NodePlugin: defineAsyncComponent(() => import('../../node/plugins/Roster.vue'))
    },

    setup() {
      const source = reactive(new RosterEntity);
      const popups = reactive({ detail: false, create: false, list: false });
      const filter = reactive({ search: "", projects: [], active: {} });
      const dates  = reactive({ values: [] });

      const fields = reactive({ title: "", project: "", chain: "" });
      const errors = reactive({ title: false, project: false, chain: false });
      const chains = reactive(new ChainEntity);

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        reload();
        refresh();

        Mediator.define("calendar:reload", async () => {
          Object.assign(source, await RosterService.load());
          reload();
          refresh();
        });
      });

      let refresh = () => {
        filter.projects = source.projects.filter(item => item.hidden != 1);
        filter.active   = filter.projects.find(item => item.hidden != 1 && item.active);
      };

      let redirect = (id) => window.location.href = "/node/" + id;

      let reload = () => {
        dates.values = [];

        source.launch.map(node => {
          dates.values.push({
            key       : node.deadline + "-" + node.id,
            dates     : node.deadline ? new Date(node.deadline) : new Date(node.launch),
            customData: {
              id              : node.id,
              code            : node.code,
              title           : node.title,
              description     : node.description,
              project         : node.project,
              path            : node.path,
              deadline        : node.deadline,
              replaced        : node.replaced,
              role_name       : node.role_name,
              complexity      : node.complexity,
              status_states_id: node.status_states_id
            }
          });
        });
      };

      let show_popups_detail = (data) => popups.detail = data;
      let hide_popups_detail = ()     => popups.detail = false;

      let show_popups_list = function (target, nodes) { popups.list = { day: target, nodes: nodes }; };
      let hide_popups_list = function () { popups.list = false; };

      let show_popups_create = function (target) { popups.create = target; };
      let hide_popups_create = function () {
        popups.create   = false;
        fields.title   = "";
        fields.project = "";
        fields.chain   = "";
      };

      let switch_popups_list = function (target) {
        popups.create = target;
        popups.list   = false;
      };

      let add_popups_list = function (target) {
        let date = new Date(target);
        date.setDate(date.getDate() + 1);

        popups.list = {
          day  : { id: moment(date).format("YYYY-MM-DD") },
          nodes: dates.values.filter(item => moment(item.dates).format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD"))
        };
      };

      let subtract_popups_list = function (target) {
        let date = new Date(target);
        date.setDate(date.getDate() - 1);

        popups.list = {
          day  : { id: moment(date).format("YYYY-MM-DD") },
          nodes: dates.values.filter(item => moment(item.dates).format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD"))
        };
      };

      return {
        source,
        popups,
        filter,
        dates,
        fields,
        errors,
        chains,

        reload,
        redirect,

        show_popups_detail,
        hide_popups_detail,
        show_popups_list,
        hide_popups_list,
        show_popups_create,
        hide_popups_create,

        switch_popups_list,
        add_popups_list,
        subtract_popups_list,

        project_active: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "project_active", project.id, filter.search));
          refresh();
          reload();
        },

        project_show: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "project_show", project.status, filter.search));
          refresh();
          reload();
        },

        priority: async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.projects.map(project => { return { id: project.id }; }), filter.search));
            refresh();
          }
        },

        // search: async (keyword) => {
        //   filter.search = keyword;
        //   Object.assign(source, await RosterService.search(keyword));
        //   refresh();
        // },

        change_project: async () => {
          fields.chain = "";
          Object.assign(chains, await RosterService.load_chains(fields.project));
        },

        create_node: async () => {
          Object.assign(source, await RosterService.create_node(popups.create, fields));

          for (const [key] of Object.entries(errors)) { errors[key] = false; }

          if (Object.keys(source.errors).length) {
            for (const [key, value] of Object.entries(source.errors)) { errors[key] = value[0]; }
          }
          else {
            hide_popups_create();
            reload();
            Mediator.handle("tracker:reload");
          }
        },

        update_node: async (node_id) => {
          Object.assign(source, await RosterService.update_node(popups.create, node_id));
          hide_popups_create();
          reload();
          Mediator.handle("tracker:reload");
        },

        clear_node: async (node) => {
          Object.assign(source, await RosterService.clear_node(node.id));
          hide_popups_list();
          reload();
          Mediator.handle("tracker:reload");
        },

        finish_node: async (data) => {
          Object.assign(source, await RosterService.finish_node(data));
          hide_popups_list();
          reload();
          Mediator.handle("tracker:reload");
        },

        start_drag: (event, node) => {
          event.dataTransfer.dropEffect    = "move";
          event.dataTransfer.effectAllowed = "move";
          event.dataTransfer.setData('node_id', node.id);
        },

        dragover: (event) => { event.target.closest(".dropzone").style.border = "1px dotted red"; },
        dragleave: (event) => { event.target.closest(".dropzone").style.border = "1px solid transparent"; },

        on_drop: async (event, target) => {
          event.target.closest(".dropzone").style.border = "1px solid transparent";
          Object.assign(source, await RosterService.move({ target: target, node_id: event.dataTransfer.getData("node_id") }));
          reload();
          Mediator.handle("tracker:reload");
        }
      };
    }
  };
</script>
