<script setup>
  import { onBeforeMount, onMounted } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../stores/StateStore.js";
  const state = useStateStore();

  import Mediator from "../../../system/mediator/Mediator";
  import { Hotkeys } from "../../../packages/hotkeys/Hotkeys";

  import LayoutWeb            from "../../../layouts/Web.vue";
  import CommonPartialFilter  from "../../../visual/partials/Filter.vue";
  import CommonPartialContent from "../../../visual/partials/Content.vue";
  import PartialFilters       from "./partials/Filters.vue";
  import PartialPaths         from "./partials/Paths.vue";
  import PartialDepth         from "./partials/Depth.vue";
  import PartialBoard         from "./partials/Board.vue";

  onBeforeMount(() => Hotkeys.reinit());

  onMounted(async () => {
    await source.load();
    Mediator.define("projects:reload", async () => await source.load());
  });
</script>

<template>
  <layout-web fullscreen>
    <common-partial-filter>
      <template #content>
        <partial-filters />
      </template>
    </common-partial-filter>

    <common-partial-content :project="source.get_active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <partial-paths />

          <partial-depth v-if="state.select"/>
          <partial-board v-else />
        </div>
      </template>
    </common-partial-content>
  </layout-web>
</template>

<!-- <template>
  <layout-web fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.spaces"
          @activate="space_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <widget-filter
            class="ml-8"
            :projects="source.spaces"
            @projects_handler="space_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <div class="overflow-y-scroll scrollbar-disable w-300px min-w-300px pr-8 pb-8 pt-10 mt--10">
            <widget-card v-for="project in source.units"
              :key="project.id"

              class="base-neutral mb-8 c-pointer"

              :item="project"
              :menu="['Remove']"
              :part="{fold: false, content: true, weight: false, actions: {menu: true, launch: false, create: false, finish: false}, diagram: {done: false, work: false, time: false, dead: false, step: false}}"

              @click="pick_project(project.id)"
              @remove="remove"
            />

            <div
              class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
              @click="show_popups_create"
            >
              {{ $t("add") }}
            </div>
          </div>

          <div v-if="source.details" class="d-flex flex-column flex-grow base-neutral overflow-hidden">
            <div class="p-8">
              <field-input
                v-model="fields.name"
                class="mb-10"
                :label="$t('name_required')"
                :placeholder="$t('name')"
                :message="$t('name_is_empty')"
                :failure="errors.name"
              />

              <field-input
                v-model="fields.code"
                class="mb-10"
                :label="$t('code')"
                :placeholder="$t('code')"
                :description="$t('max_15')"
              />

              <div class="d-flex">
                <div class="button primary" @click="update">
                  {{ $t("save") }}
                </div>
              </div>

              <div class="d-flex flex-column w-100--28px mt-32">
                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'bac'}"
                  @click="details('bac')"
                >
                  {{ "BAC: " }} {{ source.evm.bac }}
                </div>

                <div v-if="popups.details == 'bac'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Запланированный бюджет" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'pv'}"
                  @click="details('pv')"
                >
                  {{ "PV: " }} {{ source.evm.pv }}
                </div>

                <div v-if="popups.details == 'pv'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Плановый объём" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'ac'}"
                  @click="details('ac')"
                >
                  {{ "AC: " }} {{ source.evm.ac }}
                </div>

                <div v-if="popups.details == 'ac'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Фактическая стоимость" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'ev'}"
                  @click="details('ev')"
                >
                  {{ "EV: " }} {{ source.evm.ev }}
                </div>

                <div v-if="popups.details == 'ev'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Освоенный объём" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{
                    'base-light': popups.details == 'cv',
                    'base-denial-soft': source.evm.cv < 0,
                    'base-success-soft': source.evm.cv >= 0
                  }"
                  @click="details('cv')"
                >
                  {{ "CV: " }} {{ source.evm.cv }}
                </div>

                <div v-if="popups.details == 'cv'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Отклонение по стоимости" }}</div>
                  <div>{{ "Есть ли отклонения по бюджету?" }}</div>
                  <div>{{ "CV = EV - AC" }}</div>
                  <div>{{ "Если CV &#60; 0, значит, есть перерасход." }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{
                    'base-light': popups.details == 'cpi',
                    'base-denial-soft': source.evm.cpi < 1,
                    'base-success-soft': source.evm.cpi >= 1
                  }"
                  @click="details('cpi')"
                >
                  {{ "CPI: " }} {{ source.evm.cpi }}
                </div>

                <div v-if="popups.details == 'cpi'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Индекс выполнения стоимости" }}</div>
                  <div>{{ "Сколько денег Вы получаете за каждый вложенный доллар?" }}</div>
                  <div>{{ "CPI = EV / AC" }}</div>
                  <div>{{ "Если CPI &#60; 1, значит, вы вкладываете больше чем получаете." }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{
                    'base-light': popups.details == 'sv',
                    'base-denial-soft': source.evm.sv < 0,
                    'base-success-soft': source.evm.sv >= 0
                  }"
                  @click="details('sv')"
                >
                  {{ "SV: " }} {{ source.evm.sv }}
                </div>

                <div v-if="popups.details == 'sv'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Отклонение по срокам" }}</div>
                  <div>{{ "Есть ли отклонения по расписанию?" }}</div>
                  <div>{{ "SV = EV - PV" }}</div>
                  <div>{{ "Если SV &#60; 0, значит, есть отклонения." }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{
                    'base-light': popups.details == 'spi',
                    'base-denial-soft': source.evm.spi < 1,
                    'base-success-soft': source.evm.spi >= 1
                  }"
                  @click="details('spi')"
                >
                  {{ "SPI: " }} {{ source.evm.spi }}
                </div>

                <div v-if="popups.details == 'spi'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Индекс выполнения сроков" }}</div>
                  <div>{{ "Укладываетесь ли Вы в сроки?" }}</div>
                  <div>{{ "SPI = EV / PV" }}</div>
                  <div>{{ "Если SPI &#60; 1, значит, вы не укладываетесь в сроки." }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'eac'}"
                  @click="details('eac')"
                >
                  {{ "EAC: " }} {{ source.evm.eac }}
                </div>

                <div v-if="popups.details == 'eac'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Прогноз по завершению" }}</div>
                  <div>{{ "Сколько проект будет стоить по завершению, если дальше Вы будете укладываться в сроки?" }}</div>
                  <div>{{ "EAC = BAC / CPI" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'etc'}"
                  @click="details('etc')"
                >
                  {{ "ETC: " }} {{ source.evm.etc }}
                </div>

                <div v-if="popups.details == 'etc'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Прогноз до завершения" }}</div>
                  <div>{{ "Сколько денег нужно, чтобы завершить проект?" }}</div>
                  <div>{{ "ETC = EAC - AC" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{
                    'base-light': popups.details == 'vac',
                    'base-denial-soft': source.evm.vac < 0,
                    'base-success-soft': source.evm.vac >= 0
                  }"
                  @click="details('vac')"
                >
                  {{ "VAC: " }} {{ source.evm.vac }}
                </div>

                <div v-if="popups.details == 'vac'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Отклонение по завершению" }}</div>
                  <div>{{ "Какой будет разница между запланированным и реальным бюджетом?" }}</div>
                  <div>{{ "VAC = BAC - EAC" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer mb-8"
                  :class="{'base-light': popups.details == 'tcpi',
                    'base-denial-soft': source.evm.tcpi > 1,
                    'base-success-soft': source.evm.tcpi <= 1
                  }"
                  @click="details('tcpi')"
                >
                  {{ "TCPI: " }} {{ source.evm.tcpi }}
                </div>

                <div v-if="popups.details == 'tcpi'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Индекс производительности" }}</div>
                  <div>{{ "С какой производительностью нужно работать, чтобы уложиться в сроки?" }}</div>
                  <div>{{ "TCPI = (BAC - EV) / (BAC - AC)" }}</div>
                </div>

                <div
                  class="card dark p-10 c-pointer"
                  :class="{'base-light': popups.details == 'pc'}"
                  @click="details('pc')"
                >
                  {{ "PC: " }} {{ source.evm.pc }} %
                </div>

                <div v-if="popups.details == 'pc'" class="card flex-column w-auto dark base-light p-10 my-10">
                  <div>{{ "Процент завершенности" }}</div>
                  <div>{{ "На сколько процентов выполнен проект?" }}</div>
                  <div>{{ "PC = EV / BAC" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </partial-content>

    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_project") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.new_name"
          class="mb-10"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.new_name"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template> -->

<!-- <script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import WidgetFilter   from "../../../_visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../_visual/widgets/projects/Projects.vue";

  import PopupBase   from "../../../visual/popups/base/Base.vue";
  import FieldInput  from "../../../_visual/fields/input/Input.vue";

  import WidgetCard from "../../../visual/widgets/cards/Default.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      PartialFilter,
      PartialContent,
      WidgetProjects,
      WidgetFilter,
      PopupBase,
      FieldInput,
      WidgetCard
    },

    setup() {
      const source = reactive(new RosterEntity);
      const filter = reactive({ spaces: [], active: {}, search: "" });

      const popups = reactive({ create: false, details: false });

      const fields = reactive({ new_name: ""   , name: ""   , code: "" });
      const errors = reactive({ new_name: false, name: false, code: "" });

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        refresh();
      });

      let refresh = () => {
        filter.spaces = source.spaces.filter(item => item.hidden != 1);
        filter.active = filter.spaces.find(item => item.hidden != 1 && item.active);

        fields.name = source.details.name;
        fields.code = source.details.code;
      };

      let show_popups_create = () => popups.create = true;
      let hide_popups_create = () => {
        popups.create   = false;
        fields.new_name = "";
      };

      let attach_errors = (field) => { errors[field] = fields[field] == "" ? true : false; };
      let remove_errors = (field) => { errors[field] = false; };

      let details = (index) => popups.details = popups.details != index ? index : false;

      return {
        source,
        errors,
        fields,
        popups,
        filter,

        show_popups_create,
        hide_popups_create,

        details,

        create: async () => {
          if (fields.new_name != "") {
            remove_errors("new_name");
            Object.assign(source, await RosterService.create({ name: fields.new_name, spaces_id: filter.active.id }));
            hide_popups_create();
          }
          else {
            attach_errors("new_name");
          }
        },

        update: async () => {
          if (fields.name != "") {
            remove_errors("name");
            Object.assign(source, await RosterService.update(Object.assign(fields, { id: source.details.id })));
          }
          else {
            attach_errors("name");
          }
        },

        remove: async (id) => Object.assign(source, await RosterService.delete({ id: id })),

        pick_project: async (id) => { Object.assign(source, await RosterService.states(filter.active.id, "project_pick", id)); refresh(); },

        space_active: async (space) => { Object.assign(source, await RosterService.states(space.id, "space_active", space.id)); refresh(); },
        space_show  : async (space) => { Object.assign(source, await RosterService.states(space.id, "space_show", space.status)); refresh(); },
        priority    : async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.spaces.map(project => { return { id: project.id }; })));
            refresh();
          }
        }
      };
    }
  };
</script> -->