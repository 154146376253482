<script setup>
  import { ref, onBeforeMount, onMounted } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../stores/StateStore.js";
  const state = useStateStore();

  import Mediator from "../../../system/mediator/Mediator";
  import { Hotkeys } from "../../../packages/hotkeys/Hotkeys";

  import LayoutWeb            from "../../../layouts/Web.vue";
  import CommonPartialFilter  from "../../../visual/partials/Filter.vue";
  import CommonPartialContent from "../../../visual/partials/Content.vue";
  import PartialFilters       from "./partials/Filters.vue";
  import PartialSidebar       from "./partials/Sidebar.vue";
  import PartialEvents        from "./partials/Events.vue";
  import PartialWatch         from "./partials/Watch.vue";
  import WidgetTabs           from "../../../visual/widgets/tabs/Default.vue";

  const tabs = ref([
    { id: 1, name: "Stream"     , active: true  },
    { id: 2, name: "Observation", active: false }
  ]);

  onBeforeMount(() => Hotkeys.reinit());

  onMounted(async () => {
    await source.load(state.offset, state.search);
    Mediator.define("stream:reload", async () => await source.load(state.offset, state.search));
  });

  function activate(tab) {
    tabs.value.map((tab) => tab.active = false);
    tab.active = tab.id;
    state.$patch({ detail: {} });
  };
</script>

<template>
  <layout-web fullscreen>
    <common-partial-filter>
      <template #content>
        <partial-filters />
      </template>
    </common-partial-filter>

    <common-partial-content :project="source.get_active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex flex-column w-100">
          <div class="d-flex flex-justify-content-end align-items-center flex-wrap base-neutral border-radius-8 mb-16 p-8">
            <widget-tabs
              :tabs="tabs"
              @activate="activate"
            />
          </div>

          <div class="overflow-y-scroll scrollbar-disable">
            <partial-events v-if="tabs[0].active" />
            <partial-watch  v-if="tabs[1].active" />
          </div>
        </div>
      </template>
    </common-partial-content>

    <partial-sidebar />
  </layout-web>
</template>