export default {
  ru: {
    Schema      : "Структура",
    add_goal    : "Добавить цель",
    details     : "Детали",
    design      : "Оформление",
    in_progress : "В работе",
    archived    : "Завершено",
    ideas       : "Идеи",
    tasks       : "Задачи",
    border_color: "Цвет границы",
    font_size   : "Размер шрифта",
    font_style  : "Стиль шрифта",
    font_color  : "Цвет шрифта",
    icons       : "Иконки",
    child       : "Потомок",
    sibling     : "Сосед",
    highlight   : "Выделить",

    input_name_and_press_enter: "Укажите название и нажмите ввод",
  },
  en: {
    Schema      : "Schema",
    add_goal    : "Add goal",
    details     : "Details",
    design      : "Design",
    in_progress : "In progress",
    archived    : "Archived",
    ideas       : "Ideas",
    tasks       : "Tasks",
    border_color: "Border color",
    font_size   : "Font size",
    font_style  : "Font style",
    font_color  : "Font color",
    icons       : "Icons",
    child       : "Сhild",
    sibling     : "Sibling",
    highlight   : "Highlight",

    input_name_and_press_enter: "Input the name and press enter",
  }
};