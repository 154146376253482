<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import Config from "../../../../config.js";

  import FieldSelectCombo           from "../../../../_visual/fields/select/combo/Combo.vue";
  import FieldDatetimeDeadlineCombo from "../../../../_visual/fields/datetime/deadline/combo/Combo.vue";
  import WidgetFilesCombo           from "../../../../visual/widgets/files/Combo.vue";
  import WidgetComplexities         from "../../../../_visual/widgets/complexities/Complexities.vue";
  import WidgetUsersWatchers        from "../../../../_visual/widgets/users/watchers/Watchers.vue";
  import WidgetTiming               from "../../../../_visual/widgets/timing/Timing.vue";

  function update_timing(manual_timing, reason, created) {
    let status_state = source.states.find((state) => state.chains_series_id == Config.SERIES_ROLE);
    source.update_timing(manual_timing, reason, created, source.node.id, status_state.id);
  };
</script>

<template>
  <div class="d-flex flex-column justify-content-between base-neutral border-radius-16 mb-16 px-27 py-18">
    <field-select-combo v-if="source.leaves.includes(parseInt(source.node.id)) || source.leaves.length == 0"
      class="mb-32"

      :value="source.subject && source.subject.chains_id || 0"
      :items="source.chains"
      :selected="source.subject && source.subject.chains_id || 0"
      :label="$t('Process')"
      :placeholder="$t('To_run_task_specify_process')"

      @submit="(value) => source.update(source.node.id, 'chains_id', value)"
    />

    <widget-complexities
      class="mb-32"

      :complexity="source.complexities.value && source.complexities.value[0]"
      :routes="source.complexities.routes"
      :label="$t('Complexity')"
      :placeholder="$t('please_insert_complexity')"

      @submit="(value, route) => source.update_complexities(source.node.id, value, route)"
    />

    <field-select-combo
      class="mb-32"

      :value="source.category && source.category.categories_id || 0"
      :items="source.categories"
      :selected="source.category && source.category.categories_id || 0"
      :label="$t('Category')"
      :placeholder="$t('please_insert_category')"
      :clear="true"

      @submit="(value) => source.update(source.node.id, 'categories_id', value)"
    />

    <!-- <field-select-combo class="mb-32" :value="source.category && source.category.categories_id || 0" :items="source.categories" :selected="source.category && source.category.categories_id || 0" :label="$t('Related')" :placeholder="$t('please_insert_related_node')" :clear="true" @submit="(value) => source.update(source.node.id, 'categories_id', value)" /> -->

    <widget-timing v-if="source.states"
      class="mb-32"

      :label="$t('Time_current_stage')"
      :value="source.timing[source.node.id]"

      @submit="(value, reason, created) => update_timing(value, reason, created)"
    />

    <field-datetime-deadline-combo
      class="mb-32"

      :value="source.node.deadline"
      :label="$t('deadline')"
      :placeholder="$t('Deadline_not_specified')"

      @submit="(value) => source.update(source.node.id, 'deadline', value)"
    />

    <widget-users-watchers
      class="mb-32"

      :label="$t('watchers')"
      :users="source.users"
      :selected="source.watchers"

      @submit="(id) => source.update_watcher(source.node.id, id)"
    />

    <widget-files-combo
      class="mb-32"

      :label="$t('files')"
      :nodes_id="source.node.id"
    />
  </div>
</template>
