export default {
  ru: {
    Planner  : "Планнер",
    planning : "Планирование",
    all      : "Все",
    Today    : "Сегодня",
    Tomorrow : "Завтра",
    Week     : "Неделя",
    Next_week: "Cледующая неделя",
    some_day : "Позднее",
    step     : "Этап",
    plan     : "План",
  },
  en: {
    Planner  : "Planner",
    planning : "Planning",
    all      : "All",
    Today    : "Today",
    Tomorrow : "Tomorrow",
    Week     : "Week",
    Next_week: "Next week",
    some_day : "Some Day",
    step     : "Step",
    plan     : "Plan",
  }
};