import Server from "../../../../system/server/server";
import Entity from "../entities/Thread.js";

export default class Thread {
  static async load(id) {
    return new Entity(await Server.get("comments/thread/" + id));
  }

  static async create(thread) {
    return new Entity(await Server.post("comments/thread", thread));
  }

  static async remove(id) {
    return new Entity(await Server.delete("comments/thread/" + id));
  }
}
