export default {
  ru: {
    Chain        : "Процесс",
    Chains       : "Процессы",
    chains       : "процессы",
    Rejected     : "Возвраты",
    Types        : "Типы",
    events       : "События",
    type         : "Тип",
    weight       : "Вес",
    add_chain    : "Создать процесс",
    insert_role  : "Укажите роль",
    insert_step  : "Укажите этап",
    insert_weight: "Укажите вес в процентах",
    step_is_empty: "Этап не указан",
  },
  en: {
    Chain        : "Chain",
    Chains       : "Chains",
    chains       : "chains",
    Rejected     : "Rejected",
    Types        : "Types",
    events       : "Events",
    type         : "Type",
    weight       : "Weight",
    add_chain    : "Add Chain",
    insert_role  : "Insert role",
    insert_step  : "Insert step",
    insert_weight: "Insert weight",
    step_is_empty: "Step is empty",
  }
};