<template>
  <div class="colors p-relative">
    <div
      class="border-radius-5 w-80px h-10px border-1 color-border-light hover-border-dark c-pointer"
      :style="{'background': color}"
      @click.stop="dropdown = !dropdown"
    />

    <div
      v-if="dropdown"
      class="dropdown p-absolute t-20px r-0 w-200px p-5 base-neutral z-20 box-shadow-bottom d-flex flex-column"
      @mouseleave="dropdown = !dropdown"
    >
      <div v-for="item in ['white', 'blue', 'green', 'yellow', 'red', 'purple', 'pink', 'grey', 'black']" :key="item">
        <div
          class="color my-5 border-1 border-radius-5 color-border-light hover-border-dark c-pointer w-100 h-20px"
          :style="{'background': item}"
          @click.stop="action(item)"
        >
          {{ action.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  export default {
    props: {
      color: { type: String, default: () => "white" }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const dropdown = ref(false);

      return { dropdown,
        action: (color) => {
          context.emit("submit", color);
          dropdown.value = !dropdown.value;
        }
      };
    }
  };
</script>