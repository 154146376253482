import Server from "../../../../system/server/server.js";

export default class File {
  static async load(id) {
    return await Server.get("files/" + id);
  }

  static async upload(data) {
    return await Server.upload("files/upload", data);
  }

  static async delete(id) {
    return await Server.delete("files/" + id);
  }
}
