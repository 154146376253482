<script setup>
  import { defineProps, defineEmits } from "vue";

  import PopupBase from "../../../../popups/base/Base.vue";

  const props = defineProps({
    item: { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "disable", "handler"
  ]);
</script>

<template>
  <teleport to="body">
    <popup-base>
      <template #header>
        <div>{{ $t("Remove") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click.stop.prevent="emit('disable')"
        />
      </template>

      <template #content>
        {{ $t("do_you_really_want_to_delete_this_element") }} - {{ props.item.name || props.item.title }} ?
      </template>

      <template #footer>
        <div
          class="button primary"
          @click.stop.prevent="emit('handler', props.item.id)"
        >
          Ok
        </div>
      </template>
    </popup-base>
  </teleport>
</template>