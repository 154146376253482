import { ref } from "vue";
import { defineStore } from "pinia";

export const useStateStore = defineStore("projects_state", () => {
  const search = ref("");
  const select = ref("");

  return {
    search,
    select
  }
})