<script setup>
  import { ref, reactive, onMounted } from "vue";

  import moment from "moment";

  import { Calendar } from "v-calendar";
  import "v-calendar/style.css";

  import PopupBase from "../../../../visual/popups/base/Base.vue";

  import WidgetCard         from "../../../../visual/widgets/cards/Simple.vue";
  import WidgetWeekdays     from "../../../../_visual/widgets/weekdays/Weekdays.vue"
  import WidgetColorsSimple from "../../../../_visual/widgets/colors/simple/Simple.vue";

  import FieldInput      from "../../../../_visual/fields/input/Input.vue";
  import FieldInputCombo from "../../../../_visual/fields/input/combo/Combo.vue";

  import RosterEntity  from "../../entities/Roster.js";
  import RosterService from "../../services/Roster.js";

  const props = defineProps({
    spaces_id: { type: String, default: () => "" },
    root_url : { type: String, default: () => "" }
  });

  const source = reactive(new RosterEntity);
  const popups = reactive({ status_create: false, status_update: false, status_day: false });
  const fields = reactive({ spaces_id: props.spaces_id, name: "", rate: "", color: "" });
  const errors = reactive({ name: false, color: false });

  const dates  = reactive({ values: [] });

  const mounted = ref(false);
  onMounted(async () => {
    Object.assign(source, await RosterService.load(props.spaces_id));
    mounted.value = true;
    reload();
  });

  function reload() {
    dates.values = [];

    source.weekends.map(weekend => {
      dates.values.push({
        highlight: {
          color: "blue",
          fillMode: "light",
        },
        dates: {
          repeat: {
            every: "weeks",
            weekdays: weekend
          }
        }
      });
    });

    source.holidays.map(holiday => {
      dates.values.push({
        highlight: {
          color: holiday.color
        },
        dates: new Date(holiday.date),
      });
    });
  };

  const show_popups_status_create = () => {
    popups.status_create = true;
  };
  const hide_popups_status_create = () => {
    popups.status_create = false;
    fields.name  = "";
    fields.rate  = "";
    fields.color = "";
  };

  // const show_popups_status_update = (item) => {
  //   popups.status_update = item;
  //   fields.name  = item.name;
  //   fields.rate  = item.rate;
  //   fields.color = item.color;
  // };
  const hide_popups_status_update = () => {
    popups.status_update = false;
    fields.name  = "";
    fields.rate  = "";
    fields.color = "";
  };

  const attach_errors = () => {
    errors.name  = fields.name  == "" ? true : false;
    errors.color = fields.color == "" ? true : false;
  };
  const remove_errors = () => {
    errors.name  = false;
    errors.color = false;
  };

  const dropdown_statuses = (day, event) => {
    let dropdown = document.querySelector("#dropdown");
    dropdown.classList.remove("d-none");
    dropdown.style.left = event.x - 10 + "px";
    dropdown.style.top = event.y - 10 + "px";

    popups.status_day = day;
  };

  const dropdown_statuses_leave = () => {
    let dropdown = document.querySelector("#dropdown");
    dropdown.classList.add("d-none");
    dropdown.style.left = 0 + "px";
    dropdown.style.top = 0 + "px";

    popups.status_day = false;
  };

  const menu = () => {
    // :menu="menu[{ title: 'Edit', handler: () => show_popups_status_update(item) }, 'Remove']"
    return [ "Remove" ];
  };

  const part = () => {
    return { content: true, actions: { menu: true } };
  };

  async function update_duration(value, calendar) {
    Object.assign(source, await RosterService.update_duration(calendar.id, "duration", value, props.spaces_id));
  };

  async function create_status() {
    if (fields.name != "" && fields.color != "") {
      remove_errors();
      Object.assign(source, await RosterService.create_status(props.spaces_id, fields.name, fields.rate, fields.color));
      hide_popups_status_create();
    }
    else {
      attach_errors();
    }
  };

  async function update_status() {
    if (fields.name != "" && fields.color != "") {
      remove_errors();
      Object.assign(source, await RosterService.update_status(popups.status_update.id, fields.name, fields.rate, fields.color));
      hide_popups_status_update();
    }
    else {
      attach_errors();
    }
  };

  async function remove_status(id) {
    Object.assign(source, await RosterService.remove_status(id, props.spaces_id ));
  };

  async function update_weekdays(weekend) {
    Object.assign(source, await RosterService.update_weekends({ spaces_id: props.spaces_id, weekend: weekend }));
    reload();
  };

  async function update_holidays(id) {
    Object.assign(source, await RosterService.update_holidays({ spaces_id: props.spaces_id, holiday: moment(popups.status_day.date).format("YYYY-MM-DD"), status: id }));
    dropdown_statuses_leave();
    reload();
  };
</script>

<template>
  <div v-if="source.access">
    <div class="d-flex">
      <div v-for="item in source.statuses" :key="item.id" class="min-w-150px m-5">
        <widget-card
          class="base-neutral"

          :item="item"
          :menu="menu()"
          :part="part()"

          @remove="remove_status"
        />
      </div>

      <div
        class="d-flex align-items-center m-5 font-light hover-font-highlight c-pointer"
        @click="show_popups_status_create"
      >
        {{ $t("add") }}
      </div>
    </div>

    <div class="d-flex my-8">
      {{ "Выходные дни:" }}
    </div>

    <div class="d-flex my-8">
      <widget-weekdays
        :values="source.weekends"
        @submit="update_weekdays"
      />
    </div>

    <div class="d-flex my-8 lh-32">
      <div class="text-nowrap mr-10">
        {{ "Продолжительность рабочего дня:" }}:
      </div>

      <field-input-combo
        class="mb-20"
        :object="source.calendar"
        :value="source.calendar.duration"
        :placeholder="$t('insert_duration')"
        @submit="update_duration"
      />
    </div>

    <div class="d-flex my-8">
      {{ "Праздничные дни:" }}
    </div>

    <div class="d-flex my-8">
      <calendar
        locale="ru"
        :masks="{ weekdays: 'WWW' }"
        :attributes="dates.values"
        disable-page-swipe
        trim-weeks
        show-weeknumbers="right"
        :first-day-of-week="2"
        :min-date="new Date()"
        :columns="3"
        @dayclick="dropdown_statuses"
      ></calendar>

      <div
        id="dropdown"
        class="d-none dropdown p-absolute w-200px p-5 base-neutral z-20 box-shadow-bottom d-flex flex-column"
        @mouseleave="dropdown_statuses_leave"
      >
        <div v-for="item in source.statuses"
          :key="item.id"
          class="my-5 hover-font-highlight c-pointer"
          @click.stop="update_holidays(item.id)"
        >
          {{ item.name }}
        </div>

        <hr>

        <div
          class="my-5 hover-font-highlight c-pointer"
          @click.stop="update_holidays(0)"
        >
          {{ $t("clear") }}
        </div>
      </div>
    </div>

    <!-- Popup: Create status -->
    <popup-base v-if="popups.status_create">
      <template #header>
        <div>{{ $t("add") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_status_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          class="mb-10"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />

        <field-input
          v-model="fields.rate"
          class="mb-10"
          :placeholder="$t('insert_rate')"
        />

        <field-input
          v-model="fields.color"
          :placeholder="$t('insert_color')"
          :message="$t('color_is_empty')"
          :failure="errors.color"
        />

        <div class="d-flex justify-content-end">
          <widget-colors-simple
            :item_id="0"
            :color="fields.color"
            @handler="(id, color) => fields.color = color"
          />
        </div>
      </template>

      <template #footer>
        <div class="button primary" @click="create_status">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>

    <!-- Popup: Update status -->
    <popup-base v-if="popups.status_update">
      <template #header>
        <div>{{ $t("update") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_status_update"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          class="mb-10"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />

        <field-input
          v-model="fields.rate"
          class="mb-10"
          :placeholder="$t('insert_rate')"
        />

        <field-input
          v-model="fields.color"
          :placeholder="$t('insert_color')"
          :message="$t('color_is_empty')"
          :failure="errors.color"
        />

        <div class="d-flex justify-content-end">
          <widget-colors-simple
            :item_id="0"
            :color="fields.color"
            @handler="(id, color) => fields.color = color"
          />
        </div>
      </template>

      <template #footer>
        <div class="button primary" @click="update_status">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>