export default {
  ru: {
    Node                            : "Задача",
    Nodes                           : "Задачи",
    nodes                           : "Задачи",
    Tasks                           : "Задачи",
    tasks                           : "Задачи",
    node_details                    : "Задача",
    date                            : "Дата",
    total                           : "Всего",
    History                         : "История",
    in_work                         : "В работе",
    completed                       : "Завершено",
    to_work                         : "В работу",
    done                            : "Завершить",
    cancel                          : "Отмена",
    edit                            : "Изменить",
    Time_current_stage              : "Время на текущем этапе",
    title                           : "Заголовок",
    reply                           : "Ответить",
    please_insert_title             : "Пожалуйста укажите заголовок",
    description                     : "Описание",
    please_insert_description       : "Пожалуйста укажите описание",
    workers                         : "Исполнители",
    watchers                        : "Наблюдатели",
    steps                           : "Шаги",
    backward                        : "Возврат",
    abort                           : "Прервать",
    Process                         : "Процесс",
    Finish                          : "Завершить",
    Child                           : "Вложенная",
    Sibling                         : "Соседняя",
    To_run_task_specify_process     : "Для запуска задачи необходимо указать процесс",
    Category                        : "Категория",
    please_insert_category          : "Пожалуйста укажите категорию",
    attached_files                  : "Прикрепить файлы",
    deadline                        : "Срок",
    please_insert_deadline          : "Пожалуйста укажите срок",
    add_comment                     : "Добавить комментарий",
    comment_is_empty                : "Комментарий пуст",
    add_step                        : "Добавить шаг",
    appointed_executor              : "назначил(а) исполнителем",
    removed_the_performer           : "снял(a) исполнителя",
    moved_next_step_process         : "перевел(а) на этап",
    completed_the_process           : "завершил(а) процесс",
    Complexity                      : "Сложность",
    please_insert_complexity        : "Пожалуйста укажите сложность",
    release                         : "Релиз",
    please_insert_release           : "Пожалуйста укажите релиз",
    enable_all_tasks_in_this_release: "Задействовать все задачи в данном релизе",
    description_is_empty            : "Пустое описание",
    subtract_time                   : "Уменьшить количество минут",
    add_time                        : "Увеличить количество минут",
    number_of_minutes               : "Количество минут",
    add_reason                      : "Укажите причину",
    save                            : "Сохранить",
    Timings                         : "Время",
    You_have_no_watchers_in_the_task: "У Вас нет наблюдателей в задаче",
    files                           : "Файлы",
    Select_or_drag_and_drop_file    : "Выберите или перетащите сюда файл",
    No_category_specified           : "Ни одна категория не указана",
    Deadline_not_specified          : "Крайний срок выполнения не указан",
    search_node                     : "Поиск задачи",
  },
  en: {
    Node                            : "Node",
    Nodes                           : "Nodes",
    nodes                           : "Nodes",
    Tasks                           : "Tasks",
    tasks                           : "Tasks",
    node_details                    : "Тode details",
    date                            : "Date",
    total                           : "Total",
    History                         : "History",
    in_work                         : "In work",
    completed                       : "Completed",
    to_work                         : "To work",
    done                            : "Done",
    cancel                          : "Cancel",
    edit                            : "Edit",
    Time_current_stage              : "Time at the current stage",
    title                           : "Title",
    reply                           : "Reply",
    please_insert_title             : "Please insert title",
    description                     : "Description",
    please_insert_description       : "Please insert description",
    workers                         : "Workers",
    watchers                        : "Watchers",
    steps                           : "Steps",
    backward                        : "Backward",
    abort                           : "Abort",
    Process                         : "Process",
    Finish                          : "Finish",
    Child                           : "Child",
    Sibling                         : "Sibling",
    To_run_task_specify_process     : "To run a task, you must specify a process",
    Category                        : "Category",
    please_insert_category          : "Please insert category",
    attached_files                  : "Attached files",
    deadline                        : "Deadline",
    please_insert_deadline          : "Please insert deadline",
    add_comment                     : "Add comment",
    comment_is_empty                : "Comment is empty",
    add_step                        : "Add step",
    appointed_executor              : "appointed executor",
    removed_the_performer           : "removed the performer",
    moved_next_step_process         : "moved to the stage",
    completed_the_process           : "completed the process",
    Complexity                      : "Complexity",
    please_insert_complexity        : "Please insert complexity",
    release                         : "Release",
    please_insert_release           : "Please insert release",
    enable_all_tasks_in_this_release: "Enable all tasks in this release",
    description_is_empty            : "Description is empty",
    subtract_time                   : "Reduce the number of minutes",
    add_time                        : "Increase the number of minutes",
    number_of_minutes               : "Number of minutes",
    add_reason                      : "Specify the reason",
    save                            : "Save",
    Timings                         : "Timings",
    You_have_no_watchers_in_the_task: "You have no watchers in the task",
    files                           : "Files",
    Select_or_drag_and_drop_file    : "Select or drag and drop a file here",
    No_category_specified           : "No category specified",
    Deadline_not_specified          : "Deadline not specified",
    search_node                     : "Search node",
  }
};