<template>
  <div class="widget field">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div class="d-flex">
      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(2) }"
        @click="submit(2)"
      >
        ПН
      </div>

      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(3) }"
        @click="submit(3)"
      >
        ВТ
      </div>

      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(4) }"
        @click="submit(4)"
      >
        СР
      </div>

      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(5) }"
        @click="submit(5)"
      >
        ЧТ
      </div>

      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(6) }"
        @click="submit(6)"
      >
        ПТ
      </div>

      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(7) }"
        @click="submit(7)"
      >
        СБ
      </div>

      <div
        class="d-flex justify-content-center mx-4 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        :class="{'color-border-highlight': values.includes(1) }"
        @click="submit(1)"
      >
        ВС
      </div>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    onMounted
  } from "vue";

  export default {
    props: {
      label      : { type: String, default: () => "" },
      values     : { type: Array , default: () => [] },
      description: { type: String, default: () => "" }
    },

    emits: [
      "submit",
    ],

    setup(props, context) {
      onMounted(() => {

      });

      return {
        submit: (weekday) => {
          context.emit("submit", weekday);
        }
      };
    }
  };
</script>
