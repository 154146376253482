<script setup>
  import { onBeforeMount, onMounted } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();

  import Mediator from "../../../system/mediator/Mediator";
  import { Hotkeys } from "../../../packages/hotkeys/Hotkeys";

  import LayoutWeb            from "../../../layouts/Web.vue";
  import CommonPartialFilter  from "../../../visual/partials/Filter.vue";
  import CommonPartialContent from "../../../visual/partials/Content.vue";
  import PartialFilters       from "./partials/Filters.vue";
  import PartialMilestones    from "./partials/Milestones.vue";
  import PartialChart         from "./partials/Chart.vue";
  import PartialSprints       from "./partials/Spints.vue";
  import PartialSidebar       from "./partials/Sidebar.vue";

  onBeforeMount(() => Hotkeys.reinit());

  onMounted(async () => {
    await source.load();
    Mediator.define("roadmap:reload", async () => await source.load());
  });
</script>

<template>
  <layout-web fullscreen>
    <common-partial-filter>
      <template #content>
        <partial-filters />
      </template>
    </common-partial-filter>

    <common-partial-content :project="source.get_active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <partial-milestones />

          <div v-if="source.get_active.road_pick"
            class="d-flex flex-column flex-grow overflow-hidden"
          >
            <partial-sprints />

            <partial-chart />

            <div id="chart-overview" />
          </div>
        </div>
      </template>
    </common-partial-content>

    <partial-sidebar />
  </layout-web>
</template>