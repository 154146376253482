<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    lang       : { type: Boolean, default: () => false },
    label      : { type: String , default: () => ""    },
    value      : { type: Object , default: () => {}    },
    description: { type: String , default: () => ""    }
  });

  const emit = defineEmits([
    "submit"
  ]);
</script>

<template>
  <div class="field w-100 mb-16">
    <div v-if="props.label" class="label">
      {{ props.label }}
    </div>

    <div class="checkbox">
      <label>
        <input
          class="checkbox"
          type="checkbox"
          :checked="!!props.value.status"
          @click="emit('submit', { id: props.value.id, status: !props.value.status })"
        >

        <span class="checkmark" />

        <div class="text lh-20">
          {{ lang ? $t(props.value.content) : props.value.content }}
        </div>
      </label>
    </div>

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>
  </div>
</template>