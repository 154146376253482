<script setup>
  import { ref, watchEffect, onMounted, defineProps, defineEmits } from "vue";

  import Default from "./Default.vue";

  const props = defineProps({
    view: { type: Boolean, default: () => false },
    work: { type: Number , default: () => 0     },
    done: { type: Number , default: () => 0     }
  });

  const emit = defineEmits([
    "switch_show"
  ]);

  const percent = ref(0);

  onMounted(() => recount(props.work, props.done));
  watchEffect(() => recount(props.work, props.done));

  function recount(work, done) {
    if (work + done) {
      percent.value = Math.ceil(done / (done + work) * 100);
    }
  };
</script>

<template>
  <div class="d-flex " @click.exact.stop.prevent="emit('switch_show')">
    <default entity="completed" :percent="percent">
      <template #content>
        <div v-if="props.view"
          class="d-flex justify-content-center align-items-center ml-2 mr-4"
        >
          <div class="font-section">
            {{ props.done }}
          </div>
          /
          <div class="font-soft">
            {{ props.done + props.work }}
          </div>
        </div>
      </template>
    </default>
  </div>
</template>