import Server from "../../../system/server/server";

export default class Auth {
  static async login(user) {
    let response = await Server.post("login", user);
    return handle_authorization(response);
  }

  static async registration(user) {
    let response = await Server.post("register", user);
    return handle_authorization(response);
  }

  static async forgot(user) {
    let response = await Server.post("forgot", user);
    return authorization_messages(response);
  }
}

const URL_HOME   = "/planner";
const AUTH_TOKEN = "auth-token";

function handle_authorization(response) {
  if (response.token) {
    set_cookie(response.token);
    document.location.replace(URL_HOME);
    return false;
  }
  else {
    return response.status.failure.message;
  }
}

function authorization_messages(response) {
  return {
    success: response.data  ? response.data.message           : false,
    failure: !response.data ? response.status.failure.message : false,
  };
}

function set_cookie(token) {
  var date = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
  document.cookie = AUTH_TOKEN + "=" + token + "; path=/; expires=" + date.toUTCString();
}