<script setup>
  import { defineAsyncComponent } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  const StreamPlugin   = defineAsyncComponent(() => import('../../../stream/plugins/complex/Users.vue'));
  const NodePlugin     = defineAsyncComponent(() => import('../../../node/plugins/complex/Users.vue'));
  const RejectPlugin   = defineAsyncComponent(() => import("../../../chains/plugins/complex/Users.vue"));
  const ProjectsPlugin = defineAsyncComponent(() => import("../../../roles/plugins/complex/Projects.vue"));
  const RolesPlugin    = defineAsyncComponent(() => import("../../../roles/plugins/complex/Users.vue"));
  const CalendarPlugin = defineAsyncComponent(() => import("../../../calendar/plugins/complex/Calendar.vue"));
  const BookingPlugin  = defineAsyncComponent(() => import("../../../calendar/plugins/complex/Booking.vue"));

  const style = "d-flex flex-column h-100 overflow-hidden";
</script>

<template>
  <div class="d-flex overflow-y-scroll scrollbar-disable">
    <div v-if="state.select == 'reject'" :class="style">
      <reject-plugin :space_id="source.get_active.id" :users_id="source.get_active.user_pick" />
    </div>

    <div v-if="state.select == 'projects'" :class="style">
      <projects-plugin :space_id="source.get_active.id" :users_id="source.get_active.user_pick" />
    </div>

    <div v-if="state.select == 'roles'" :class="style">
      <roles-plugin :space_id="source.get_active.id" :users_id="source.get_active.user_pick" />
    </div>

    <div v-if="state.select == 'booking'" :class="style">
      <booking-plugin :space_id="source.get_active.id" :users_id="source.get_active.user_pick" />
    </div>

    <div v-if="state.select == 'calendar'" :class="style">
      <calendar-plugin :space_id="source.get_active.id" :users_id="source.get_active.user_pick" />
    </div>

    <div v-if="state.select == 'node'" :class="style">
      <node-plugin :users_id="source.get_active.user_pick" />
    </div>

    <div v-if="state.select == 'stream'" :class="style">
      <stream-plugin :users_id="source.get_active.user_pick" />
    </div>
  </div>
</template>