import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM  from "../system.js";
import SERVICE from "../services/Roster.js";

import CHART from "../../../system/chart/entities/Blank.js";

export const useSourceStore = defineStore("schema_source", () => {
  const access   = ref(false);
  const errors   = ref({});
  const status   = ref({});
  const tree     = ref({});
  const chart    = ref([]);
  const squad    = ref([]);
  const chains   = ref([]);
  const filters  = ref({});
  const projects = ref([]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  const get_active = computed(() => {
    return projects.value.find(project => project.active && project.hidden != 1);
  });

  const get_visible = computed(() => {
    return projects.value.filter(project => project.hidden != 1);
  });

  function reload(response) {
    if (response.data) {
      access.value   = response.access;
      errors.value   = response.errors;
      status.value   = response.status;
      tree.value     = response.data.tree;
      chart.value    = response.data.chart;
      squad.value    = response.data.squad;
      chains.value   = response.data.chains;
      filters.value  = response.data.filters;
      projects.value = response.data.projects;
    }
  };

  function chart_fold(node, action) {
    return CHART.fold(chart.value, node, action);
  };

  function chart_blank_child(id) {
    CHART.blank(chart.value, id, "child");
  };

  function chart_blank_sibling(id) {
    CHART.blank(chart.value, id, "sibling");
  };

  function chart_blank_cancel() {
    CHART.cancel(chart.value);
  };

  return {
    access,
    errors,
    status,
    tree,
    chart,
    squad,
    chains,
    filters,
    projects,

    is_access,

    get_active,
    get_visible,

    chart_fold,
    chart_blank_child,
    chart_blank_sibling,
    chart_blank_cancel,

    load: async () => reload(await SERVICE.load()),

    node_branch: async (target) => reload(await SERVICE.create_bundle(get_active.value.id, target)),

    node_child: async (target, is_chart = true) => {
      reload(await SERVICE.create_child(get_active.value.id, target));
      is_chart && chart_blank_child(target.id)
    },

    node_sibling: async (target, is_chart = true) => {
      reload(await SERVICE.create_sibling(get_active.value.id, target));
      is_chart && chart_blank_sibling(target.id)
    },

    node_clone: (id, action) => {
      localStorage.setItem("id", id);
      localStorage.setItem("action", action);
    },

    node_paste: async (id) => {
      if (localStorage.getItem("id")) {
        if (localStorage.getItem("action") == "copy") {
          reload(await SERVICE.insert(id, localStorage.getItem("id"), get_active.value.id));
        }
        else if (localStorage.getItem("action") == "cut" ) {
          reload(await SERVICE.move(localStorage.getItem("id"), id));
        }
  
        localStorage.removeItem("id");
        localStorage.removeItem("action");
      }
    },

    node_move_location: async (data) => reload(await SERVICE.move_location(data.node_id, data.target_id, data.location)),

    node_move_start: (event, node) => {
      event.dataTransfer.dropEffect    = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("node_id", node.id);
    },

    node_move_drop: async (event, node) => {
      if (node && node.id != event.dataTransfer.getData("node_id")) {
        reload(await SERVICE.move(event.dataTransfer.getData("node_id"), node.id));
      }
    },

    node_folded: async (id) => reload(await SERVICE.states(get_active.value.id, "node_fold", id)),

    node_update: async (option, id, value) => reload(await SERVICE.update(id, option, value)),

    node_access: async (id, type) => reload(await SERVICE.access(id, type)),

    node_finish: async (id) => reload(await SERVICE.finish(id)),

    node_launch: async (data) => reload(await SERVICE.enable(data.nodes_id, data.chains_id)),

    node_remove: async (id) => reload(await SERVICE.remove(id)),

    tree_fold : async (id) => reload(await SERVICE.states(get_active.value.id, "tree_fold", id)),

    tree_pick : async (id) => reload(await SERVICE.states(get_active.value.id, "tree_pick", id)), // focusing([]);

    tree_show : async (state) => reload(await SERVICE.states(state.id, "tree_show", +state.status)),

    tree_order: async (target, source, offset) => reload(await SERVICE.order(target, source, offset)),

    mode_view: async (state) => reload(await SERVICE.states(state.id, "mode_view", +state.status)),

    filter_status : async (filter, status) => reload(await SERVICE.states(null, filter, +status)),

    filter_empty  : async (state) => reload(await SERVICE.states(state.id, "filter_empty", +state.status)),

    filter_workers: async (id) => reload(await SERVICE.states(id, "filter_workers", id)),

    project_activate: async (id) => reload(await SERVICE.states(id, "project_active", id)),

    project_visible : async (id) => reload(await SERVICE.states(id, "project_show"  , id)),

    project_priority: async (event) => event.moved &&
      reload(await SERVICE.priority(get_visible.value.map(project => { return { id: project.id }; })))
  };
})