<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    access: { type: Number, default: () => 1 }
  });
</script>

<template>
  <div
    class="icon small"
    :class="{
      'icon-access-private': props.access == 1,
      'icon-access-limited': props.access == 2,
    }"

    @click.exact.stop.prevent
  />
</template>