<script setup>
  import { reactive } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import Server from "../../../../system/server/server.js";

  import FieldEditorCombo from "../../../../visual/fields/editor/Combo.vue";

  const token = reactive({
    value: "token=" + Server.token() + "\"",
    regex: new RegExp("token=([\\w,|]*)\"","g")
  });
</script>

<template>
  <div class="d-flex base-neutral border-radius-16 mx-27 mb-16 px-27 py-18">
    <field-editor-combo
      :node_id="source.node.id"
      :value="source.node.description && source.node.description.replace(token.regex, token.value)"
      :placeholder="$t('please_insert_description')"

      @submit="(value) => source.update(source.node.id, 'description', value)"
    />
  </div>
</template>