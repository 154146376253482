<script setup>
  import { ref, watch, onMounted, defineProps, defineEmits } from "vue";

  import FieldAutocomplete from "../../../../visual/fields/autocomplete/Default.vue";

  const props = defineProps({
    node: { type: Object , default: () => {}    },
    pool: { type: Array  , default: () => []    },
    lock: { type: Boolean, default: () => false }
  });

  const emit = defineEmits([
    "root", "child", "sibling", "cancel"
  ]);

  const list = ref([]);

  onMounted(() => list.value = props.pool);
  watch(() => props.pool, (pool) => list.value = pool);

  function search(word) {
    list.value = props.pool.filter(item =>
      item.title && item.title.toLowerCase().includes(word.toLowerCase())
    ).map(row => {
      return {
        id  : row.id,
        code: row.code,
        name: row.title,
        path: row.path
      };
    });
  };

  function submit(node) {
    if      (node && !node.name               ) { emit("cancel");                                                                                                          }
    else if (props.node.entity.type == "root" ) { emit("root"   , { node_id: node.id, title: node.name, offset: props.node.option.offset                               }); }
    else if (props.node.entity.type == "child") { emit("child"  , { id: props.node.entity.target, node_id: node.id, title: node.name, offset: props.node.option.offset }); }
    else                                        { emit("sibling", { id: props.node.entity.target, node_id: node.id, title: node.name, offset: props.node.option.offset }); }
  };
</script>

<template>
  <div class="content"
    draggable="false"
  >
    <field-autocomplete
      :autofocus="true"
      :pathable="true"

      :list="list"
      :lock="props.lock"

      :placeholder="$t('search_node')"

      @handle_search="search"
      @handle_choose="submit"
    />
  </div>
</template>

<style lang="scss" scoped>
  .node {
    border: 1px solid transparent;
    cursor: pointer;

    & > .content {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
</style>