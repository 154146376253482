import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(spaces_id) {
    return new Entity(await Server.get("roles?spaces_id=" + spaces_id));
  }

  static async load_project(space_id, user_id) {
    return await Server.get("roles/project?space_id=" + space_id + "&user_id=" + user_id);
  }

  static async load_user(space_id, user_id) {
    return await Server.get("roles/user?space_id=" + space_id + "&user_id=" + user_id);
  }

  static async create(role) {
    return new Entity(await Server.post("roles", role));
  }

  static async delete(role_id) {
    return new Entity(await Server.delete("roles/" + role_id));
  }
}