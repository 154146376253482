import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(spaces_id) {
    return new Entity(await Server.get("factors/" + spaces_id));
  }

  static async create(factor) {
    return new Entity(await Server.post("factors", factor));
  }

  static async update(id, field, value) {
    return new Entity(await Server.put("factors/" + id, { id: id, field: field, value: value }));
  }

  static async delete(id) {
    return new Entity(await Server.delete("factors/" + id));
  }
}