<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import WidgetCard from "../../../../visual/widgets/cards/Default.vue";

  const menu = () => {
    return [];
  };

  const part = () => { return {
    fold   : false,
    content: true,
    weight : false,
    actions: { menu: false, launch: false, create: false, finish: false },
    diagram: { done: false, work: false, time: true, dead: true, step: true }
  }; };
</script>

<template>
  <div class="box-grid col-5">
    <widget-card v-for="node in source.nodes"
      :key="node.id"

      class="h-100 base-neutral"

      :selected="state.detail.id == node.id"

      :item="node"
      :menu="menu()"
      :part="part()"
      :stages="source.stage[node.id]"

      @pick="(item) => state.$patch({ detail: item })"
    />
  </div>
</template>