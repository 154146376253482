<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap base-neutral border-radius-8 mb-16 p-8">
    <div class="font-title">
      {{ $t("Profile") }}
    </div>

    <widget-tabs
      :tabs="tabs"
      @activate="activate"
    />
  </div>
</template>

<script>
  import { reactive  } from "vue";
  import { useRouter } from "vue-router";

  import WidgetTabs from "../../../../_visual/widgets/tabs/Tabs.vue";

  export default {
    components: {
      WidgetTabs
    },

    props: {
      id    : { type: Number, default: () => 0 },
      active: { type: Number, default: () => 0  }
    },

    setup(props) {
      const router = useRouter();

      const tabs = reactive([
        { id: 1, name: "Details", active: false }
      ]);

      tabs[props.active].active = true;

      return {
        tabs,

        activate: (tab)  => {
          tabs.map(function(tab) { tab.active = false; });
          tab.active = tab.id;

          let path = tab.url &&
            "/profile/" + props.id + "/" + tab.url ||
            "/profile";

          router.push({ path: path });
        }
      };
    }
  };
</script>