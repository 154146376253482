<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    icon: { type: String, default: () => "" },
    item: { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "handler"
  ]);
</script>

<template>
  <div class="actions p-absolute r--7px b--7px z-104">
    <div
      class="default box-shadow-bottom z-104"
      @click.exact.stop.prevent="emit('handler', props.item.id)"
    >
      <div :class="['icon', props.icon]" />
    </div>
  </div>
</template>