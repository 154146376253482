<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    project: { type: Object , default: () => {}    },
    complex: { type: Boolean, default: () => false },
    neutral: { type: Boolean, default: () => true  },
    message: { type: String , default: () => ""    }
  });
</script>

<template>
  <div class="d-flex overflow-y-hidden flex-grow justify-content-between px-8 pt-60">
    <div v-if="!props.complex && props.project && props.project.id"
      class="overflow-scroll scrollbar w-100 h-100"
      :class="{ 'base-neutral': props.neutral, 'p-8': props.neutral }"
    >
      <slot name="content" />
    </div>

    <slot v-else-if="props.complex && props.project && props.project.id" name="content" />

    <div v-else
      class="d-flex justify-content-center align-items-center base-neutral w-100 p-8"
    >
      {{ props.message ? $t(props.message) : $t('Choose_project') }}
    </div>
  </div>
</template>