 import Server from "../../../../system/server/server";
import Entity from "../entities/Comment.js";

export default class Comment {
  static async load(id) {
    return new Entity(await Server.get("comments/" + id));
  }

  static async create(comment) {
    return new Entity(await Server.post("comments/", comment));
  }

  static async remove(id) {
    return new Entity(await Server.delete("comments/" + id));
  }
}
