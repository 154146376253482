import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(spaces_id) {
    return new Entity(await Server.get("chains/" + spaces_id));
  }

  static async load_reject_project(project_id) {
    return await Server.get("chains/project?project_id=" + project_id);
  }

  static async load_reject_user(space_id, user_id) {
    return await Server.get("chains/user?space_id=" + space_id + "&user_id=" + user_id);
  }

  static async create(chain) {
    return new Entity(await Server.post("chains", chain));
  }

  static async delete(chain_id) {
    return new Entity(await Server.delete("chains/" + chain_id));
  }
}