<script setup>
  import { ref, watch, defineProps, defineEmits } from "vue";

  import Server from "../../../system/server/server.js";
  import FileAdapter from "./adapters/File.js";

  import CKEditor      from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

  const props = defineProps({
    active     : { type: Boolean, default: false    },
    nodes_id   : { type: Number , default: () => 4  },
    value      : { type: String , default: () => "" },
    label      : { type: String , default: () => "" },
    placeholder: { type: String , default: () => "" },
    description: { type: String , default: () => "" },
    failure    : { type: Boolean, default: false    },
    message    : { type: String , default: () => "" }
  });

  const emit = defineEmits([
    "submit"
  ]);

  watch(() => props.value, (value) => { editorData.value = value; });

  const edit = ref(props.active);

  const editor       = ClassicEditor;
  const editorData   = ref(props.value);
  const editorConfig = ref({
    language: "ru",
    toolbar: {
      items: [ "heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "imageUpload", "blockQuote", "mediaEmbed", "undo", "redo" ],
    },
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"]
    },
    mediaEmbed: {
      previewsInData: true
    },
    extraPlugins: [(editor) => {
      editor.plugins.get( "FileRepository" ).createUploadAdapter = ( loader ) => {
        return new FileAdapter( loader, Server.token(), props.nodes_id );
      };
    }],
  });

  function onEditorReady(editor) {
    editor.model.change(writer => {
      writer.setSelection(editor.model.document.getRoot(), 'end');
    });
    editor.editing.view.focus();
  }

  function clear() {
    editorData.value = props.value;
    edit.value = false;
  };

  function submit() {
    emit("submit", editorData.value);
  };
</script>

<template>
  <div class="field w-100">
    <div v-if="props.label" class="font-soft font-memo mb-8">
      {{ props.label }}
    </div>

    <div v-if="!edit"
      class="d-flex overflow-wrap c-crosshair"
      @click="edit = true"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="props.value" class="ck-content w-100" v-html="props.value" />

      <div v-else class="placeholder font-memo font-italic">
        {{ props.placeholder }}
      </div>
    </div>

    <div v-if="edit"
      class="ckeditor-wrapper"

      @keydown.stop

      @keyup.esc.exact.stop="clear"
      @keyup.enter.shift.exact.stop="submit"
    >
      <div class="d-flex justify-content-end mb-5">
        <div
          class="font-denial c-pointer"
          @click="clear"
        >
          {{ $t("cancel") }}

          <div class="font-memo font-light lh-14">
            {{ "ESC" }}
          </div>
        </div>

        <div
          class="font-highlight ml-8 c-pointer"
          @click="submit"
        >
          {{ $t("save") }}

          <div class="font-memo font-light lh-14">
            {{ "Shift + Enter" }}
          </div>
        </div>
      </div>

      <CKEditor.component
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"

        @ready="onEditorReady"
      />
    </div>

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>

    <div v-if="props.failure" class="font-denial font-memo">
      {{props. message }}
    </div>
  </div>
</template>