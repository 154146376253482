import { ref } from "vue";
import { defineStore } from "pinia";

import { Blank, States } from "../../../packages/states/States.js";

export const useStateStore = defineStore("roadmap_state", () => {
  const milestone_blank = ref(0);

  const sprint_pick  = ref(-1);
  const sprint_break = ref(false);

  const detail = ref({});
  const select = ref(-1);

  const states  = ref([]);
  const manager = new States(states.value, NodeState);

  function cleanup_actions() {
    manager.cleanup(["context", "heading", "period"]);
  }

  function switch_action(id, clear, target, region) {
    if (id > -1) {
      manager.cleanup(clear);
      manager.switch_state(id, region, target);
    }
    else {
      cleanup_actions();
    }
  }

  function switch_field(array, clear, target, region, value) {
    cleanup_actions();

    array.forEach(id => {
      manager.cleanup(clear, id);
      manager.switch_state(id, region, target, value);
    });
  }

  return {
    milestone_blank,

    sprint_pick,
    sprint_break,

    detail,
    select,

    get_state: (id, region, field) => {
      return manager.get_state(id, region, field);
    },

    switch_context: (id, region) => {
      switch_action(id, ["heading", "period"], "context", region);
    },

    switch_heading: (id, region) => {
      switch_action(id, ["context", "period"], "heading", region);
    },

    switch_period: (id, region) => {
      switch_action(id, ["context", "heading"], "period", region);
    },

    switch_dead_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["done_show", "step_show", "time_show", "work_show"], "dead_show", region, group.length ? true : undefined);
    },

    switch_done_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "step_show", "time_show", "work_show"], "done_show", region, group.length ? true : undefined);
    },

    switch_step_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "done_show", "time_show", "work_show"], "step_show", region, group.length ? true : undefined);
    },

    switch_time_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "done_show", "step_show", "work_show"], "time_show", region, group.length ? true : undefined);
    },

    switch_work_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "done_show", "step_show", "time_show"], "work_show", region, group.length ? true : undefined);
    },

    node_activate: (node, action = "") => {
      detail.value = node;
      detail.value.action = action;
    },

    node_deactivate: () => {
      detail.value = {};
    },

    sprint_activate: (id) => {
      select.value = id;
    },

    sprint_deactivate: () => {
      select.value = -1;
    }
  };
})

class NodeState extends Blank {
  constructor (id, region, field, value) {
    super(id, region);

    this.context   = false;
    this.heading   = false;
    this.period    = false;
    this.dead_show = false;
    this.done_show = false;
    this.step_show = false;
    this.time_show = false;
    this.work_show = false;

    if (value !== undefined)
      this[field] = value;
  };
}