import Server from "../../../../system/server/server";

export default class Tracker {
  static async assignee() {
    return await Server.get("tracker");
  }

  static async activate(state_id) {
    return await Server.post("tracker", { status_states_id: state_id });
  }

  static async launch(role_state_id) {
    return await Server.post("tracker/launch", { role_state_id: role_state_id });
  }

  static async finish(role_state_id) {
    return await Server.post("tracker/finish", { role_state_id: role_state_id });
  }
}