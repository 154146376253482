<script setup>
  import { reactive, onMounted, watch, defineProps, defineEmits } from "vue";

  import CardsPartialTitle   from "./partials/Title.vue";
  import CardsPartialContent from "./partials/Content.vue";

  import CardsPartialActionMenu from "./partials/actions/Menu.vue";

  import CardsPartialPopupRemove from "./partials/popups/Remove.vue";

  const props = defineProps({
    item: { type: Object , default: () => {}    },
    menu: { type: Array  , default: () => []    },
    part: { type: Object , default: () => {}    },

    selected: { type: Boolean, default: () => false },
    region  : { type: String , default: () => ""    },

    context: { type: Boolean, default: () => false },
    heading: { type: Boolean, default: () => false },
  });

  const emit = defineEmits([
    "pick", "update", "remove", "switch_context", "switch_heading"
  ]);

  const popup = reactive({ remove: false });

  const handlers = reactive({ list: [] });

  onMounted(() => init_menu(props.menu));
  watch(() => props.menu, (menu) => init_menu(menu));

  function init_menu(menu) {
    handlers.list = [];

    menu.forEach((item) => {
      if      (item.title === "Details" && (!item.items)) { handlers.list.push({...item, ...{ handler: redirect   , hotkey: "Ctrl + Click" }}); }
      else if (item.title === "Remove"  && (!item.items)) { handlers.list.push({...item, ...{ handler: show_remove, hotkey: ""             }}); }
      else                                                { handlers.list.push({...item                                                     }); }
    });
  };

  function show_remove() { popup.remove = true ; };
  function hide_remove() { popup.remove = false; };

  function redirect(id) { emit("pick", id); }

  function remove(id) { emit("remove", id); hide_remove(); }
</script>

<template>
  <div
    class="card default p-relative d-flex border-radius-primary border-1 color-border-soft box-shadow-card"
    :class="{ 'selected': props.selected }"

    @click.exact.stop.prevent="emit('pick', props.item)"
    @click.shift.exact.stop.prevent="emit('switch_heading', props.item.id, props.region)"
  >
    <div class="d-flex flex-column justify-content-between w-100 p-4">
      <div class="d-flex justify-content-between h-28px mb-4">
        <cards-partial-title
          class="w-100"

          :edit="props.heading"
          :text="props.item.name || props.item.title"

          @handler="title => emit('update', 'title', props.item.id, title)"
          @switch="emit('switch_heading', props.item.id, props.region)"
        />
      </div>

      <cards-partial-content v-if="props.part.content"
        :string="props.item.description"
      />
    </div>

    <cards-partial-action-menu v-if="props.part.actions.menu && props.menu"
      :active="props.context"
      :item="props.item"
      :actions="handlers.list"

      @switch="() => emit('switch_context', props.item.id, props.region)"
    />

    <cards-partial-popup-remove v-if="popup.remove"
      :item="props.item"

      @disable="hide_remove"
      @handler="(id) => remove(id)"
    />
  </div>
</template>