<script setup>
  import { reactive, onMounted, watch, defineProps, defineEmits } from "vue";

  import CardsPartialTitle   from "./partials/Title.vue";
  import CardsPartialCode    from "./partials/Code.vue";
  import CardsPartialPeriod  from "./partials/Period.vue";

  import CardsPartialDiagramDone from "./partials/diagram/Done.vue";
  import CardsPartialDiagramWork from "./partials/diagram/Work.vue";
  import CardsPartialDiagramTime from "./partials/diagram/Time.vue";

  import CardsPartialActionMenu    from "./partials/actions/Menu.vue";
  import CardsPartialActionDefault from "./partials/actions/Default.vue";

  import CardsPartialPopupRemove from "./partials/popups/Remove.vue";

  const props = defineProps({
    item      : { type: Object , default: () => {}    },
    menu      : { type: Array  , default: () => []    },
    part      : { type: Object , default: () => {}    },

    selected  : { type: Boolean, default: () => false },
    region    : { type: String , default: () => ""    },

    context   : { type: Boolean, default: () => false },
    heading   : { type: Boolean, default: () => false },
    period    : { type: Boolean, default: () => false },
    done_show : { type: Boolean, default: () => false },
    time_show : { type: Boolean, default: () => false },
    work_show : { type: Boolean, default: () => false },
  });

  const emit = defineEmits([
    "pick", "finish", "remove", "update",
    "switch_context", "switch_heading", "switch_period",
    "switch_done_show", "switch_time_show", "switch_work_show"
  ]);

  const popup = reactive({ remove: false });

  const handlers = reactive({ list: [] });

  onMounted(() => init_menu(props.menu));
  watch(() => props.menu, (menu) => init_menu(menu));

  function init_menu(menu) {
    handlers.list = [];

    menu.forEach((item) => {
      if      (item.title === "Finish"  && (!item.items)) { handlers.list.push({...item, ...{ handler: finish     , hotkey: "" }}); }
      else if (item.title === "Remove"  && (!item.items)) { handlers.list.push({...item, ...{ handler: show_remove, hotkey: "" }}); }
      else                                                { handlers.list.push({...item                                         }); }

      if (item.items && item.items.length) {
        handlers.list[handlers.list.length - 1].actions = [];

        item.items.forEach((action) => {
          handlers.list[handlers.list.length - 1].actions.push({...action});
        });
      }

      delete handlers.list[handlers.list.length - 1].items;
    });
  };

  function show_remove() { popup.remove = true ; };
  function hide_remove() { popup.remove = false; };

  function finish() { emit("finish", props.item.id); };

  function remove(id) { emit("remove", id); hide_remove(); }
</script>

<template>
  <div
    class="card default p-relative d-flex border-radius-primary border-1 color-border-soft box-shadow-card"
    :class="{ 'selected': props.selected }"

    @click.exact.stop.prevent="emit('pick', item)"
    @click.shift.exact.stop.prevent="emit('switch_heading', props.item.id, props.region)"
  >
    <div class="d-flex flex-column justify-content-between w-100 p-4">
      <div class="d-flex justify-content-between h-28px mb-4">
        <cards-partial-title
          class="w-100"

          :edit="props.heading"
          :text="props.item.name || props.item.title"

          @handler="title => emit('update', 'title', props.item.id, title)"
          @switch="emit('switch_heading', props.item.id, props.region)"
        />
      </div>

      <div class="d-flex justify-content-between h-28px mb-4">
        <cards-partial-period
          class="w-100 align-items-center"

          :edit="props.period"
          :start="props.item.start"
          :finish="props.item.finish"

          @handler="(option, value) => emit('update', option, props.item.id, value)"
          @switch="emit('switch_period', props.item.id, props.region)"
        />
      </div>

      <div class="d-flex justify-content-between h-24px">
        <div class="d-flex flex-grow">
          <cards-partial-diagram-done v-if="props.part.diagram.done"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.done_show,
              'color-border-highlight-opacity-0': !props.done_show,
              'color-border-highlight-opacity-1': props.done_show
            }"

            :work="+props.item.work"
            :done="+props.item.done"
            :view="props.done_show"

            @switch_show="() => emit('switch_done_show', props.item.id, props.region)"
          />

          <cards-partial-diagram-time v-if="props.part.diagram.time"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.time_show,
              'color-border-highlight-opacity-0': !props.time_show,
              'color-border-highlight-opacity-1': props.time_show
            }"

            :plan="+props.item.complexities"
            :fact="+props.item.timing"
            :view="props.time_show"

            @switch_show="() => emit('switch_time_show', props.item.id, props.region)"
          />

          <cards-partial-diagram-work v-if="props.part.diagram.work"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.work_show,
              'color-border-highlight-opacity-0': !props.work_show,
              'color-border-highlight-opacity-1': props.work_show
            }"

            :idea="+props.item.idea"
            :work="+props.item.work"
            :view="props.work_show"

            @switch_show="() => emit('switch_work_show', props.item.id, props.region)"
          />
        </div>

        <cards-partial-code v-if="props.item.code || props.item.id"
          class="pl-8 align-items-end"
          :string="props.item.code || String(props.item.id)"
        />
      </div>
    </div>

    <cards-partial-action-menu v-if="props.part.actions.menu && menu"
      :active="props.context"
      :item="props.item"
      :actions="handlers.list"

      @switch="() => emit('switch_context', props.item.id, props.region)"
    />

    <cards-partial-action-default v-if="props.part.actions.finish"
      icon="icon-finish"
      :item="props.item"

      @handler="(id) => emit('finish', id)"
    />

    <cards-partial-popup-remove v-if="popup.remove"
      :item="props.item"

      @disable="hide_remove"
      @handler="(id) => remove(id)"
    />
  </div>
</template>