<script setup>
  import { reactive, onMounted, watch, defineProps, defineEmits } from "vue";

  import CardsPartialFold    from "./partials/Fold.vue";
  import CardsPartialTitle   from "./partials/Title.vue";
  import CardsPartialStatus  from "./partials/Status.vue";
  import CardsPartialAccess  from "./partials/Access.vue";
  import CardsPartialCode    from "./partials/Code.vue";
  import CardsPartialContent from "./partials/Content.vue";

  import CardsPartialDiagramDone from "./partials/diagram/Done.vue";
  import CardsPartialDiagramWork from "./partials/diagram/Work.vue";
  import CardsPartialDiagramTime from "./partials/diagram/Time.vue";
  import CardsPartialDiagramDead from "./partials/diagram/Dead.vue";
  import CardsPartialDiagramStep from "./partials/diagram/Step.vue";

  import CardsPartialActionMenu    from "./partials/actions/Menu.vue";
  import CardsPartialActionLaunch  from "./partials/actions/Launch.vue";
  import CardsPartialActionDefault from "./partials/actions/Default.vue";

  import CardsPartialPopupRemove from "./partials/popups/Remove.vue";

  const props = defineProps({
    item      : { type: Object , default: () => {}    },
    menu      : { type: Array  , default: () => []    },
    part      : { type: Object , default: () => {}    },
    chains    : { type: Array  , default: () => []    },
    stages    : { type: Array  , default: () => []    },

    selected  : { type: Boolean, default: () => false },
    region    : { type: String , default: () => ""    },

    context   : { type: Boolean, default: () => false },
    process   : { type: Boolean, default: () => false },
    heading   : { type: Boolean, default: () => false },
    dead_show : { type: Boolean, default: () => false },
    done_show : { type: Boolean, default: () => false },
    step_show : { type: Boolean, default: () => false },
    time_show : { type: Boolean, default: () => false },
    work_show : { type: Boolean, default: () => false },
    dead_edit : { type: Boolean, default: () => false },
    time_edit : { type: Boolean, default: () => false }
  });

  const emit = defineEmits([
    "pick", "fold", "zoom", "create", "clone", "paste", "launch", "finish", "access", "update", "remove",
    "switch_context", "switch_process", "switch_heading",
    "switch_dead_show", "switch_done_show", "switch_step_show", "switch_time_show", "switch_work_show",
    "switch_dead_edit", "switch_time_edit"
  ]);

  const popup = reactive({ remove: false });

  const handlers = reactive({ list: [] });

  onMounted(() => init_menu(props.menu));
  watch(() => props.menu, (menu) => init_menu(menu));

  function init_menu(menu) {
    handlers.list = [];

    menu.forEach((item) => {
      if      (item.title === "Details" && (!item.items)) { handlers.list.push({...item, ...{ handler: redirect   , hotkey: "Ctrl + Click" }}); }
      else if (item.title === "Zoom"    && (!item.items)) { handlers.list.push({...item, ...{ handler: zoom       , hotkey: "DBClick"      }}); }
      else if (item.title === "Create"  && (!item.items)) { handlers.list.push({...item, ...{ handler: create     , hotkey: "Insert"       }}); }
      else if (item.title === "Finish"  && (!item.items)) { handlers.list.push({...item, ...{ handler: finish     , hotkey: ""             }}); }
      else if (item.title === "Remove"  && (!item.items)) { handlers.list.push({...item, ...{ handler: show_remove, hotkey: ""             }}); }
      else if (item.title === "Cut"     && (!item.items)) { handlers.list.push({...item, ...{ handler: cut        , hotkey: "Ctrl + X"     }}); }
      else if (item.title === "Copy"    && (!item.items)) { handlers.list.push({...item, ...{ handler: copy       , hotkey: "Ctrl + C"     }}); }
      else if (item.title === "Paste"   && (!item.items)) { handlers.list.push({...item, ...{ handler: paste      , hotkey: "Ctrl + V"     }}); }
      else                                                { handlers.list.push({...item                                                     }); }

      if (item.items && item.items.length) {
        handlers.list[handlers.list.length - 1].actions = [];

        item.items.forEach((action) => {
          if      (action.title === "Limited") { handlers.list[handlers.list.length - 1].actions.push({...action, ...{ handler: limited, hotkey: ""             }}); }
          else if (action.title === "Shared" ) { handlers.list[handlers.list.length - 1].actions.push({...action, ...{ handler: shared , hotkey: ""             }}); }
          else if (action.title === "Child"  ) { handlers.list[handlers.list.length - 1].actions.push({...action, ...{ handler: create , hotkey: "Insert"       }}); }
          else if (action.title === "Sibling") { handlers.list[handlers.list.length - 1].actions.push({...action, ...{ handler: sibling, hotkey: "Ctrl + Enter" }}); }
          else                                 { handlers.list[handlers.list.length - 1].actions.push({...action                                                 }); }
        });
      }

      delete handlers.list[handlers.list.length - 1].items;
    });
  };

  function redirect() { if (props.item.code) window.location.href = `/node/${props.item.code}` };

  function show_remove() { popup.remove = true ; };
  function hide_remove() { popup.remove = false; };

  function zoom()    { emit("zoom"  , props.item.id           ); };
  function create()  { emit("create", props.item.id, "child"  ); };
  function sibling() { emit("create", props.item.id, "sibling"); };
  function cut()     { emit("clone" , props.item.id, "cut"    ); };
  function copy()    { emit("clone" , props.item.id, "copy"   ); };
  function paste()   { emit("paste" , props.item.id           ); };
  function finish()  { emit("finish", props.item.id           ); };
  function limited() { emit("access", props.item.id, 2        ); };
  function shared()  { emit("access", props.item.id, 3        ); };

  function remove(id) { emit("remove", id); hide_remove(); }
</script>

<template>
  <div
    class="card default p-relative d-flex border-radius-primary border-1 color-border-soft box-shadow-card"
    :class="{ 'selected': props.selected }"

    @click.exact.stop.prevent="emit('pick', props.item)"
    @click.ctrl.exact.stop.prevent="redirect"
    @click.shift.exact.stop.prevent="emit('switch_heading', props.item.id, props.region)"
  >
    <cards-partial-fold v-if="props.part.fold && props.item.folded.access"
      :status="props.item.folded.status"

      @handler="emit('fold', props.item.id)"
    />

    <div class="d-flex flex-column justify-content-between w-100 p-4">
      <div class="d-flex justify-content-between h-28px mb-4">
        <cards-partial-title
          class="w-222px"

          :edit="props.heading"
          :text="props.item.name || props.item.title"

          @handler="title => emit('update', 'title', props.item.id, title)"
          @switch="emit('switch_heading', props.item.id, props.region)"
        />

        <div class="d-flex flex-column">
          <cards-partial-status
            :branch="!!props.item.branch"
            :launch="!!(props.stages[0] && props.stages[0].state_id)"
          />

          <cards-partial-access
            :access="props.item.accesses_id"
          />
        </div>
      </div>

      <cards-partial-content v-if="props.part.content"
        :string="props.item.description"
      />

      <div class="d-flex justify-content-between h-24px">
        <div v-if="!!props.item.branch" class="d-flex flex-grow">
          <cards-partial-diagram-done v-if="props.part.diagram.done"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.done_show,
              'color-border-highlight-opacity-0': !props.done_show,
              'color-border-highlight-opacity-1': props.done_show
            }"

            :work="+props.item.work"
            :done="+props.item.done"
            :view="props.done_show"

            @switch_show="() => emit('switch_done_show', props.item.id, props.region)"
          />

          <cards-partial-diagram-time v-if="props.part.diagram.time"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.time_show,
              'color-border-highlight-opacity-0': !props.time_show,
              'color-border-highlight-opacity-1': props.time_show
            }"

            :plan="+props.item.complexities"
            :fact="+props.item.timing"
            :view="props.time_show"

            @switch_show="() => emit('switch_time_show', props.item.id, props.region)"
          />

          <cards-partial-diagram-work v-if="props.part.diagram.work"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.work_show,
              'color-border-highlight-opacity-0': !props.work_show,
              'color-border-highlight-opacity-1': props.work_show
            }"

            :idea="+props.item.idea"
            :work="+props.item.work"
            :view="props.work_show"

            @switch_show="() => emit('switch_work_show', props.item.id, props.region)"
          />
        </div>

        <div v-else class="d-flex flex-grow">
          <cards-partial-diagram-dead v-if="props.part.diagram.dead"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.dead_show,
              'color-border-highlight-opacity-0': !props.dead_show && !props.dead_edit,
              'color-border-highlight-opacity-1': props.dead_show || props.dead_edit
            }"

            :start="props.item.created"
            :deadline="props.item.deadline"
            :view="props.dead_show"
            :edit="props.dead_edit"

            @handler="value => emit('update', 'deadline', props.item.id, value)"
            @switch_show="() => emit('switch_dead_show', props.item.id, props.region)"
            @switch_edit="() => emit('switch_dead_edit', props.item.id, props.region)"
          />

          <cards-partial-diagram-time v-if="props.part.diagram.time"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.time_show,
              'color-border-highlight-opacity-0': !props.time_show && !props.time_edit,
              'color-border-highlight-opacity-1': props.time_show || props.time_edit
            }"

            :plan="+props.item.complexity"
            :fact="+props.item.timing"
            :view="props.time_show"
            :edit="props.time_edit"

            @handler="value => emit('update', 'complexity', props.item.id, value)"
            @switch_show="() => emit('switch_time_show', props.item.id, props.region)"
            @switch_edit="() => emit('switch_time_edit', props.item.id, props.region)"
          />

          <cards-partial-diagram-step v-if="props.part.diagram.step"
            class="transition-250 border-right-1 border-top-1 border-bottom-1 border-radius-12 mr-5"
            :class="{
              'flex-grow': props.step_show,
              'color-border-highlight-opacity-0': !props.step_show,
              'color-border-highlight-opacity-1': props.step_show
            }"

            :stages="props.stages"
            :view="props.step_show"

            @switch_show="() => emit('switch_step_show', props.item.id, props.region)"
          />
        </div>

        <cards-partial-code v-if="props.item.code || props.item.id"
          class="pl-8 align-items-end"
          :string="props.item.code || String(props.item.id)"
        />
      </div>
    </div>

    <cards-partial-action-menu v-if="props.part.actions.menu && props.menu"
      :active="props.context"
      :item="props.item"
      :actions="handlers.list"

      @switch="() => emit('switch_context', props.item.id, props.region)"
    />

    <cards-partial-action-launch v-if="props.part.actions.launch && props.chains"
      :active="props.process"
      :item="props.item"
      :chains="props.chains"

      @handler="(id) => emit('launch', { nodes_id: props.item.id, chains_id: id })"
      @switch="() => emit('switch_process', props.item.id, props.region)"
    />

    <cards-partial-action-default v-if="props.part.actions.create"
      :class="{'r-30px-imp': props.part.actions.launch}"

      icon="icon-plus"
      :item="props.item"

      @handler="(id) => emit('create', id)"
    />

    <cards-partial-action-default v-if="props.part.actions.finish"
      :class="{'r-30px-imp': props.part.actions.close}"

      icon="icon-finish"
      :item="props.item"

      @handler="(id) => emit('finish', id)"
    />

    <cards-partial-popup-remove v-if="popup.remove"
      :item="props.item"

      @disable="hide_remove"
      @handler="(id) => remove(id)"
    />
  </div>
</template>