<script setup>
  import { onMounted, defineProps } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();

  import LayoutWeb from "../../../layouts/Web.vue";

  import HeaderPartial      from "./partials/Header.vue";
  import ProgressPartial    from "./partials/Progress.vue";
  import TitlePartial       from "./partials/Title.vue";
  import DescriptionPartial from "./partials/Description.vue";
  import ExtensionPartial   from "./partials/Extension.vue";
  import SidebarPartial     from "./partials/Sidebar.vue";

  const props = defineProps({ code: { type: String, default: () => "" } });

  onMounted(async () => await source.load(props.code));
</script>

<template>
  <layout-web fullscreen>
    <header-partial v-if="source.node.id" />

    <div v-if="source.node.id" class="d-flex">
      <div class="d-flex flex-column w-65 w-md">
        <progress-partial />

        <title-partial />

        <description-partial />

        <extension-partial />
      </div>

      <div class="d-flex flex-column w-33 w-md p-sticky t-17px h-800px">
        <sidebar-partial />
      </div>
    </div>
  </layout-web>
</template>