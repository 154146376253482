import SERVER from "../../../system/server/server";

export default class Roster {
  static async load(keyword = "") {
    return await SERVER.get("planner?keyword=" + keyword);
  }

  static async states(project_id, option, value, keyword = "") {
    return await SERVER.post("planner/states" + "?keyword=" + keyword, { id: project_id, option: option, value: value });
  }

  static async priority(projects, keyword = "") {
    return await SERVER.post("planner/priority?keyword=" + keyword, { list: projects });
  }

  static async finish(id, keyword) {
    return await SERVER.post("planner/finish?keyword=" + keyword, { status_state_id: id });
  }

  static async move(data, keyword) {
    return await SERVER.post("planner/move?keyword=" + keyword, data);
  }
}