<script setup>
  import { ref, onMounted, nextTick, watch, defineProps, defineEmits } from "vue";

  import moment from "moment";

  import { DatePicker } from "v-calendar";

  const DATETIME_FORMAT = "YYYY-MM-DD";

  const props = defineProps({
    autofocus  : { type: Boolean, default: () => false        },
    picker     : { type: Boolean, default: () => false        },
    value      : { type: String , default: () => ""           },
    label      : { type: String , default: () => ""           },
    placeholder: { type: String , default: () => "YYYY-MM-DD" },
    description: { type: String , default: () => ""           }
  });

  const emit = defineEmits([
    "submit", "close"
  ]);

  const input = ref();
  const dateData = ref("");

  onMounted(async() => {
    await nextTick();
    if (props.autofocus) input.value.focus();
  });

  watch(() => dateData.value, (value) => { emit("submit", parse_datetime(value)); });

  let parse_datetime = (datetime) => {
    if (datetime) {
      datetime = moment(datetime, DATETIME_FORMAT);

      return datetime.isValid() ? datetime.format(DATETIME_FORMAT) : "";
    }

    return "";
  };
</script>

<template>
  <div class="field w-100">
    <div v-if="props.label" class="font-soft font-memo mb-8">
      {{ props.label }}
    </div>

    <div class="input p-relative">
      <input
        :ref="(element) => { input = element }"
        type="text"
        class="w-100"

        :value="parse_datetime(props.value)"
        :placeholder="props.placeholder"

        @keyup.stop
        @keydown.stop

        @keydown.enter.stop.prevent="emit('submit', parse_datetime($event.target.value));"
        @keydown.esc.stop.prevent="emit('close');"
      >

      <div v-if="props.picker"
        class="p-absolute t-30px l-0 z-10"
      >
        <date-picker
          v-model="dateData"
          locale="ru"
          mode="date"
          is-expanded
        />
      </div>
    </div>

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>
  </div>
</template>
