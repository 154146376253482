import SERVER from "../../../system/server/server";

export default class Roster {
  static async load() {
    return await SERVER.get("roadmap");
  }

  static async load_evm(project_id) {
    return await SERVER.get("roadmap/evm?project_id=" + project_id);
  }

  static async load_unused(project_id, id) {
    return await SERVER.get("roadmap/unused?project_id=" + project_id + "&root=" + id);
  }

  static async states(project_id, option, value) {
    return await SERVER.post("roadmap/states", { id: project_id, option: option, value: value });
  }

  static async priority(projects) {
    return await SERVER.post("roadmap/priority", { list: projects });
  }

  static async create_milestone(project_id, name) {
    return await SERVER.post("roadmap/create/milestone", { project_id: project_id, name: name });
  }

  static async shipped_milestone(id) {
    return await SERVER.get("roadmap/shipped/" + id);
  }

  static async remove_milestone(id) {
    return await SERVER.delete("roadmap/milestone/" + id);
  }

  static async update_milestone(id, option, value) {
    return await SERVER.put("roadmap/milestone/" + id, { option: option, value: value });
  }

  static async order_milestone(target, source, offset) {
    return await SERVER.post("roadmap/order/milestone", { target: target, source: source, offset: offset });
  }

  static async create_sprint(milestone_id) {
    return await SERVER.post("roadmap/create/sprint", { milestone_id: milestone_id });
  }

  static async break_sprint(milestone_id, value) {
    return await SERVER.post("roadmap/create/sprints", { milestone_id: milestone_id, value: value });
  }

   static async node_append(milestone_id, node_id) {
    return await SERVER.post("roadmap/node/append", { milestone_id: milestone_id, node_id: node_id });
  }

  static async node_move(milestone_id, node_id) {
    return await SERVER.post("roadmap/node/move", { target: milestone_id, source: node_id });
  }

  static async node_remove(id) {
    return await SERVER.delete("roadmap/node/" + id);
  }
}