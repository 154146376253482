export default {
  ru: {
    Roadmap              : "Дорожная карта",
    EVMCost              : "Отклонение по стоимости",
    EVMSchedule          : "Отклонение по срокам",
    break_sprints        : "Разбить на спринты",
    duration_sprint      : "Укажите длительность спринта в днях",
    create_or_choose_node: "Создать или выбрать этап",
    add_sprint           : "Добавить спринт",
    search_node          : "Поиск задачи",
  },
  en: {
    Roadmap              : "Roadmap",
    EVMCost              : "Cost",
    EVMSchedule          : "Schedule",
    break_sprints        : "Break it down into sprints",
    duration_sprint      : "Specify the duration of the sprint in days",
    create_or_choose_node: "Create or choose node",
    add_sprint           : "Add sprint",
    search_node          : "Search node",
  }
};