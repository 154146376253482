<template>
  <layout-flat>
    <div class="d-flex flex-column justify-content-center align-items-center h-100">
      <div class="icon logo-long my-20" />

      <widget-tabs
        class="mb-10"
        :tabs="tabs"
        @activate="activate"
      />

      <div
        v-if="messages.success"
        class="font-selection"
      >
        {{ messages.success }}
      </div>

      <div
        v-if="messages.failure"
        class="font-denial"
      >
        {{ messages.failure }}
      </div>

      <auth-login
        v-if="tabs[0].active"
        @login="login"
        @forgot="forgot"
      />

      <auth-registration
        v-else
        @registration="registration"
      />
    </div>
  </layout-flat>
</template>

<script>
  import { reactive } from "vue";

  import LayoutFlat       from "../../../layouts/Flat.vue";
  import WidgetTabs       from "../../../_visual/widgets/tabs/Tabs.vue";
  import AuthLogin        from "./auth/Login.vue";
  import AuthRegistration from "./auth/Registration.vue";

  import AuthService from "../services/auth.js";

  export default {
    components: {
      LayoutFlat,
      WidgetTabs,
      AuthLogin,
      AuthRegistration
    },

    setup() {
      const tabs = reactive([
        { id: 1, name: "Login"       , active: true  },
        { id: 2, name: "Registration", active: false }
      ]);

      const messages = reactive({
        failure: false,
        success: false
      });

      return {
        tabs,
        messages,

        login       : async (user) => messages.failure = await AuthService.login(user),
        registration: async (user) => messages.failure = await AuthService.registration(user),
        forgot      : async (user) => ({ success: messages.success, failure: messages.failure } = await AuthService.forgot(user)),

        activate: (tab)  => {
          messages.success = false;
          messages.failure = false;

          tabs.map(function(tab) { tab.active = false; });
          tab.active = tab.id;
        }
      };
    }
  };
</script>