<template>
  <layout-web>
    <div>
      <h1>{{ $t("prompt") }}</h1>

      <hr>

      <div class="d-flex flex-row">
        <widget-tabs
          class="flex-column"
          :tabs="tabs"
          @activate="activate"
        />

        <div class="w-100 px-20">
          <div v-if="tabs[0].active">
            <h2>{{ $t(tabs[0].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/3hEco9VwdT0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              <!-- <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rHaW5r-44uI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              /> -->
            </div>
            <hr>

            <div class="text-center">
              <img src="@/resources/images/flow.png">
            </div>
          </div>

          <div v-if="tabs[1].active">
            <h2>{{ $t(tabs[1].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/D5JZip0AQ3w"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              <!-- <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/w3oeYaxzvho"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              /> -->
            </div>
            <hr>

            <div class="text-center">
              <img src="@/resources/images/flow.png">
            </div>
          </div>

          <div v-if="tabs[2].active">
            <h2>{{ $t(tabs[2].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/uhYyEhzTofQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <hr>

            <p>В системе сформирована система планирования "Сегодня - Завтра - Неделя - Следующая неделя - Позднее".</p>
            <br>
            <p>Методика планирования:</p>
            <p>- задачи, которые намереваемся когда-либо исполнить, помещаем в группу "Позднее";</p>
            <p>- задачи, которые намереваемся выполнить на следующей неделе, перемещаем из группы "Позднее" в группу "Следующая неделя";</p>
            <p>- задачи, которые намереваемся выполнить на текущей неделе, перемещаем из группы "Следующая неделя" в группу "Неделя";</p>
            <p>- задачи, которые намереваемся выполнить завтра, перемещаем из группы "Неделя" в группу "Завтра";</p>
            <p>- задачи, которые намереваемся выполнить сегодня, перемещаем из группы "Завтра" в группу "Сегодня".</p>
            <br>
            <p>Задачи, которые запланированы на сегодня, завтра будут отображены в инструменте "Трекер" и становятся доступны к исполнению.</p>
          </div>

          <div v-if="tabs[3].active">
            <h2>{{ $t(tabs[3].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/uoErvoC8HHs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[4].active">
            <h2>{{ $t(tabs[4].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/uQ8lJEyNyn0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[5].active">
            <h2>{{ $t(tabs[5].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/_Hfdk1Jm9r4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[6].active">
            <h2>{{ $t(tabs[6].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/eDn0ZB1mlKo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[7].active">
            <h2>{{ $t(tabs[7].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/5E9lp7wvIHE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[8].active">
            <h2>{{ $t(tabs[8].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/trvDROzWRrE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[9].active">
            <h2>{{ $t(tabs[9].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Lx3ONY4-Jms"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>

          <div v-if="tabs[10].active">
            <h2>{{ $t(tabs[10].name) }}</h2>
            <hr>

            <div class="text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rNq9JpT84DY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-web>
</template>

<script>
  import { reactive  } from "vue";

  import LayoutWeb from "../../../layouts/Web.vue";

  import WidgetTabs from "../../../_visual/widgets/tabs/Tabs.vue";

  export default {
    components: {
      LayoutWeb,
      WidgetTabs,
    },

    setup() {
      const tabs = reactive([
        { id: 1 , name: "quick_start_simple"                    , active: true  },
        { id: 2 , name: "quick_start_complex"                   , active: false },
        { id: 3 , name: "planning"                              , active: false },
        { id: 4 , name: "creating_and_setting_up_a_new_project" , active: false },
        { id: 5 , name: "profile_setup"                         , active: false },
        { id: 6 , name: "create_and_setup_an_organization"      , active: false },
        { id: 7 , name: "promoting_a_task_through_a_new_process", active: false },
        { id: 8 , name: "structure"                             , active: false },
        { id: 9 , name: "Calendar"                              , active: false },
        { id: 10, name: "task_detail_page"                      , active: false },
        { id: 11, name: "Status"                                , active: false }
      ]);

      return { tabs,
        activate: (tab) => {
          tabs.map(function(tab) { tab.active = false; });
          tab.active = true;
        }
      };
    }
  };
</script>