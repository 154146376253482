<script setup>
  import { reactive, computed, defineProps } from "vue";

  import PartialUnit   from "./toolbar/Unit.vue";
  import PartialPrompt from "./toolbar/Prompt.vue";
  // import Setting from "./toolbar/Setting.vue";

  import Toolbar from "./Toolbar.js";

  const props = defineProps({
    items : { type: Array  , default: () => []    },
    expand: { type: Boolean, default: () => false }
  });

  const actions = computed(() => { return Toolbar.computed(props.items); });

  const popup = reactive({ setting: false, prompt: false });

  function redirect(url) {
    document.location.href = url;
  };

  function prompt() {
    popup.setting = false;
    popup.prompt  = !popup.prompt;
  };

  // let setting() {
  //   popup.prompt  = false;
  //   popup.setting = !popup.setting;
  // };

  // function reset_tutorial() {
  //   localStorage.removeItem("step");
  //   window.location.reload();
  // };

  // async function tutorial (id) {
  //   await Toolbar.tutorial(id);
  //   reset_tutorial();
  // };
</script>

<template>
  <div class="d-flex flex-column h-100 base-dark p-relative">
    <div
      class="d-flex c-pointer p-12 element"
      @click="redirect('/')"
    >
      <div class="icon logo-short c-pointer" />

      <div v-if="props.expand"
        class="font-header font-neutral text-uppercase lh-36 ml-16"
      >
        {{ $t("Lorida") }}
      </div>
    </div>

    <partial-unit
      v-for="action in actions"
      :key="action.name"
      :expand="expand"
      :action="action"
      @redirect="redirect"
    />

    <!-- <div class="flex-grow" /> -->

    <!-- <setting
      :expand="expand"
      :value="popup.setting"
      @handler="setting"
    /> -->

    <partial-prompt
      :expand="props.expand"
      :value="popup.prompt"

      @handler="prompt"
    />
  </div>
</template>