import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM  from "../system.js";
import SERVICE from "../services/Roster.js";

export const useSourceStore = defineStore("people_source", () => {
  const access = ref(false);
  const errors = ref({});
  const status = ref({});
  const spaces = ref([]);
  const users  = ref([]);

  const layout = ref([
    { "x": 0, "y": 0 , "w": 2, "h": 2, "i": "1" },
    { "x": 2, "y": 0 , "w": 2, "h": 2, "i": "2" },
    { "x": 4, "y": 0 , "w": 2, "h": 2, "i": "3" },

    { "x": 0, "y": 2 , "w": 4, "h": 3, "i": "4" },
    { "x": 4, "y": 2 , "w": 2, "h": 3, "i": "5" },

    { "x": 0, "y": 5 , "w": 2, "h": 4, "i": "6" },
    { "x": 2, "y": 5 , "w": 4, "h": 4, "i": "7" },
  ]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  const get_active = computed(() => {
    return spaces.value.find(space => space.active && space.hidden != 1);
  });

  const get_visible = computed(() => {
    return spaces.value.filter(space => space.hidden != 1);
  });

  function reload(response) {
    if (response.data) {
      access.value = response.access;
      errors.value = response.errors;
      status.value = response.status;
      spaces.value = response.data.spaces;
      users.value  = response.data.users;
    }
  };

  return {
    access,
    errors,
    status,
    spaces,
    users,
    layout,

    is_access,

    get_active,
    get_visible,

    load: async () => reload(await SERVICE.load()),

    user_pick: async (id) => reload(await SERVICE.states(get_active.value.id, "user_pick", id)),

    space_activate: async (id) => reload(await SERVICE.states(id, "space_active", id)),

    space_visible : async (id) => reload(await SERVICE.states(id, "space_show"  , id)),

    space_priority: async (event) => event.moved &&
      reload(await SERVICE.priority(get_visible.value.map(project => { return { id: project.id }; })))
  };
})