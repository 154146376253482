<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import Config from "../../../../config.js";

  import FieldInputCombo from "../../../../_visual/fields/input/combo/Combo.vue";

  async function onward() {
    let status_state = source.states.find((state) => state.chains_series_id == Config.SERIES_STATUS);
    source.onward(source.node.id, status_state.id);
    window.location.href = "/planner";
  };

  async function backward() {
    source.backward(source.node.id);
    window.location.href = "/planner";
  };
</script>

<template>
  <div class="d-flex justify-content-between base-neutral border-radius-16 mx-27 mb-16 px-27 py-18">
    <div class="d-flex flex-column flex-grow">
      <div class="font-memo py-5">
        #{{ source.node.code }}
      </div>

      <field-input-combo
        :value="source.node.title"
        :placeholder="$t('please_insert_title')"

        @submit="(value) => source.update(source.node.id, 'title', value)"
      />
    </div>

    <div class="d-flex flex-wrap">
      <div v-if="source.actions.remove"
        class="button cancel mx-5 mb-5"

        @click="source.remove(source.node.id)"
      >
        {{ $t("remove") }}
      </div>

      <div v-if="source.actions.backward"
        class="button cancel mx-5 mb-5"

        @click="backward"
      >
        {{ $t("backward") }}
      </div>

      <div v-if="source.actions.onward"
        class="button primary mx-5 mb-5"

        @click="onward"
      >
        {{ $t("done") }}
      </div>

      <div v-if="source.actions.onward"
        class="button cancel mx-5 mb-5"

        @click="source.finish(source.node.id)"
      >
        {{ $t("abort") }}
      </div>
    </div>
  </div>
</template>