export default {
  ru: {
    Expenses              : "Затраты",
    Invoice               : "Счёт",
    insert_user           : "Укажите пользователя",
    spent                 : "Затрачено",
    total_spent           : "Всего затраченно",
    hourly_cost           : "Укажите стоимость часа",
    calculation_start_date: "Дата начала расчета",
    calculation_end_date  : "Дата конца расчета",
    create_an_invoice     : "Сформировать счёт",
    export_to_pdf         : "Экспорт в PDF",
    cost                  : "Стоимость",
    total_cost            : "Общая стоимость",
    choose_project        : "Выберите проект",
    select_date_start     : "Выберите дату начала расчета",
    select_date_finish    : "Выберите дату конца расчета",
    invalid_date_finish   : "Укажите дату не раньше даты начала расчета"
  },
  en: {
    Expenses              : "Expenses",
    Invoice               : "Invoice",
    insert_user           : "Insert user",
    spent                 : "Spent",
    total_spent           : "Total spent",
    hourly_cost           : "Enter cost per hour",
    calculation_start_date: "Calculation start date",
    calculation_end_date  : "Calculation end date",
    create_an_invoice     : "Create an invoice",
    export_to_pdf         : "Export to PDF",
    cost                  : "Cost",
    total_cost            : "Total cost",
    choose_project        : "Choose project",
    select_date_start     : "Select the start date of the calculation",
    select_date_finish    : "Select the finish date of the calculation",
    invalid_date_finish   : "Specify a date no earlier than the start date of the calculation"
  }
};