import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM  from "../system.js";
import SERVICE from "../services/Roster.js";

export const useSourceStore = defineStore("planner_source", () => {
  const access   = ref(false);
  const errors   = ref({});
  const status   = ref({});
  const projects = ref([]);
  const periods  = ref([]);
  const nodes    = ref({});
  const stage    = ref([]);
  const paths    = ref([]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  const get_active = computed(() => {
    return projects.value.find(project => project.active && project.hidden != 1);
  });

  const get_visible = computed(() => {
    return projects.value.filter(project => project.hidden != 1);
  });

  const get_backlog = computed(() => {
    return nodes.value.filter(node => { return !node.periods_id && !node.worker_users_id; });
  });

  const get_assigne = computed(() => {
    return nodes.value.filter(node => { return !node.periods_id && node.worker_users_id; });
  });

  function get_periods(id) {
    return nodes.value.filter(node => { return node.periods_id == id; });
  };

  function reload(response) {
    if (response.data) {
      access.value   = response.access;
      errors.value   = response.errors;
      status.value   = response.status;
      projects.value = response.data.projects;
      periods.value  = response.data.periods;
      nodes.value    = response.data.nodes;
      stage.value    = response.data.stage;
      paths.value    = response.data.paths;
    }
  };

  return {
    access,
    errors,
    status,
    projects,
    periods,
    nodes,
    stage,
    paths,

    is_access,

    get_active,
    get_visible,
    get_assigne,
    get_backlog,
    get_periods,

    load: async (keyword = "") => reload(await SERVICE.load(keyword)),

    node_finish: async (id, keyword) => reload(await SERVICE.finish(id, keyword)),

    node_move: async (data, keyword) => reload(await SERVICE.move(data, keyword)),

    project_activate: async (id, keyword) => reload(await SERVICE.states(id, "project_active", id, keyword)),

    project_visible : async (id, keyword) => reload(await SERVICE.states(id, "project_show"  , id, keyword)),

    project_priority: async (event, keyword) => event.moved &&
      reload(await SERVICE.priority(get_visible.value.map(project => { return { id: project.id }; }), keyword))
  };
})