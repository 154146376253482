<script setup>
  import { onMounted, watch, defineProps } from "vue";

  import { useSourceStore } from "./stores/SourceStore.js";
  const source = useSourceStore();

  import Config from "../../../config.js";
  import Server from "../../../system/server/server.js";

  const props = defineProps({
    label      : { type: String, default: () => "" },
    nodes_id   : { type: Number, default: () => 0  },
    description: { type: String, default: () => "" }
  });

  onMounted(async () => await source.load(props.nodes_id));

  watch(async () => props.nodes_id, (selection) => {
    selection.then(async (id) => await source.load(id));
  });

  function dragover(event) {
    event.target.closest(".input").classList.add("color-border-highlight");
    event.target.closest(".input").classList.remove("color-border-option");
  };

  function dragleave(event) {
    event.target.closest(".input").classList.add("color-border-option");
    event.target.closest(".input").classList.remove("color-border-highlight");
  };

  async function upload(event, drop = false) {
    let data = new FormData();
    data.append("nodes_id", props.nodes_id);

    let files = drop ? event.dataTransfer.files : event.target.files;

    for (let i = 0; i < files.length; i++) {
      data.append("files[]", files[i]);
    }

    if (files.length > 0)
      source.upload(data);

    dragleave(event);
    event.target.value = "";
  };

  function download(id) {
    return Config.API + '/api/files/download?id=' + id + '&token=' + Server.token();
  };
</script>

<template>
  <div class="widget d-flex flex-column">
    <div v-if="props.label" class="font-soft font-memo mb-8">
      {{ props.label }}
    </div>

    <div class="input d-flex justify-content-center border-1-dashed color-border-option border-radius-4">
      <label
        class="d-flex c-pointer p-relative text-center py-16 font-light"

        @dragenter.prevent
        @dragover.prevent="dragover"
        @dragleave.prevent="dragleave"
        @drop.prevent="upload($event, true)"
      >
        {{ $t('Select_or_drag_and_drop_file') }}

        <input
          class="p-absolute d-none"
          type="file"
          multiple

          @change="upload"
        >
      </label>
    </div>

    <div class="border-1 border-radius-5 color-border-light">
      <div v-for="file in source.files"
        :key="file.id"
        class="p-5"
      >
        <div class="file d-flex justify-content-between w-100">
          <a
            class="font-dark text-decoration-none c-pointer"
            target="_blank"
            :href="download(file.id)"
            :download="file.name"
          >
            {{ file.name }}
          </a>

          <div
            class="icon icon-remove icon-hover-dark c-pointer"
            @click="source.delete(file.id)"
          />
        </div>
      </div>
    </div>

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>
  </div>
</template>
