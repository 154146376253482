<script setup>
  import { onBeforeMount, onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "./stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "./stores/StateStore.js";
  const state = useStateStore();

  import Mediator from "../../../system/mediator/Mediator";
  import { Hotkeys, Action } from "../../../packages/hotkeys/Hotkeys";

  import PartialItem from "./partials/Item.vue";
  import PartialNode from "./partials/Node.vue";

  const REGION_TRACKER = "tracker";

  onBeforeMount(() => Hotkeys.reinit(REGION_TRACKER));

  onMounted(async () => {
    await source.list();
    Mediator.define("tracker:reload", async () => await source.list());

    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_P, () => tracking(), { shift: true }),

      new Action(Hotkeys.KEYCODE_T  , () => state.$patch({ list: !state.list }), { shift: true }),
      new Action(Hotkeys.KEYCODE_ESC, () => state.$patch({ list: false }))
    ], REGION_TRACKER);
  });

  onUnmounted(() => Hotkeys.reinit(REGION_TRACKER));

  async function activate(item) {
    state.$patch({ list: false });
    source.activate(source.assignee.find(node => node.id == item.id).status_states_id);
  };

  async function tracking() {
    if (source.active && source.active.id !== undefined) {
      if (source.follow) {
        await source.finish(source.active.role_states_id);
      }
      else {
        await source.launch(source.active.role_states_id);
      }
    }
  };
</script>

<template>
  <div class="tracker d-flex p-relative w-100 h-100 align-items-center">
    <div class="content d-flex w-100" :class="{ 'pause': source.follow }">
      <div v-if="source.follow"
        class="d-flex align-self-center launch icon icon-pause c-pointer"

        @click="tracking()"
      />

      <div v-else
        class="d-flex align-self-center launch icon icon-play c-pointer"

        @click="tracking()"
      />

      <partial-node :node="source.node" />

      <div
        class="d-flex align-self-center toogle icon icon-menu-list c-pointer"

        @click="state.$patch({ list: !state.list })"
      />
    </div>

    <div
      class="details p-absolute"
      :class="{'visible': state.list}"
    >
      <div class="list box-shadow-bottom overflow-y-scroll max-h-500px scrollbar">
        <div class="font-memo font-soft">
          {{ $t('Tasks_available_today_tomorrow') }}
        </div>

        <partial-item v-for="node in source.pull"
          :key="node.id"
          :item="node"

          @click="activate(node)"
        />
      </div>
    </div>
  </div>
</template>