<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    branch: { type: Boolean, default: () => false },
    launch: { type: Boolean, default: () => false }
  });
</script>

<template>
  <div
    class="icon small"
    :class="{
      'icon-work': !props.branch && props.launch,
      'icon-idea': !props.branch && !props.launch,
      'icon-folder': props.branch,
    }"
  />
</template>