import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(spaces_id) {
    return new Entity(await Server.get("modules/" + spaces_id));
  }

  static async update(spaces_id, module) {
    return new Entity(await Server.put("modules/" + spaces_id, module));
  }
}