
<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    icon: { type: String, default: () => "" },
    name: { type: String, default: () => "" },
    item: { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "handler"
  ]);

  function icons() {
    return props.item.status ? "icon icon-" + props.icon + "-hover" : "icon icon-" + props.icon;
  };
</script>

<template>
  <div class="p-relative">
    <div
      class="d-flex p-relative justify-content-between align-items-center border-radius-12 box-shadow-bottom px-8 h-32px c-pointer"
      :class="{
        'base-neutral': !props.item.status,
        'base-highlight': props.item.status
      }"
      @click.stop="emit('handler', props.item.id, !props.item.status)"
    >
      <div :class="icons()" />

      <div class="ml-8" :class="{ 'font-neutral': props.item.status }">
        {{ $t(props.name) }}
      </div>
    </div>
  </div>
</template>