<script setup>
  import { defineAsyncComponent } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  const StreamPlugin = defineAsyncComponent(() => import("../../../stream/plugins/compact/Projects.vue"));
  const NodePlugin   = defineAsyncComponent(() => import("../../../node/plugins/compact/Projects.vue"));
  const CostPlugin   = defineAsyncComponent(() => import("../../../roadmap/plugins/compact/Cost.vue"));
  const TimePlugin   = defineAsyncComponent(() => import("../../../roadmap/plugins/compact/Time.vue"));
  const TeamPlugin   = defineAsyncComponent(() => import("../../../team/plugins/compact/Time.vue"));
  const RejectPlugin = defineAsyncComponent(() => import("../../../chains/plugins/compact/Projects.vue"));

  const wrap = "d-flex flex-column h-100 overflow-hidden";
  const head = "d-flex justify-content-between m-4 font-soft font-section";
  const icon = "icon little icon-arrow-next-1 c-pointer";
</script>

<template>
  <div class="d-flex w-100 overflow-y-scroll scrollbar-disable">
    <GridLayout v-model:layout="source.layout"
      class="w-100"
      :col-num="8"
      :row-height="100"
      :margin="[10, 10]"
      :is-draggable="false"
      :is-resizable="false"
      :is-mirrored="false"
      :vertical-compact="true"
      :use-css-transforms="true"
    >
      <GridItem v-for="item in source.layout"
        :key="item.i"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :static="true"
      >
        <div v-if="item.i == 1" :class="wrap">
          <div :class="head">
            <div>{{ $t("Tasks") }}</div>
            <div :class="icon" @click="state.$patch({ select: 'node' })" />
          </div>
          <node-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'node' })" />
        </div>

        <div v-if="item.i == 2" :class="wrap">
          <div :class="head">
            <div>{{ $t("News") }}</div>
            <div :class="icon" @click="state.$patch({ select: 'stream' })" />
          </div>
          <stream-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'stream' })" />
        </div>

        <div v-if="item.i == 3" :class="wrap">
          <div :class="head">
            <div>{{ $t("EVMCost") }}</div>
            <div :class="icon" @click="state.$patch({ select: 'cost' })" />
          </div>
          <cost-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'cost' })" />
        </div>

        <div v-if="item.i == 4" :class="wrap">
          <div :class="head">
            <div>{{ $t("EVMSchedule") }}</div>
            <div :class="icon" @click="state.$patch({ select: 'time' })" />
          </div>
          <time-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'time' })" />
        </div>

        <div v-if="item.i == 5" :class="wrap">
          <div :class="head">
            <div>{{ $t("Employees") }}</div>
            <div :class="icon" @click="state.$patch({ select: 'team' })" />
          </div>
          <team-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'team' })" />
        </div>

        <div v-if="item.i == 6" :class="wrap">
          <div :class="head">
            <div>{{ "Очередь на тесты" }}</div>
            <div :class="icon" @click="state.$patch({ select: 'test' })" />
          </div>
          <!-- <test-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'test' })" /> -->
          <reject-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'test' })" />
        </div>

        <div v-if="item.i == 7" :class="wrap">
          <div :class="head">
            <div>{{ "Очередь на MR" }}</div>
            <div :class="icon" @click="state.$patch({ select: 'mr' })" />
          </div>
          <!-- <mr-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'mr' })" /> -->
          <reject-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'mr' })" />
        </div>

        <div v-if="item.i == 8" :class="wrap">
          <div :class="head">
            <div>{{ "Документы" }}</div>
            <div :class="icon" @click="state.$patch({ select: 'docs' })" />
          </div>
          <!-- <docs-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'docs' })" /> -->
          <cost-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'docs' })" />
        </div>

        <div v-if="item.i == 9" :class="wrap">
          <div :class="head">
            <div>{{ "Инциденты" }}</div>
            <div :class="icon" @click="state.$patch({ select: 'incidents' })" />
          </div>
          <!-- <incidents-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'incidents' })" /> -->
          <stream-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'incidents' })" />
        </div>

        <div v-if="item.i == 10" :class="wrap">
          <div :class="head">
            <div>{{ "Баговость" }}</div>
            <div :class="icon" @click="state.$patch({ select: 'bugs' })" />
          </div>
          <!-- <bugs-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'bugs' })" /> -->
          <reject-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'bugs' })" />
        </div>

        <div v-if="item.i == 11" :class="wrap">
          <div :class="head">
            <div>{{ $t("Rejected") }}</div>
            <div :class="icon" @click="state.$patch({ select: 'reject' })" />
          </div>
          <reject-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'reject' })" />
        </div>

        <div v-if="item.i == 12" :class="wrap">
          <div :class="head">
            <div>{{ "Оплата" }}</div>
            <div :class="icon" @click="state.$patch({ select: 'cash' })" />
          </div>
          <!-- <cash-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'cash' })" /> -->
          <time-plugin :projects_id="source.get_active.id" @click="state.$patch({ select: 'cash' })" />
        </div>
      </GridItem>
    </GridLayout>
  </div>
</template>

<style scoped>
  .vue-grid-layout {
    background: #eee;
  }
  .vue-grid-item:not(.vue-grid-placeholder) {
    background: #fff;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
  }
  .vue-grid-item .resizing {
    opacity: 0.9;
  }
  .vue-grid-item .static {
    background: #cce;
  }
  .vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  .vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
  }
  .vue-grid-item .minMax {
    font-size: 12px;
  }
  .vue-grid-item .add {
    cursor: pointer;
  }
  .vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
      no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
  }
</style>