<script setup>
  import { defineProps, defineEmits } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import Mediator from "../../../../system/mediator/Mediator";

  import WidgetVertical from "../../../../visual/widgets/regions/Vertical.vue";
  import WidgetCard     from "../../../../visual/widgets/cards/Default.vue";

  const REGION_MAIN = "main";

  const props = defineProps({
    nodes : { type: Array , default: () => [] },
    period: { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "pick", "finish"
  ]);

  const menu = () => {
    return [
      { title: "Details", separator: true  },
      { title: "Finish" , separator: false }
    ];
  };

  const part = () => { return {
    fold   : false,
    content: true,
    weight : false,
    actions: { menu: true, launch: false, create: false, finish: true },
    diagram: { done: false, work: false, time: true, dead: true, step: true }
  }; };

  function dragstart(event, period_id, node) {
    event.dataTransfer.setData("period_id", period_id);
    event.dataTransfer.setData("role_state_id", node.role_states_id);
    event.dataTransfer.setData("status_state_id", node.status_states_id);
  };

  async function dragdrop(event, period_id, node, priority) {
    if (event.dataTransfer.getData("period_id")) {
      await source.node_move({
        target         : period_id,
        source         : event.dataTransfer.getData("period_id"),
        role_state_id  : event.dataTransfer.getData("role_state_id"),
        status_state_id: event.dataTransfer.getData("status_state_id"),
        priority       : node ? node.priority + priority : priority
      }, state.search);

      Mediator.handle("tracker:reload");
    }
  };
</script>

<template>
  <div class="h-100 overflow-y-hidden">
    <div class="d-flex base-neutral justify-content-between border-radius-8 p-8">
      {{ $t(props.period.name) }}
    </div>

    <div
      class="overflow-y-scroll scrollbar-disable h-100--40px mt-8 p-4"

      @dragover.prevent
      @dragenter.prevent
      @dragleave.prevent
      @drop="dragdrop($event, props.period.id, false, 0)"
    >
      <widget-vertical
        :array="props.nodes"
        :branch="false"

        @begin="(event, node) => dragstart(event, props.period.id, node)"
        @change="(event, node, offset) => dragdrop(event, props.period.id, node, offset)"
      >
        <template #item="element">
          <widget-card
            :data-role-state-id="element.item.role_states_id"
            :data-status-state-id="element.item.status_states_id"

            class="planner-item my-4 base-neutral h-100 w-100"

            :item="element.item"
            :menu="menu()"
            :part="part()"
            :stages="source.stage[element.item.id]"

            :region="REGION_MAIN"

            :context="state.get_state(element.item.id, REGION_MAIN, 'context')"
            :dead_show="state.get_state(element.item.id, REGION_MAIN, 'dead_show')"
            :step_show="state.get_state(element.item.id, REGION_MAIN, 'step_show')"
            :time_show="state.get_state(element.item.id, REGION_MAIN, 'time_show')"

            @switch_context="state.switch_context"
            @switch_dead_show="state.switch_dead_show"
            @switch_step_show="state.switch_step_show"
            @switch_time_show="state.switch_time_show"

            @pick="(node) => emit('pick', node)"
            @finish="(data) => emit('finish', data)"

            @dragover.prevent
            @dragenter.prevent
            @dragleave.prevent
          />
            <!-- @drop="dragdrop($event, props.period.id, element.item, 0)" -->
        </template>
      </widget-vertical>
    </div>
  </div>
</template>