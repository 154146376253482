import { ref } from "vue";
import { defineStore } from "pinia";

import SERVICE from "../services/File.js";

export const useSourceStore = defineStore("file_source", () => {
  const files = ref([]);

  function reload(response) {
    if (response.data) {
      files.value = response.data.files;
    }
  };

  return {
    files,

    load: async (id) => reload(await SERVICE.load(id)),

    upload: async (data) => reload(await SERVICE.upload(data)),

    delete: async (id) => reload(await SERVICE.delete(id))
  }
})