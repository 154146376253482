import SERVER from "../../../system/server/server";

export default class Detail {
  static async load(offset, keyword = "") {
    return await SERVER.get("stream" + "?offset=" + offset + "&keyword=" + keyword);
  }

  static async load_by_projects(projects_id, offset, keyword = "") {
    return await SERVER.get("stream/projects" + "?projects_id=" + projects_id + "&offset=" + offset + "&keyword=" + keyword);
  }

  static async load_by_users(users_id, offset, keyword = "") {
    return await SERVER.get("stream/users" + "?users_id=" + users_id + "&offset=" + offset + "&keyword=" + keyword);
  }

  static async states(project_id, option, value, offset, keyword = "") {
    return await SERVER.post("stream/states" + "?offset=" + offset + "&keyword=" + keyword, { id: project_id, option: option, value: value });
  }

  static async priority(projects, offset, keyword = "") {
    return await SERVER.post("stream/priority" + "?offset=" + offset + "&keyword=" + keyword, { list: projects });
  }
}