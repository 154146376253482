<script setup>
  import { ref, watchEffect, onMounted, defineProps, defineEmits } from "vue";

  import Default from "./Default.vue";

  const props = defineProps({
    view  : { type: Boolean, default: () => false },
    stages: { type: Array  , default: () => []    }
  });

  const emit = defineEmits([
    "switch_show"
  ]);

  const percent = ref(0);

  onMounted(() => recount(props.stages));
  watchEffect(() => recount(props.stages));

  function recount(stages) {
    if (stages.length) {
      percent.value = Math.ceil(complete(stages) / stages.length * 100);
    }
  };

  function complete(stages) {
    let value = 0;

    stages.length && stages.forEach(stage => {
      value += stage.state_status == 'history' ? 1 : 0;
    });

    return value;
  };

  function total(stages) {
    let value = 0;

    if (stages.length && (stages[0].state_status == 'current' || stages[0].state_status == 'history')) {
      value = stages.length;
    }

    return value;
  };
</script>

<template>
  <div class="d-flex" @click.exact.stop.prevent="emit('switch_show')">
    <default entity="completed" :percent="percent" :defined="!!props.stages.length">
      <template #content>
        <div v-if="props.view"
          class="d-flex justify-content-center align-items-center ml-2 mr-4"
        >
          <div class="font-section">
            {{ complete(props.stages) }}
          </div>
          /
          <div class="font-soft">
            {{ total(props.stages) }}
          </div>
        </div>
      </template>
    </default>
  </div>
</template>