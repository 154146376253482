<script setup>
  import { reactive, defineProps, defineEmits } from "vue";

  const props = defineProps({
    array: { type: Array, default: () => [] }
  });

  const emit = defineEmits([
    "change"
  ]);

  const location = reactive({ items: [], target: 0 });

  function location_none() { location.items = []; };

  function location_before(id) { location_none(); location.items[id] = "before"; };
  function location_inside(id) { location_none(); location.items[id] = "inside"; };
  function location_after (id) { location_none(); location.items[id] = "after" ; };

  function dragstart(event, id) {
    location.target = id;

    event.dataTransfer.dropEffect    = "move";
    event.dataTransfer.effectAllowed = "move";

    event.target.style.opacity = "0.5";
  };

  function drop(source, offset) {
    if (location.target) {
      emit("change", location.target, source, offset);
    }
  };

  function dragend() {
    location_none();

    document
      .querySelector(".widgets-regions-hierarchy [data-id='"  + location.target + "']")
      .closest(".p-relative")
      .style.opacity = "1"
    ;

    location.target = 0;
  };
</script>

<template>
  <div class="widgets-regions-hierarchy">
    <div
      v-for="(item, index) in props.array"
      :key="item.entity.id"
      class="p-relative"

      :data-id="item.entity.id"
      :data-branch="item.entity.branch"
      :data-index="index + 1"

      draggable="true"
      @dragenter.prevent
      @dragleave.prevent
      @dragover.prevent
      @dragend="dragend"
      @dragstart="dragstart($event, item.entity.id)"

      @click.exact.prevent
      @click.ctrl.exact.prevent
    >
      <div v-if="location.target && location.target != item.entity.id" class="p-absolute w-100 h-100 z-10">
        <div v-if="item.entity.id"
          class="w-100 h-33"
          @dragenter.prevent
          @dragleave.prevent
          @dragover="location_before(item.entity.id)"
          @drop.stop="drop(item.entity.id, -0.5)"
        />

        <div
          class="w-100"
          :class="{'h-33': item.entity.id, 'h-100': !item.entity.id }"
          @dragenter.prevent
          @dragleave.prevent
          @dragover="location_inside(item.entity.id)"
          @drop.stop="drop(item.entity.id, 0)"
        />

        <div v-if="item.entity.id"
          class="w-100 h-33"
          @dragenter.prevent
          @dragleave.prevent
          @dragover="location_after(item.entity.id)"
          @drop.stop="drop(item.entity.id, 0.5)"
        />
      </div>

      <div class="d-flex p-absolute w-100 h-1px">
        <div v-for="n in item.option.level + 1" :key="n" class="w-34px max-w-34px min-w-34px border-left-3 border-highlight-slave" />
        <div class="w-100" :class="{'border-top-1-dashed': location.items[item.entity.id] && location.items[item.entity.id] == 'before'}" />
      </div>

      <slot name="item" :item="item" :inside="location.items[item.entity.id] && location.items[item.entity.id] == 'inside'" />

      <div class="d-flex p-absolute w-100 h-1px">
        <div v-for="n in item.option.level + 1" :key="n" class="w-34px max-w-34px min-w-34px border-left-3 border-highlight-slave" />
        <div class="w-100" :class="{'border-top-1-dashed': location.items[item.entity.id] && location.items[item.entity.id] == 'after'}" />
      </div>
    </div>
  </div>
</template>