<template>
  <div>
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <widget-tabs
        :tabs="tabs"
        @activate="activate"
      />
    </div>

    <hr>
  </div>
</template>

<script>
  import { reactive  } from "vue";
  import { useRouter } from "vue-router";

  import WidgetTabs from "../../../../_visual/widgets/tabs/Tabs.vue";

  export default {
    components: {
      WidgetTabs
    },

    props: {
      id    : { type: Number, default: () => 0 },
      active: { type: Number, default: () => 0  }
    },


    setup(props) {
      const router = useRouter();

      const tabs = reactive([
        { id: 1, name: "Progress", active: false, url: "progress" },
        { id: 2, name: "Tracker" , active: false, url: "tracker"  },
        { id: 3, name: "Captures", active: false, url: "captures" },
        { id: 4, name: "Excuses" , active: false                  }
      ]);
      tabs[props.active].active = true;

      return {
        tabs,

        activate: (tab)  => {
          tabs.map(function(tab) { tab.active = false; });
          tab.active = tab.id;

          let path = tab.url &&
            "/excuses/" + tab.url ||
            "/excuses";

          router.push({ path: path });
        }
      };
    }
  };
</script>