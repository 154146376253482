<script setup>
  // import { defineAsyncComponent } from "vue";

  // import { useSourceStore } from "../../stores/SourceStore.js";
  // const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  // <div>{{ $t("Tasks") }}</div>
  // <div :class="icon" @click="state.$patch({ select: 'node' })" />
  // <div>{{ $t("News") }}</div>
  // <div :class="icon" @click="state.$patch({ select: 'stream' })" />
</script>

<template>
  <div class="d-flex w-100">
    <div @click="state.$patch({ select: '' })">Dashboard</div>

    <div v-if="state.select">
      <div>></div>
      <div>{{ state.select }}</div>
    </div>
  </div>
</template>