<script setup>
  import { ref, watch, onMounted, defineProps } from "vue";

  const props = defineProps({
    node: { type: Object , default: () => {} }
  });

  const options = ref({});

  onMounted(() => refresh(props.node));
  watch([ () => props.node ], async ([node]) => refresh(node));

  function refresh(node) {
    options.value = node.option.style && JSON.parse(node.option.style);
  }

  function design(full = false) {
    let styles = { color: options.value && options.value.color };

    if (full && options.value) {
      styles.fontSize       = options.value.size + 'px';
      styles.textDecoration = options.value.textDecoration;
      styles.fontStyle      = options.value.fontStyle;
      styles.fontWeight     = options.value.fontWeight;
    }

    return styles;
  }
</script>

<template>
  <div class="content">
    <div class="d-flex w-100">
      <div v-if="options && options.icon" class="d-flex ml-5">
        <div v-for="icon in options.icon" :key="icon">
          <div class="icon" :class="'picker-' + icon" />
        </div>
      </div>

      <div class="id text-nowrap ml-5" :style="design(false)">
        #{{ props.node.entity.code || props.node.entity.id }}
      </div>

      <div class="text" :style="design(true)">
        {{ props.node.entity.title || props.node.entity.name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .content {
    display: flex;
    align-items: center;
    position: relative;

    & .icons {
      background-repeat: no-repeat;
      width: 0;
      height: 17px;
    }

    & .id {
      display: flex;
      border: 2px solid transparent;
      border-radius: 4px;
    }

    & .text {
      display: flex;
      border: 2px solid transparent;
      border-radius: 4px;
      padding-right: 8px;
      padding-left: 8px;
    }
  }
</style>