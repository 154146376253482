<template>
  <layout-web fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.spaces"
          @activate="space_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <widget-filter
            class="ml-8"
            :projects="source.spaces"
            @projects_handler="space_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :background="false">
      <template #content>
        <!-- <div class="d-flex align-items-center flex-wrap base-neutral border-radius-8 mb-16 p-8">
          <widget-tabs
            :tabs="tabs"
            @activate="activate"
          />
        </div> -->

        <!--Expenses tab -->
        <!-- <div v-if="tabs[0].active"
          class="base-neutral"
        > -->
        <div class="base-neutral">
          <partial-expenses
            :users="source.users"
            :list="source.list"
            @update="change_user"
          />
        </div>

        <!--Invoice tab -->
        <!-- <div v-if="tabs[1].active"
          class="base-neutral"
        >
          <partial-invoice
            :date_start="fields.date_start"
            :date_finish="fields.date_finish"
            :price="fields.price"
            :show_invoice="fields.show_invoice"
            :errors="fields.errors"
            :invoice_list="source.invoice_list"
            :total_cost="source.total_cost"

            @update:date_start="change_date_start"
            @update:date_finish="change_date_finish"
            @update:price="change_price"
            @create_invoice="create_invoice"
          />
        </div> -->
      </template>
    </partial-content>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import moment from "moment";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PartialFilter   from "../../../visual/partials/Filter.vue";
  import PartialContent  from "../../../visual/partials/Content.vue";
  // import PartialInvoice  from "../../../app/expenses/screens/partials/Invoice.vue";
  import PartialExpenses from "../../../app/expenses/screens/partials/Expenses.vue";

  // import WidgetTabs     from "../../../_visual/widgets/tabs/Tabs.vue";
  import WidgetFilter   from "../../../_visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../_visual/widgets/projects/Projects.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      // WidgetTabs,
      PartialFilter,
      PartialContent,
      WidgetFilter,
      WidgetProjects,
      // PartialInvoice,
      PartialExpenses
    },

    setup() {
      const source = reactive(new RosterEntity);

      const filter = reactive({ projects: [], active: {} });

      const fields = reactive({
        project_id  : "",
        user_id     : "",
        date_start  : moment().subtract(7, "days").format("YYYY-MM-DD"),
        date_finish : moment().format("YYYY-MM-DD"),
        price       : "",
        show_invoice: false,
        errors      : {}
      });

      // const tabs = reactive([
      //   { id: 1, name: "Expenses", active: true  },
      //   { id: 2, name: "Invoice" , active: false }
      // ]);

      onMounted(async () => {
        Object.assign(source, await RosterService.load(fields));
        refresh();
      });

      let refresh = () => {
        filter.spaces = source.spaces.filter(item => item.hidden != 1);
        filter.active = filter.spaces.find(item => item.hidden != 1 && item.active);
      };

      // let activate = (tab) => {
      //   tabs.map((tab) => tab.active = false);
      //   tab.active = tab.id;
      // };

      let change_date_finish = (datetime) => {
        fields.date_finish                   = datetime;
        fields.show_invoice                  = false;
        fields.errors["date_finish"]         = false;
        fields.errors["invalid_date_finish"] = false;
        if(fields.date_finish<fields.date_start) {
          fields.errors["invalid_date_finish"] = true;
        }
      };

      let change_date_start = (datetime) => {
        fields.date_start           = datetime;
        fields.show_invoice         = false;
        fields.errors["date_start"] = false;
      };

      let change_price = (value) => {
        fields.price           = value;
        fields.show_invoice    = false;
        fields.errors["price"] = false;
      };

      return {
        source,
        filter,
        fields,
        // tabs,

        // activate,
        change_date_finish,
        change_date_start,
        change_price,

        space_active: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "space_active", project.id));
          refresh();
          fields.show_invoice = false;
        },

        space_show: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "space_show", project.status));
          refresh();
        },

        priority: async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.spaces.map(space => { return { id: space.id }; })));
            refresh();
          }
        },

        change_user: async (value) => {
          fields.user = value;
          Object.assign(source, await RosterService.load(fields));
        },

        create_invoice: async () => {
          if(fields.date_start && fields.date_finish && fields.price && fields.date_finish>=fields.date_start) {
            Object.assign(source, await RosterService.load(fields));
            fields.show_invoice = true;
          } else {
            if (!fields.date_start) {
              fields.errors["date_start"] = true;
            }
            if (!fields.date_finish) {
              fields.errors["date_finish"] = true;
            }
            if (!fields.price) {
              fields.errors["price"] = true;
            }
          }
        }
      };
    }
  };
</script>