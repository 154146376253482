import { ref } from "vue";
import { defineStore } from "pinia";

export const useStateStore = defineStore("stream_state", () => {
  const search = ref("");
  const offset = ref(0);
  const detail = ref({});

  return {
    search,
    offset,
    detail
  };
})