import SYSTEM from "../system.js";

export default class Roster {
  constructor(response) {
    this.access   = false;
    this.errors   = {};
    this.status   = {};
    this.calendar = [];
    this.weekends = [];
    this.holidays = [];
    this.statuses = [];

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.calendar = response.data.calendar;
        this.weekends = response.data.weekends;
        this.holidays = response.data.holidays;
        this.statuses = response.data.statuses;
      }
    }
  }
}