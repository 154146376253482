<template>
  <div class="card-wrapper">
    <div class="cards h-100">
      <div class="header d-flex p-16">
        <div class="short-text font-section font-weight-500">
          {{ $t(title) }}
        </div>
      </div>

      <div class="content p-16 pt-0">
        <div
          v-for="item in items"
          :key="item.name"
          class="checkbox mb-8 lh-20"
          :class="item.disabled ? 'disabled' : ''"
        >
          <label>
            <input
              class="checkbox"
              type="checkbox"
              :checked="item.checked == 1"
              :disabled="item.disabled == 1"
              @click="$emit('change', item)"
            >

            <span class="checkmark" />

            <div class="text">
              {{ $t(item.name) }}
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: { type: String, default: () => "" },
      items: { type: Array , default: () => [] }
    },

    emits: [
      "change"
    ]
  };
</script>
