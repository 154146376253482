import SERVER from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(id) {
    return new Entity(await SERVER.get("calendar/" + id));
  }

  static async load_calendar(space_id, user_id) {
    return await SERVER.get("calendar/calendar?space_id=" + space_id + "&user_id=" + user_id);
  }

  static async load_booking(space_id, user_id, from, to) {
    return await SERVER.get("calendar/booking?space_id=" + space_id + "&user_id=" + user_id + "&from=" + from + "&to=" + to);
  }

  static async user_holidays(holiday) {
    return await SERVER.put("calendar/" + holiday.spaces_id + "/holidays/user", holiday);
  }

  static async update_duration(calendar_id, field, value, spaces_id) {
    return new Entity(await SERVER.post("calendar/update", { calendar_id: calendar_id, field: field, value: value, spaces_id: spaces_id } ));
  }

  static async create_status(spaces_id, name, rate, color) {
    return new Entity(await SERVER.post("calendar/status/store", { spaces_id: spaces_id, name:name, rate: rate, color: color }));
  }

  static async update_status(statuses_id, name, rate, color) {
    return new Entity(await SERVER.post("calendar/status/update", { statuses_id: statuses_id, name:name, rate: rate, color: color }));
  }

  static async remove_status(statuses_id, spaces_id) {
    return new Entity(await SERVER.post("calendar/status/delete", { id: statuses_id, spaces_id: spaces_id }));
  }

  static async update_weekends(weekend) {
    return new Entity(await SERVER.put("calendar/" + weekend.spaces_id + "/weekends", weekend));
  }

  static async update_holidays(holiday) {
    return new Entity(await SERVER.put("calendar/" + holiday.spaces_id + "/holidays", holiday));
  }
}