<script setup>
  import { reactive, watch, onMounted, defineProps, defineEmits } from "vue";

  const props = defineProps({
    wrapper: { type: Object, default: () => {} },
    roots  : { type: Array , default: () => [] },
    paths  : { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "handler"
  ]);

  const source = reactive({ items: [] });

  onMounted(() => refresh(props.roots, props.paths));
  watch([() => props.roots, () => props.paths], ([roots, paths]) => refresh(roots, paths));

  function refresh (roots, paths) {
    source.items = paths && roots[0] && paths[roots[0]] ? paths[roots[0]] : [];
  }
</script>

<template>
  <div class="widgets-crumbs">
    <div v-if="props.wrapper && props.wrapper.name"
      class="member"
      @click.stop.prevent="$emit('handler', 0)"
    >
      {{ props.wrapper.name }}
    </div>

    <div v-for="(item, index) in source.items" :key="index">
      <div
        class="member"
        @click.stop.prevent="emit('handler', index)"
      >
        <div class="short-title max-w-200px">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>