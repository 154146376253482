import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(spaces_id, roles_id) {
    return new Entity(await Server.get("roles/" + roles_id + "?spaces_id=" + spaces_id));
  }

  static async change(roles_id, permission) {
    return new Entity(await Server.put("roles/change/" + roles_id, permission ));
  }

  static async create_qualification(id, name, rate) {
    return new Entity(await Server.post("roles/qualifications/", {
      roles_id: id,
      name    : name,
      rate    : rate
    } ));
  }

  static async update_qualification(id, field, value) {
    return new Entity(await Server.put("roles/qualifications/" + id, { field: field, value: value } ));
  }

  static async delete_qualification(id) {
    return new Entity(await Server.delete("roles/qualifications/" + id));
  }
}