<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div v-if="!form.edit"
      class="d-flex c-crosshair"
      @click="form.edit = true"
    >
      <div v-if="item">
        {{ item.name }}
      </div>

      <div v-else class="placeholder font-memo font-italic">
        {{ placeholder }}
      </div>
    </div>

    <div v-if="form.edit" :class="{ 'p-relative': clear }">
      <select
        class="w-100"
        :autofocus="form.edit"
        :value="value"
        @change="change"
      >
        <option value="" disabled>
          {{ placeholder }}
        </option>

        <option
          v-for="option in items"
          :key="option.id"
          :value="option.id"
          :selected="option.id == selected"
        >
          {{ option.name || option.title }}
        </option>
      </select>

      <div v-if="clear"
        class="icon icon-clear p-absolute c-pointer t-8px r-20px"
        @click="cleaning"
      />
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    reactive,
    computed
  } from "vue";

  export default {
    props: {
      value      : { type: Number , default: () => 0     },
      label      : { type: String , default: () => ""    },
      items      : { type: Array  , default: () => []    },
      selected   : { type: Number , default: () => 0     },
      placeholder: { type: String , default: () => ""    },
      description: { type: String , default: () => ""    },
      clear      : { type: Boolean, default: () => false }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const form = reactive({ edit: false });
      const item = computed(() => props.items.length && props.items.find(item => item.id == props.selected) );

      return { 
        item,
        form,

        change: (event) => {
          context.emit("submit", event.target.value);
          form.edit = false;
        },

        cleaning: () => {
          context.emit("submit", "");
          form.edit = false;
        }
      };
    }
  };
</script>