export class Action {
  constructor (
    code,
    action,
    options = undefined
  ) {
    this.code   = code;
    this.action = action;

    this.alt   = options?.alt   || false;
    this.ctrl  = options?.ctrl  || false;
    this.shift = options?.shift || false;
    this.stop  = options?.stop  || false;

    this.type = options?.type || TYPE_DYNAMIC;
  }
}

export class Hotkeys {
  static reinit(region) {
    handlers = handlers.filter(
      handler =>
        (region && (!handler.region || handler.region != region)) ||
        (!region && handler.type == TYPE_STATIC)
    );
  }

  static change(primary, reserve) {
    let region = document.querySelector(`[data-${DATA_REGION}="${primary}"]`) || document.querySelector(`[data-${DATA_REGION}="${reserve}"]`);

    if (region) {
      document.querySelectorAll(`[data-${DATA_REGION_PICKED}]`)
        .forEach(element =>
          element.removeAttribute(`data-${DATA_REGION_PICKED}`)
        )
      ;

      region.setAttribute(`data-${DATA_REGION_PICKED}`, "");

      return true;
    }

    return false;
  }

  static define(hotkeys, region) {
    hotkeys.forEach(action => {
      if (region)
        action.region = region;

      handlers.push(action);
    });
  }

  static focused(wrapper, id) {
    let node = document.querySelector(`[data-${DATA_REGION_PICKED}] [data-${id !== undefined ? `id="${id || 0}"` : `index="1"`}]`);

    if (node) {
      document.querySelectorAll(`[data-${DATA_FOCUSED}]`)
        .forEach(element =>
          element.removeAttribute(`data-${DATA_FOCUSED}`)
        )
      ;

      node = wrapper ? node.getElementsByClassName(wrapper)[0] : node;

      if (node)
        node.dataset[DATA_FOCUSED] = true;
    }
  }

  static toggle(node) {
    if (node.dataset[DATA_FOCUSED]) {
      delete node.dataset[DATA_FOCUSED];
    }
    else {
      node.dataset[DATA_FOCUSED] = true;
    }
  }

  static get KEYCODE_ESC   () { return 27  };
  static get KEYCODE_ENTER () { return 13  };
  static get KEYCODE_LEFT  () { return 37  };
  static get KEYCODE_UP    () { return 38  };
  static get KEYCODE_RIGHT () { return 39  };
  static get KEYCODE_DOWN  () { return 40  };
  static get KEYCODE_INSERT() { return 45  };
  static get KEYCODE_B     () { return 66  };
  static get KEYCODE_C     () { return 67  };
  static get KEYCODE_D     () { return 68  };
  static get KEYCODE_E     () { return 69  };
  static get KEYCODE_I     () { return 73  };
  static get KEYCODE_M     () { return 77  };
  static get KEYCODE_O     () { return 79  };
  static get KEYCODE_P     () { return 80  };
  static get KEYCODE_S     () { return 83  };
  static get KEYCODE_T     () { return 84  };
  static get KEYCODE_V     () { return 86  };
  static get KEYCODE_X     () { return 88  };
  static get KEYCODE_F2    () { return 113 };
}

function handle(event) {
  let region = detect_region();
  let actions = detect_actions(event, region);

  if (!actions.length)
    actions = detect_actions(event);

  actions.forEach(action => {
    let nodes = [];

    [DATA_FOCUSED].forEach(selector => {
      region.querySelectorAll(`[data-${selector}]`).forEach(node =>
        nodes.push(node)
      );
    });

    if (action.stop) {
      event.cancelBubble = true;
      event.preventDefault();
    }

    action.action(nodes);
  });
}

function detect_region() {
  return document.querySelector(`[data-${DATA_REGION_PICKED}]`) || document;
}

function detect_actions(event, region) {
  let name = region?.dataset?.[DATA_REGION];

  return handlers.filter(handler =>
    (name && handler.region == name || !name) &&
    handler.code  == event.keyCode &&
    handler.alt   == event.altKey  &&
    handler.ctrl  == event.ctrlKey &&
    handler.shift == event.shiftKey
  )
}

const DATA_FOCUSED = "focused";
// const DATA_SELECTED = "selected";

const DATA_REGION = "region";
const DATA_REGION_PICKED = "picked";

const TYPE_STATIC = 1;
const TYPE_DYNAMIC = 2;

let handlers = [];

// window.addEventListener("keyup", handle);
window.addEventListener("keydown", handle);