<script setup>
  import { defineProps } from "vue";

  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";
  import FactorsRoster from "../../factors/plugins/roster/Roster.vue";

  const props = defineProps({
    id: { type: String, default: () => "" }
  });
</script>

<template>
  <layout-web>
    <div>
      <partial-header
        :id="$route.params.id"
        :active="5"
      />

      <div class="base-neutral border-radius-8 p-8">
        <factors-roster
          :spaces_id="props.id"
        />
      </div>
    </div>
  </layout-web>
</template>