import { ref } from "vue";
import { defineStore } from "pinia";

import { Blank, States } from "../../../packages/states/States.js";

export const useStateStore = defineStore("schema_state", () => {
  const blank = ref(-1);

  const detail = ref({});
  const select = ref(-1);

  const states  = ref([]);
  const manager = new States(states.value, NodeState);

  function cleanup_actions() {
    manager.cleanup(["context", "process", "heading", "dead_edit", "time_edit"])
  }

  function switch_action(id, clear, target, region) {
    if (id > -1) {
      manager.cleanup(clear);
      manager.switch_state(id, region, target);
    }
    else {
      cleanup_actions();
    }
  }

  function cleanup_fields(id) {
    manager.cleanup(["dead_show", "done_show", "step_show", "time_show", "work_show"], id)
  }

  function switch_field(array, clear, target, region, value) {
    cleanup_actions();

    array.forEach(id => {
      manager.cleanup(clear, id);
      manager.switch_state(id, region, target, value);
    });
  }

  return {
    blank,

    detail,
    select,

    get_state: (id, region, field) => {
      return manager.get_state(id, region, field);
    },

    switch_context: (id, region) => {
      switch_action(id, ["process", "heading", "dead_edit", "time_edit"], "context", region);
    },

    switch_process: (id, region) => {
      switch_action(id, ["context", "heading", "dead_edit", "time_edit"], "process", region);
    },

    switch_heading: (id, region) => {
      switch_action(id, ["context", "process", "dead_edit", "time_edit"], "heading", region);
    },

    switch_dead_edit: (id, region) => {
      cleanup_fields(id);
      switch_action(id, ["context", "process", "heading", "time_edit"], "dead_edit", region);
    },

    switch_time_edit: (id, region) => {
      cleanup_fields(id);
      switch_action(id, ["context", "process", "heading", "dead_edit"], "time_edit", region);
    },

    switch_dead_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["done_show", "step_show", "time_show", "work_show"], "dead_show", region, group.length ? true : undefined);
    },

    switch_done_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "step_show", "time_show", "work_show"], "done_show", region, group.length ? true : undefined);
    },

    switch_step_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "done_show", "time_show", "work_show"], "step_show", region, group.length ? true : undefined);
    },

    switch_time_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "done_show", "step_show", "work_show"], "time_show", region, group.length ? true : undefined);
    },

    switch_work_show: (id, region, group = []) => {
      switch_field(group.length ? group : [id], ["dead_show", "done_show", "step_show", "time_show"], "work_show", region, group.length ? true : undefined);
    },

    node_activate: (node, action = "") => {
      detail.value = node;
      detail.value.action = action;
    },

    node_deactivate: () => {
      detail.value = {};
    }
  };
})

class NodeState extends Blank {
  constructor (id, region, field, value) {
    super(id, region);

    this.context   = false;
    this.process   = false;
    this.heading   = false;
    this.dead_edit = false;
    this.time_edit = false;
    this.dead_show = false;
    this.done_show = false;
    this.step_show = false;
    this.time_show = false;
    this.work_show = false;

    if (value !== undefined)
      this[field] = value;
  };
}