
<script setup>
  import { defineProps, defineEmits } from "vue";

  const props = defineProps({
    icon: { type: String, default: () => "" },
    item: { type: Object, default: () => {} }
  });

  const emit = defineEmits([
    "handler"
  ]);
</script>

<template>
  <div class="p-relative">
    <div
      class="d-flex p-relative justify-content-center align-items-center border-radius-12 box-shadow-bottom w-32px h-32px c-pointer"
      :class="{
        'base-neutral': !props.item.status,
        'base-highlight': props.item.status
      }"
      @click.stop="emit('handler', { id: props.item.id, status: !props.item.status})"
    >
      <div
        class="icon"
        :class="{
          ['icon-' + props.icon]: !props.item.status,
          ['icon-' + props.icon + '-hover']: props.item.status
        }"
      />
    </div>
  </div>
</template>