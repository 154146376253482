<script setup>
  import { onBeforeMount, onMounted } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();

  import Mediator from "../../../system/mediator/Mediator";
  import { Hotkeys, Action } from "../../../packages/hotkeys/Hotkeys";

  import LayoutWeb            from "../../../layouts/Web.vue";
  import CommonPartialFilter  from "../../../visual/partials/Filter.vue" ;
  import CommonPartialContent from "../../../visual/partials/Content.vue";
  import PartialFilters       from "./partials/Filters.vue";
  import PartialHierarchy     from "./partials/Hierarchy.vue";
  import PartialChart         from "./partials/Chart.vue";
  import PartialList          from "./partials/List.vue";
  import PartialSidebar       from "./partials/Sidebar.vue";
  import WidgetsCrumbs        from "../../../visual/widgets/crumbs/Default.vue";

  onBeforeMount(() => Hotkeys.reinit());

  onMounted(async () => {
    await source.load();
    Mediator.define("schema:reload", async () => await source.load());

    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_V , () => source.mode_view({ id: source.get_active.id, status: !source.get_active.mode_view }))
    ]);
  });

  const resize = () => document.addEventListener("mousemove", resize_calculate, false);
  const resize_calculate = (event) => document.getElementById("schema-tree").style.width = (+event.x) + "px";

  document.addEventListener("mouseup", function() {
    document.removeEventListener("mousemove", resize_calculate, false);
  });
</script>

<template>
  <layout-web fullscreen> <!-- v-slot="slotProps" -->
    <common-partial-filter>
      <template #content>
        <partial-filters />
      </template>
    </common-partial-filter>

    <common-partial-content :project="source.get_active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <partial-hierarchy v-if="source.tree.show" class="z-20" />

          <div v-if="source.tree.show"
            class="base-neutral w-3px mx-4 h-100 c-col-resize"
            @mousedown="resize"
          />

          <div class="d-flex flex-column w-100 p-relative z-10">
            <widgets-crumbs
              class="mb-8"
              :wrapper="source.get_active"
              :roots="source.chart.roots"
              :paths="source.chart.paths"
              @handler="(id) => source.tree_pick(id)"
            />

            <partial-chart v-if="source.get_active.mode_view" />

            <partial-list v-else />

            <div id="chart-overview" />
          </div>
        </div>
      </template>
    </common-partial-content>

    <partial-sidebar /> <!-- :user="slotProps.user" -->
  </layout-web>
</template>