<script setup>
  import { ref, reactive, onMounted } from "vue";

  import RosterEntity  from "../../entities/Roster.js";
  import RosterService from "../../services/Roster.js";

  import FieldCheckbox from "../../../../_visual/fields/checkbox/Checkbox.vue";

  const props = defineProps({
    spaces_id: { type: String, default: () => "" }
  });

  const source = reactive(new RosterEntity);

  const mounted = ref(false);
  onMounted(async () => {
    Object.assign(source, await RosterService.load(props.spaces_id));
    mounted.value = true;
  });

  async function update(item) {
    Object.assign(source, await RosterService.update(props.spaces_id, item));
  };
</script>

<template>
  <div v-if="source.access">
    <div
      v-for="item in source.modules"
      :key="item.id"
      class="d-flex"
    >
      <field-checkbox
        :lang="true"
        :value="item"
        @submit="update"
      />

      <div class="w-100">
        {{ item.description }}
      </div>
    </div>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>