<script setup>
  import { ref, onMounted, nextTick } from "vue";

  const KEYCODE_ENTER = 13;
  const KEYCODE_ESC   = 27;
  const KEYCODE_DOWN  = 40;
  const KEYCODE_UP    = 38;

  const props = defineProps({
    autofocus  : { type: Boolean, default: () => false },
    pathable   : { type: Boolean, default: () => false },
    list       : { type: Array  , default: () => []    },
    lock       : { type: Boolean, default: () => false },
    label      : { type: String , default: () => ""    },
    placeholder: { type: String , default: () => ""    },
    description: { type: String , default: () => ""    }
  });

  const emit = defineEmits([
    "handle_choose", "handle_search"
  ]);

  const input = ref();

  onMounted(async() => {
    await nextTick();
    if (props.autofocus)
      input.value.focus();
  });

  function move(list, index, offset) {
    list.map(item => item.active = false);

    if (index != -1) {
      if (list[index + offset]) list[index + offset].active = true;
    }
    else if (offset > 0) {
      if (list[0]) list[0].active = true;
    }
    else if (offset < 0) {
      if (list[(list.length - 1)]) list[(list.length - 1)].active = true;
    }

    let active = list.find(item => item.active == true);

    if (active)
      document.querySelector(`[data-autocomplete="${active.id}"]`).scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
  };

  function choose(list, index) {
    if (index != -1) {
      emit("handle_choose", { id: list[index].id, name: list[index].name || list[index].title });
    }
    else if (!props.lock) {
      emit("handle_choose", { id: 0, name: input.value.value });
    }

    input.value.value = "";
  };

  function search(event) {
    let index = props.list.findIndex(item => item.active == true);

    if      (event.keyCode == KEYCODE_DOWN ) { move(props.list, index, 1);                 }
    else if (event.keyCode == KEYCODE_UP   ) { move(props.list, index, -1);                }
    else if (event.keyCode == KEYCODE_ENTER) { choose(props.list, index);                  }
    else if (event.keyCode == KEYCODE_ESC  ) { emit("handle_choose", { id: 0, name: "" }); }
    else                                     { emit("handle_search", event.target.value);  }
  };
</script>

<template>
  <div class="field autocomplete w-100">
    <div v-if="props.label" class="label">
      {{ props.label }}
    </div>

    <input
      :ref="(element) => { input = element }"
      type="text"
      :placeholder="props.placeholder"

      @keyup.stop
      @keydown.stop="search"
    />

    <div v-if="props.list.length"
      class="dropdown box-shadow max-h-400px scrollbar-disable overflow-y-scroll"
    >
      <div v-for="item in props.list"
        :key="item.id"
        :data-autocomplete="item.id"

        class="item"
        :class="{ active: item.active }"

        @click="$emit('handle_choose', { id: item.id, name: item.name || item.title })"
      >
        <div class="d-flex">
          <div class="font-soft mr-4">
            #{{ item.code }}
          </div>

          <div class="short-text">
            {{ item.name }}
          </div>
        </div>

        <div v-if="props.pathable" class="path">
          <div v-for="(member, index) in item.path" :key="index" class="member d-inline word-break short-string">
            {{ member }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>
  </div>
</template>