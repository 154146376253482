<script setup>
  import { ref, reactive, onMounted, defineProps } from "vue";

  import PopupBase       from "../../../../visual/popups/base/Base.vue";
  import FieldInput      from "../../../../_visual/fields/input/Input.vue";
  import FieldInputCombo from "../../../../_visual/fields/input/combo/Combo.vue";

  import RosterEntity  from "../../entities/Roster.js";
  import RosterService from "../../services/Roster.js";

  const props = defineProps({
    spaces_id: { type: String, default: () => "" }
  });

  const source = reactive(new RosterEntity);

  const popups = reactive({ factor: false });
  const fields = reactive({ title: ""   , variable: ""   , value: ""   , formula: ""    });
  const errors = reactive({ title: false, variable: false, value: false, formula: false });

  const mounted = ref(false);
  onMounted(async () => {
    Object.assign(source, await RosterService.load(props.spaces_id));
    mounted.value = true;
  });

  const show_popups_factor = (type) => {
    popups.factor = type;
  };
  const hide_popups_factor = () => {
    popups.factor   = false;
    fields.title    = "";
    fields.variable = "";
    fields.value    = "";
    fields.formula  = "";

    remove_errors();
  };

  const attach_errors = () => {
    errors.title    = fields.title    == "" ? true : false;
    errors.variable = fields.variable == "" ? true : false;
    errors.value    = fields.value    == "" ? true : false;
    errors.formula  = fields.formula  == "" ? true : false;
  };
  const remove_errors = () => {
    errors.title    = false;
    errors.variable = false;
    errors.value    = false;
    errors.formula  = false;
  };

  async function create() {
    if (fields.title != "" && fields.variable != "" && (fields.value != "" || fields.formula != "") ) {
      remove_errors();
      Object.assign(source, await RosterService.create({
        spaces_id: props.spaces_id,
        title    : fields.title,
        variable : fields.variable,
        value    : fields.value,
        formula  : fields.formula
      }));
      hide_popups_factor();
    }
    else {
      attach_errors();
    }
  };

  async function update_title(value, factor) {
    Object.assign(source, await RosterService.update(factor.id, "title", value));
  };

  async function update_variable(value, factor) {
    Object.assign(source, await RosterService.update(factor.id, "variable", value));
  };

  async function update_value(value, factor) {
    Object.assign(source, await RosterService.update(factor.id, "value", value));
  };

  async function update_formula(value, factor) {
    Object.assign(source, await RosterService.update(factor.id, "formula", value));
  };

  async function remove(id) {
    Object.assign(source, await RosterService.delete(id));
  };
</script>

<template>
  <div v-if="source.access">
    <div class="d-flex flex-column w-100">
      <div class="mb-20">
        {{ $t("Factors") }}
      </div>

      <div v-for="item in source.factors.filter(factor => factor.value)"
        :key="item.id"
        class="box-grid col-4 lh-32"
      >
        <div>
          <field-input-combo
            class="mb-20"
            :object="item"
            :value="item.title"
            :placeholder="$t('insert_title')"
            @submit="update_title"
          />
        </div>

        <div class="d-flex">
          $
          <field-input-combo
            class="mb-20"
            :object="item"
            :value="item.variable"
            :placeholder="$t('insert_variable')"
            @submit="update_variable"
          />
        </div>

        <div>
          <field-input-combo
            class="mb-20"
            :object="item"
            :value="item.value"
            :placeholder="$t('insert_value')"
            @submit="update_value"
          />
        </div>

        <div
          class="d-flex justify-content-center justify-self-end align-items-center border-1 border-radius-12 color-border-light hover-border-highlight base-neutral w-18px h-18px c-pointer"
          @click="remove(item.id)"
        >
          -
        </div>
      </div>

      <div
        class="d-flex justify-content-center c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        @click="show_popups_factor('factor')"
      >
        {{ $t("add") }}
      </div>
    </div>

    <div class="d-flex flex-column w-100 mt-10">
      <div class="mt-32 mb-20">
        {{ $t("Formulas") }}
      </div>

      <div v-for="item in source.factors.filter(factor => factor.formula)"
        :key="item.id"
        class="box-grid col-4 lh-32"
      >
        <div>
          <field-input-combo
            class="mb-20"
            :object="item"
            :value="item.title"
            :placeholder="$t('insert_title')"
            @submit="update_title"
          />
        </div>

        <div class="d-flex">
          $
          <field-input-combo
            class="mb-20"
            :object="item"
            :value="item.variable"
            :placeholder="$t('insert_variable')"
            @submit="update_variable"
          />
        </div>

        <div>
          <field-input-combo
            class="mb-20"
            :object="item"
            :value="item.formula"
            :placeholder="$t('insert_formula')"
            @submit="update_formula"
          />
        </div>

        <div class="d-flex justify-content-between">
          <div>
            {{ item.summary }}
          </div>

          <div
            class="d-flex justify-content-center justify-self-end align-items-center border-1 border-radius-12 color-border-light hover-border-highlight base-neutral w-18px h-18px c-pointer"
            @click="remove(item.id)"
          >
            -
          </div>
        </div>
      </div>

      <div
        class="d-flex justify-content-center c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
        @click="show_popups_factor('formula')"
      >
        {{ $t("add") }}
      </div>
    </div>

    <popup-base v-if="popups.factor">
      <template #header>
        <div>{{ $t("add_factor") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_factor"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.title"
          class="mb-10"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.title"
        />

        <field-input
          v-model="fields.variable"
          class="mb-10"
          :placeholder="$t('insert_variable')"
          :message="$t('variable_is_empty')"
          :failure="errors.variable"
        />

        <field-input v-if="popups.factor == 'factor'"
          v-model="fields.value"
          class="mb-10"
          :placeholder="$t('insert_value')"
          :message="$t('value_is_empty')"
          :failure="errors.value"
        />

        <field-input v-else
          v-model="fields.formula"
          class="mb-10"
          :placeholder="$t('insert_formula')"
          :message="$t('formula_is_empty')"
          :failure="errors.formula"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t('ok') }}
        </div>
      </template>
    </popup-base>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>