import SERVER from "../../../system/server/server";

export default class Detail {
  static async load(keyword = "") {
    return await SERVER.get("people" + "?keyword=" + keyword);
  }

  static async states(space_id, option, value, keyword = "") {
    return await SERVER.post("people/states" + "?keyword=" + keyword, { id: space_id, option: option, value: value });
  }

  static async priority(spaces, keyword = "") {
    return await SERVER.post("people/priority" + "?keyword=" + keyword, { list: spaces });
  }
}