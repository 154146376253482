<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    overview: { type: Boolean, default: () => false }
  });
</script>

<template>
  <div class="popup popup-base p-fixed">
    <div class="popup-body p-fixed"
      :class="{
        'h-60': props.overview,
        'overflow-scroll': props.overview,
        'scrollbar-disable': props.overview
      }">
      <div class="popup-header d-flex justify-content-between">
        <slot name="header" />
      </div>

      <div class="popup-content">
        <slot name="content" />
      </div>

      <div class="popup-footer d-flex justify-content-end">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>