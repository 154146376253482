<script setup>
  import { ref, reactive, computed, watch, defineProps, defineEmits } from "vue";

  import Motion from "../Motion.js";

  import ChartsLink from "./elements/Link.vue";
  import ChartsNode from "./elements/Node.vue";

  const SCALE = 10;

  const props = defineProps({
    nodes   : { type: Array , default: () => [] },
    links   : { type: Array , default: () => [] },
    layout  : { type: Object, default: () => {} },
    region  : { type: Object, default: () => {} },
    viewport: { type: Object, default: () => {} },
    scale   : { type: Number, default: () => 1  }
  });

  const emit = defineEmits([
    "location"
  ]);

  const window = ref();

  const cursor = reactive({ x: 0, y: 0 });

  const region = reactive({
    width : props.region.width,
    height: props.region.height
  });

  watch(props.region, size => {
    region.width  = size.width;
    region.height = size.height;
  });

  const overview = computed(() => ({
    width : region.width  + "px",
    height: region.height + "px",
  }));

  const viewport = reactive({
    left  : props.viewport.left,
    top   : props.viewport.top,
    width : props.viewport.width,
    height: props.viewport.height,
  });

  watch(props.viewport, location => {
    viewport.left   = -location.left * props.scale;
    viewport.top    = -location.top * props.scale;
    viewport.width  = location.width;
    viewport.height = location.height;
  });

  const location = computed(() => ({
    left  : viewport.left   + "px",
    top   : viewport.top    + "px",
    width : viewport.width  + "px",
    height: viewport.height + "px",
  }));

  const drag = function (event) {
    cursor.x = event.x;
    cursor.y = event.y;

    Motion.launch(event, window.value);
  };

  const over_slow = function (event) {
    const limits = {
      right : region.width  - viewport.width,
      bottom: region.height - viewport.height,
    };

    let location = Motion.handle(
      viewport, cursor, event, limits, SCALE
    );

    cursor.x = event.x;
    cursor.y = event.y;

    emit("location", -location.left, -location.top);
  };

  var limitExecByInterval = function(fn, time) {
    var lock, execOnUnlock, args;
    return function() {
      args = arguments;
      if (!lock) {
        lock = true;
        // var scope = this;
        setTimeout(function(){
          lock = false;
          if (execOnUnlock) {
            // args.caller.apply(scope, args);
            execOnUnlock = false;
          }
        }, time);
        return fn.apply(this, args);
      } else execOnUnlock = true;
    }
  }

  var over = limitExecByInterval(over_slow, 50);

  const drop = function () {
    Motion.finish(window.value);
  };

  function classes() {
    return `w-250px`;
  };

  function position(node) {
    return `position: absolute; ${props.layout.nodes && props.layout.nodes[node.entity.id] || "opacity: 0; "}`;
  };
</script>

<template>
  <div
    class="overview z-101 base-neutral"
    :style="overview"

    @dragenter.prevent
    @dragover.prevent="over"
  >
    <charts-link
      :links="props.links"
      :layout="props.layout"
    />

    <div v-for="(node) in props.nodes"
      :key="node.entity.id"

      class="node base-neutral border-radius-4 box-shadow-bottom"
      :class="classes()"
      :style="position(node)"
    >
      <charts-node
        :node="node"
        @fold="() => { return; }"
      />
   </div>

    <div ref="window"
      class="viewport"
      :style="location"

      draggable="true"
      @dragenter.prevent
      @dragend="drop"
      @dragstart="drag"
      @dragover.prevent="over"
    />
  </div>
</template>

<style lang="scss" scoped>
  .overview {
    right: 10px;
    bottom: 10px;

    position: absolute;
    border  : 10px solid #FFFFFF;

    transform       : scale(0.1, 0.1);
    transform-origin: bottom right;
  }

  .viewport {
    border  : 5px solid #353B3D;
    position: absolute;
  }
</style>