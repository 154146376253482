<template>
  <div>
    <div class="d-flex p-8">
      <field-select
        v-model="fields.user"
        class="w-200px"
        :items="users"
        :selected="false"
        :placeholder="$t('insert_user')"
        @change="$emit('update', $event.target.value)"
      />
    </div>

    <div class="d-flex flex-column">
      <!--Expenses list -->
      <div class="d-flex align-items-center h-32px base-light">
        <div class="p-5 w-15 min-w-150px">
          {{ $t("date") }}
        </div>

        <div class="p-5 w-15 min-w-150px">
          {{ $t("total_spent") }}
        </div>

        <div class="p-5 w-70 min-w-150px">
          {{ $t("nodes") }}
        </div>
      </div>

      <div v-for="row in list" :key="row">
        <div class="d-flex">
          <div class="d-flex p-5 w-15 min-w-150px">
            {{ format_date(row.date) }}
          </div>

          <div class="d-flex p-5 w-15 min-w-150px">
            {{ format_time(row.duration) }}
          </div>

          <div class="d-flex flex-column p-5 w-70 min-w-150px">
            <div v-for="node in row.nodes"
              :key="node.id"
              class="d-inline p-5"
            >
              <router-link :to="'/node/' + node.code" class="left font-dark text-decoration-none">
                [#{{ node.code || node.id }}]
              </router-link>

              <router-link :to="'/node/' + node.code" class="left font-dark text-decoration-none">
                {{ node.title }}
              </router-link>

              ({{ format_time(node.spent) }})
            </div>
          </div>
        </div>

        <hr>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive } from "vue";

  import FieldSelect from "../../../../_visual/fields/select/Select.vue";

  const PER_HOUR   = 3600;
  const PER_MINUTE = 60;

  export default {
    components: {
      FieldSelect
    },

    props: {
      users: { type: Object, default: () => {} },
      list: { type: Object, default: () => {} },
    },

    emits: [
      'update'
    ],

    setup() {
      const fields = reactive({
        user: ""
      });

      let format_time = (time) => {
        let hour   = parseInt(time / PER_HOUR);
        time       = time - (hour * PER_HOUR);
        let minute = parseInt(time / PER_MINUTE);
        return format_time_value(hour) + ":" + format_time_value(minute);
      };

      let format_time_value = (value) => {
        return String(value).length < 2 && ('0' + String(value)) || value;
      };

      let format_date = (date) => {
        return new Date(date).toLocaleDateString("ru-RU", { dateStyle: "long" });
      };

      return {
        fields,

        format_time,
        format_date
      };
    }
  };
</script>