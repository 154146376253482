<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import WidgetProjects    from "../../../../visual/widgets/projects/Projects.vue";
  import WidgetFilterCombo from "../../../../visual/widgets/filters/Combo.vue";
  import FieldSearch       from "../../../../visual/fields/search/Default.vue";

  function search(keyword) {
    state.$patch({ search: keyword });
    source.load(keyword);
  };
</script>

<template>
  <div class="d-flex justify-content-between w-100">
    <widget-projects
      :projects="source.get_visible"
      @activate="(id) => source.project_activate(id, state.search)"
      @priority="(event) => source.project_priority(event, state.search)"
    />

    <div class="d-flex align-items-center">
      <field-search
        class="max-w-400px"
        :value="state.search"
        @handler="keyword => search(keyword)"
      />

      <widget-filter-combo
        class="ml-8"
        :projects="source.projects"
        @projects_handler="(id) => source.project_visible(id, state.search)"
      />
    </div>
  </div>
</template>