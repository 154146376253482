<script setup>
  import { onBeforeMount, onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../stores/StateStore.js";
  const state = useStateStore();

  import Mediator            from "../../../system/mediator/Mediator";
  import { Hotkeys, Action } from "../../../packages/hotkeys/Hotkeys";

  import LayoutWeb            from "../../../layouts/Web.vue";
  import CommonPartialFilter  from "../../../visual/partials/Filter.vue";
  import CommonPartialContent from "../../../visual/partials/Content.vue";
  import PartialFilters       from "./partials/Filters.vue";
  import PartialPeriods       from "./partials/Periods.vue";
  import PartialSidebar       from "./partials/Sidebar.vue";

  const REGION_MAIN = "main";

  onBeforeMount(() => Hotkeys.reinit());

  onMounted(async () => {
    await source.load();
    Mediator.define("planner:reload", async () => await source.load());

    Hotkeys.change(REGION_MAIN);
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ESC  , () => jump_prev()),
      new Action(Hotkeys.KEYCODE_ENTER, (nodes) => nodes.forEach((node) => jump_next(node))),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => move(node, "left") ), { stop: true }),
      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => move(node, "up")   ), { stop: true }),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => move(node, "right")), { stop: true }),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => move(node, "down") ), { stop: true }),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => transfer(node, "left") ), { ctrl: true, stop: true }),
      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => transfer(node, "up")   ), { ctrl: true, stop: true }),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => transfer(node, "right")), { ctrl: true, stop: true }),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => transfer(node, "down") ), { ctrl: true, stop: true }),

      new Action(Hotkeys.KEYCODE_M, (nodes) => nodes.forEach((node) => state.switch_context(+node.closest("[data-id]").dataset.id, REGION_MAIN)), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => state.switch_dead_show(+node.closest("[data-id]").dataset.id, REGION_MAIN))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => state.switch_step_show(+node.closest("[data-id]").dataset.id, REGION_MAIN))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.closest("[data-id]").dataset.id, REGION_MAIN))),
      new Action(Hotkeys.KEYCODE_D, () => state.switch_dead_show(-1, REGION_MAIN, get_nodes_ids()), { alt: true, stop: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_step_show(-1, REGION_MAIN, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_T, () => state.switch_time_show(-1, REGION_MAIN, get_nodes_ids()), { alt: true             }),
    ], REGION_MAIN);
    Hotkeys.focused("planner-item");
  });

  onUnmounted(() => Hotkeys.reinit(REGION_MAIN));

  function get_nodes_ids() {
    return source.nodes.map(node => node.id);
  }

  function pick(node) {
    state.node_activate(node)
    Hotkeys.focused("planner-item", node.id);
  }

  function jump_prev() {
    state.node_deactivate();
  };

  function jump_next(node, action = "") {
    let target = source.nodes.find(target =>
      target.id == node.closest("[data-id]").dataset.id)
    ;

    target && state.node_activate(target, action);
  };

  function move(node, action) {
    if (["left", "right"].includes(action)) {
      let target;
      let wrapper = action == "right" ?
        node.closest("[data-period-id]").nextSibling :
        node.closest("[data-period-id]").previousSibling
      ;

      while (wrapper) {
        target = wrapper && wrapper.childNodes.length && wrapper.getElementsByClassName("planner-item")[0];

        if (target) {
          Hotkeys.toggle(node);
          Hotkeys.toggle(target);

          target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});

          break;
        }

        wrapper = action == "right" ? wrapper.nextSibling : wrapper.previousSibling;
      }
    }
    else {
      let target = action == "down" ?
        node.closest("[data-id]").nextSibling :
        node.closest("[data-id]").previousSibling
      ;

      target = target && target.childNodes.length && target.getElementsByClassName("planner-item")[0];

      if (target) {
        Hotkeys.toggle(node);
        Hotkeys.toggle(target);

        target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
      }
    }
  }

  async function transfer(node, action) {
    if (["left", "right"].includes(action)) {
      let wrapper = node.closest("[data-period-id]");
      let target = action == "right" ? wrapper.nextSibling : wrapper.previousSibling;

      while (target) {
        if (target.dataset && target.dataset.periodId) {
          await source.node_move({
            target         : target.dataset.periodId,
            source         : wrapper.dataset.periodId,
            role_state_id  : node.dataset.roleStateId,
            status_state_id: node.dataset.statusStateId,
            priority       : 0
          }, state.search);

          Hotkeys.focused("planner-item", node.closest("[data-id]").dataset.id);
          node.closest("[data-id]").scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});

          break;
        }

        target = action == "right" ? target.nextSibling : target.previousSibling;
      }
    }
    else {
      let wrapper = node.closest("[data-id]");
      let target = action == "down" ? wrapper.nextSibling : wrapper.previousSibling;

      if (target) {
        await source.node_move({
          target         : target.closest("[data-period-id]").dataset.periodId,
          source         : target.closest("[data-period-id]").dataset.periodId,
          role_state_id  : node.dataset.roleStateId,
          status_state_id: node.dataset.statusStateId,
          priority       : target.dataset.index
        }, state.search);

        node.closest("[data-id]").scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
      }
    }
  }

  async function finish(id) {
    await source.node_finish(source.nodes.find(node => node.id == id).status_states_id, state.search);
    Mediator.handle("tracker:reload");
  };
</script>

<template>
  <layout-web fullscreen>
    <common-partial-filter>
      <template #content>
        <partial-filters />
      </template>
    </common-partial-filter>

    <common-partial-content :project="source.get_active" :complex="true" :background="false">
      <template #content>
        <div
          :data-region="REGION_MAIN"
          class="d-flex h-100 flex-grow box-grid col-6"
        >
          <div v-for="period in source.periods"
            :key="period.id"
            :data-period-id="period.id"
          >
            <partial-periods
              :nodes="source.get_periods(period.id)"
              :period="period"

              @pick="pick"
              @finish="finish"
            />
          </div>

          <partial-periods
            :data-period-id="-1"

            :nodes="source.get_assigne"
            :period="{ id: -1, name: 'some_day' }"

            @pick="pick"
            @finish="finish"
          />

          <partial-periods
            :data-period-id="-2"

            :nodes="source.get_backlog"
            :period="{ id: -2, name: 'all' }"

            @pick="pick"
            @finish="finish"
          />
        </div>
      </template>
    </common-partial-content>

    <partial-sidebar />
  </layout-web>
</template>