import SchemaRoster from './screens/Roster.vue';
// import SchemaDetail from './screens/Detail.vue';

export default [
  {
    path: "/schema",
    name: "SchemaRoster",
    component: SchemaRoster
  }, {
    // path: "/schema/:code",
    // name: "SchemaDetail",
    // component: SchemaDetail,
    // props: true
  }
];