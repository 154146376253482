<script setup>
  import { ref, watchEffect, onUpdated, defineProps, defineEmits } from "vue";

  const KEYCODE_ENTER  = 13;
  const KEYCODE_ESC    = 27;
  const KEYCODE_LEFT   = 37;
  const KEYCODE_UP     = 38;
  const KEYCODE_RIGHT  = 39;
  const KEYCODE_DOWN   = 40;

  const props = defineProps({
    active : { type: Boolean, default: () => false },
    item   : { type: Object , default: () => {}    },
    actions: { type: Array  , default: () => []    }
  });

  const emit = defineEmits([
    "switch"
  ]);

  const dropdown = ref(false);

  watchEffect(() => {
    let menu = document.querySelector(".menu-dropdown");
    !props.active && menu && menu.blur();
  });

  onUpdated(() => {
    let menu = document.querySelector(".menu-dropdown");
    props.active && menu && menu.focus();
  });

  function activate(current, sibling) {
    if (current) delete current.dataset.contextActive;
    if (sibling && sibling.dataset) sibling.dataset.contextActive = true;
    if (sibling.dataset.contextWrapper) dropdown.value = sibling.dataset.contextTitle;
  };

  function move(current, direction) {
    if (!current) {
      current = document.querySelector(".menu-dropdown-item" + (direction == "next" ? "" : ":last-of-type"));
      activate(null, current)
    }
    else {
      let sibling = direction == "next" ? current.nextSibling : current.previousSibling;
      if (sibling && sibling.dataset) activate(current, sibling)
    }
  };

  function fail(current) {
    if (current && current.dataset.contextWrapper) {
      let child = current.querySelector(".menu-dropdown-item");
      if (child && child.dataset) activate(current, child);
    }
  };

  function rise(current) {
    if (current) {
      let parent = current.parentElement.closest(".menu-dropdown-item");
      if (parent && parent.dataset) activate(current, parent);
    }
  };

  function handler(action) {
    action.handler && action.handler(props.item);
    emit("switch");
  };

  function navigation(event) {
    let item = document.querySelector(".menu-dropdown-item[data-context-active]");

    if      (event.keyCode == KEYCODE_DOWN ) { move(item, "next"); }
    else if (event.keyCode == KEYCODE_UP   ) { move(item, "prev"); }
    else if (event.keyCode == KEYCODE_LEFT ) { fail(item);         }
    else if (event.keyCode == KEYCODE_RIGHT) { rise(item);         }
    else if (event.keyCode == KEYCODE_ESC  ) { emit("switch"); }
    else if (event.keyCode == KEYCODE_ENTER) {
      if (item && item.dataset && item.dataset.contextIndex) {
        item.dataset.contextSecondaryIndex &&
          handler(props.actions[item.dataset.contextIndex].actions[item.dataset.contextSecondaryIndex]) ||
          handler(props.actions[item.dataset.contextIndex])
        ;
      };
    }
  };
</script>

<template>
  <div class="actions p-absolute t--7px r--7px z-105">
    <div
      class="menu box-shadow-bottom z-105"
      @click.exact.stop.prevent="emit('switch')"
    >
      <div class="icon icon-menu-context" />
    </div>

    <div v-if="active"
      class="menu-dropdown d-flex flex-column p-absolute border-radius-16 t-0 r-0 min-w-125px max-w-125px p-4 base-neutral box-shadow-bottom z-105"
      tabindex="0"

      @keydown.stop.prevent
      @keyup.stop.prevent="navigation"
      @mouseleave="emit('switch');"
    >
      <div v-for="(action, index) in actions"
        :key="index"
        :data-context-index="index"
        :data-context-title="action.title"
        :data-context-wrapper="!!action.actions"

        class="menu-dropdown-item p-relative d-flex flex-column"

        @mouseover="dropdown = action.title"
        @mouseleave="dropdown = false"
        @click.exact.stop.prevent="handler(action)"
      >
        <div class="menu-dropdown-item-content d-flex justify-content-between my-5 p-5 border-radius-5 hover-base-light c-pointer">
          <div>
            {{ $t(action.title) }}
          </div>

          <div v-if="action.actions" class="font-light">
            >
          </div>

          <div v-else-if="action.hotkey" class="font-memo font-light lh-14">
            {{ action.hotkey }}
          </div>
        </div>

        <hr v-if="action.separator" class="my-5" />

        <div v-if="action.actions && dropdown == action.title"
          class="d-flex flex-column p-absolute t--10px r-100 border-radius-16 min-w-125px max-w-125px p-4 base-neutral box-shadow-bottom z-105"

          @mouseleave="dropdown = false"
        >
          <div v-for="(secondary, secondary_index) in action.actions"
            :key="secondary.title"
            :data-context-index="index"
            :data-context-secondary-index="secondary_index"
            class="menu-dropdown-item d-flex flex-column"

            @click.exact.stop.prevent="handler(secondary)"
          >
            <div class="menu-dropdown-item-content d-flex justify-content-between my-5 p-5 border-radius-5 hover-base-light c-pointer">
              <div>
                {{ $t(secondary.title) }}
              </div>

              <div v-if="secondary.hotkey" class="font-memo font-light lh-14">
                {{ secondary.hotkey }}
              </div>
            </div>

            <hr v-if="secondary.separator" class="my-5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>