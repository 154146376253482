<script setup>
  import { reactive, defineProps, defineEmits } from "vue";

  import PopupBase     from "../../../../../visual/popups/base/Base.vue";
  import FieldInput    from "../../../../../_visual/fields/input/Input.vue";
  import FieldSelect   from "../../../../../_visual/fields/select/Select.vue";
  import FieldCheckbox from "../../../../../_visual/fields/checkbox/Checkbox.vue";
  import WidgetCard    from "../../../../../visual/widgets/cards/Simple.vue";

  const props = defineProps({
    routes: { type: Array , default: () => [] },
    units : { type: Array , default: () => [] }
  });

  const emit = defineEmits([
    "remove", "update", "create"
  ]);

  const popups = reactive({ create: false, update: false });

  const fields = reactive({ create: ""   , unit_id: false, step: "", route_id: 0, weight: null, default: null });
  const errors = reactive({ create: false, unit_id: false });

  const hide_popups_create = () => {
    popups.create = false;
    errors.create = false;

    fields.create  = "";
    fields.step    = "";
    fields.weight  = null;
    fields.default = null;
  };

  // const show_popups_update = (route) => {
  //   popups.update = true;

  //   fields.route_id = route.id;
  //   fields.unit_id  = route.object_id;
  //   fields.step     = route.step;
  //   fields.weight   = route.weight;
  //   fields.default  = route.default;
  // };

  const hide_popups_update = () => {
    popups.update  = false;
    errors.unit_id = false;

    fields.route_id = 0;
    fields.unit_id  = "";
    fields.step     = "";
    fields.weight   = null;
    fields.default  = null;
  };

  const menu = () => {
    // :menu="[{ title: 'Edit', handler: () => show_popups_update(route) }, 'Remove']"
    return [ "Details", "Remove" ];
  };

  const part = () => {
    return { content: true, actions: { menu: true } };
  };

  // function default_update(item) {
  //   fields.default = item.status;
  // };

  function create() {
    if (fields.create != "") {
      emit("create", fields.create, fields.step, fields.weight, fields.default);
      hide_popups_create();
    }
    else {
      errors.create = true;
    }
  };

  function update(id) {
    if (fields.unit_id != "") {
      emit("update", id, fields.unit_id, fields.step, fields.weight, fields.default);
      hide_popups_update();
    }
    else {
      errors.unit_id = true;
    }
  };
</script>

<template>
  <div>
    <div class="box-row">
      <div
        v-for="(route, index) in props.routes"
        :key="route.id"
        class="d-flex flex-row align-items-center w-200px"
      >
        <widget-card
          class="m-5 base-neutral"

          :item="{ name: route.name + (route.default ? ' &#10029;' : ''), description: route.step, weight: route.weight }"
          :menu="menu()"
          :part="part()"

          @remove="emit('remove', route.id)"
        />

        <div v-if="index != props.routes.length - 1" class="m-10">
          >
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center w-20px h-100">
        <div
          class="icon small icon-plus c-pointer"
          @click="popups.create = true"
        />
      </div>
    </div>

    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-select
          v-model="fields.create"
          class="mb-10"
          :items="props.units"
          :selected="false"
          :failure="errors.create"
          :placeholder="$t('insert_role')"
          :message="$t('role_is_empty')"
        />

        <field-input
          v-model="fields.step"
          class="mb-10"
          :placeholder="$t('insert_step')"
        />

        <field-input
          v-model="fields.weight"
          class="mb-10"
          :placeholder="$t('insert_weight')"
        />

        <field-checkbox
          :lang="true"
          :value="{ id: 0, status: fields.default, content: 'Default' }"
          @submit="(item) => fields.default = item.status"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>

    <popup-base v-if="popups.update">
      <template #header>
        <div>{{ $t("update") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popups_update" />
      </template>

      <template #content>
        <field-select
          v-model="fields.unit_id"
          class="mb-10"
          :items="props.units"
          :selected="fields.unit_id"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.unit_id"
        />

        <field-input
          v-model="fields.step"
          class="mb-10"
          :placeholder="$t('insert_step')"
          :message="$t('step_is_empty')"
          :failure="errors.step"
        />

        <field-input
          v-model="fields.weight"
          class="mb-10"
          :placeholder="$t('insert_weight')"
        />

        <field-checkbox
          :lang="true"
          :value="{ id: 0, status: fields.default, content: 'Default' }"
          @submit="(item) => fields.default = item.status"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="update(fields.route_id)">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </div>
</template>