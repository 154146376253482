<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    node: { type: Object, default: () => {} }
  });
</script>

<template>
  <div class="d-flex flex-grow">
    <div class="time d-flex align-items-center">
      <div class="title">
        {{ props.node.spent }}
      </div>
    </div>

    <div class="node">
      <router-link v-if="props.node.id > 0"
        :to="'/node/' + props.node.code"
        class="title text-decoration-none"
      >
        {{ props.node.title }}
      </router-link>

      <div v-else
        class="title text-decoration-none"
      >
        {{ props.node.title }}
      </div>

      <div class="path">
        {{ props.node.path }}
      </div>
    </div>
  </div>
</template>