<script setup>
  import { onMounted, onUnmounted } from "vue";

  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();
  import { useStateStore } from "../../stores/StateStore.js";
  const state = useStateStore();

  import { Hotkeys, Action } from "../../../../packages/hotkeys/Hotkeys";

  import ChartMindmap from "../../../../system/chart/Chart.vue";
  import WidgetCard   from "../../../../visual/widgets/cards/Default.vue";

  const REGION_TREE  = "tree";
  const REGION_LIST  = "list";
  const REGION_CHART = "chart";

  onMounted(async () => {
    Hotkeys.change(REGION_CHART);
    Hotkeys.define([
      new Action(Hotkeys.KEYCODE_ESC  , (nodes) => nodes.forEach((node) => jump_prev(node))),
      new Action(Hotkeys.KEYCODE_ENTER, (nodes) => nodes.forEach((node) => jump_next(node))),
      new Action(Hotkeys.KEYCODE_D    , (nodes) => nodes.forEach((node) => jump_next(node, "desc")), { ctrl: true, shift: true, stop: true }),
      new Action(Hotkeys.KEYCODE_S    , (nodes) => nodes.forEach((node) => jump_sprint(node)), { shift: true }),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => fold(node, "hide")), { ctrl: true }),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => fold(node, "show")), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_LEFT , (nodes) => nodes.forEach((node) => move(node, "left") )),
      new Action(Hotkeys.KEYCODE_UP   , (nodes) => nodes.forEach((node) => move(node, "up")   )),
      new Action(Hotkeys.KEYCODE_RIGHT, (nodes) => nodes.forEach((node) => move(node, "right"))),
      new Action(Hotkeys.KEYCODE_DOWN , (nodes) => nodes.forEach((node) => move(node, "down") )),

      new Action(Hotkeys.KEYCODE_INSERT, (nodes) => nodes.forEach((node) => source.chart_blank_child(node.dataset.id))),
      new Action(Hotkeys.KEYCODE_ENTER , (nodes) => nodes.forEach((node) => source.chart_blank_sibling(node.dataset.id)), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_M  , (nodes) => nodes.forEach((node) => state.switch_context(+node.dataset.id, REGION_CHART)), { ctrl: true }),

      new Action(Hotkeys.KEYCODE_D, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_dead_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_done_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_S, (nodes) => nodes.forEach((node) => node.dataset.branch == "false" && state.switch_step_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_I, (nodes) => nodes.forEach((node) => node.dataset.branch == "true"  && state.switch_work_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_T, (nodes) => nodes.forEach((node) => state.switch_time_show(+node.dataset.id, REGION_CHART))),
      new Action(Hotkeys.KEYCODE_D, () => state.switch_dead_show(-1, REGION_CHART, get_nodes_ids()), { alt: true, stop: true }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_done_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_S, () => state.switch_step_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_T, () => state.switch_time_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
      new Action(Hotkeys.KEYCODE_I, () => state.switch_work_show(-1, REGION_CHART, get_nodes_ids()), { alt: true             }),
    ], REGION_CHART);
    Hotkeys.focused();
  });

  onUnmounted(() => Hotkeys.reinit(REGION_CHART));


  function get_nodes_ids() {
    return source.chart.nodes.map(node => node.entity.id);
  }

  function pick(node) {
    state.node_activate(node)
    Hotkeys.focused(undefined, node.id);
  }

  function jump_prev(node) {
    if (!state.detail.id) {
      if (Hotkeys.change(REGION_TREE)) {
        Hotkeys.toggle(node);
        Hotkeys.focused("tree-item", source.get_active.road_pick);
      }
    }

    state.node_deactivate();
  };

  function jump_sprint(node) {

    if (Hotkeys.change(REGION_LIST)) {
      Hotkeys.toggle(node);
      Hotkeys.focused("list-item");
    }

    state.node_deactivate();
  };

  function jump_next(node, action = "") {
    let target = source.chart.nodes.find(target =>
      target.entity.id == node.dataset.id)
    ;

    target && state.node_activate(target.entity, action);
  };

  function move(node, action) {
    let offset = { left: -1, right: 1, up: 0, down: 0 };
    let target = node;

    while (target) {
      target = ["right", "down"].includes(action) ?
        target.nextSibling :
        target.previousSibling
      ;

      if (target && target.dataset && +target.dataset.level == (+node.dataset.level + offset[action])) {
        Hotkeys.toggle(node);
        Hotkeys.toggle(target);

        target.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});

        break;
      }
    }
  }

  function fold(node, action) {
    let id = source.chart_fold(node, action);

    if (id)
      source.node_folded(id);
  };

  const menu = () => {
    return [
      { title: "Details", separator: true  },
      { title: "Create" , separator: true, items: [ { title: "Child"  , separator: false }, { title: "Sibling", separator: false } ] },
      { title: "Remove" , separator: false },
    ];
  };

  const part = () => { return {
    fold   : false,
    content: false,
    weight : false,
    actions: { menu: true, launch: false, create: false, finish: false },
    diagram: { done: true, work: true, time: true, dead: true, step: true }
  }; };

  function is_selected(node) {
    return !!source.sprints_nodes.find(item => item.nodes_id == node.id && item.milestones_id == state.sprint_pick);
  }
</script>

<template>
  <chart-mindmap
    :data-region="REGION_CHART"
    class="overflow-hidden z-10"

    :nodes="source.chart.nodes"
    :links="source.chart.links"
    :roots="source.chart.roots"

    :pool="source.unused"
    :lock="true"

    @blank_child="(target) => source.node_child(target)"
    @blank_sibling="(target) => source.node_sibling(target)"
    @blank_cancel="() => source.chart_blank_cancel()"

    @node_folded="(id) => source.node_folded(id)"
  >
    <template #node="element">
      <widget-card
        class="chart-item h-100"

        :item="element.node.entity"
        :menu="menu()"
        :part="part()"
        :stages="source.chart.stage[element.node.entity.id]"

        :selected="is_selected(element.node.entity)"
        :region="REGION_CHART"

        :context="state.get_state(element.node.entity.id, REGION_CHART, 'context')"
        :dead_show="state.get_state(element.node.entity.id, REGION_CHART, 'dead_show')"
        :done_show="state.get_state(element.node.entity.id, REGION_CHART, 'done_show')"
        :step_show="state.get_state(element.node.entity.id, REGION_CHART, 'step_show')"
        :time_show="state.get_state(element.node.entity.id, REGION_CHART, 'time_show')"
        :work_show="state.get_state(element.node.entity.id, REGION_CHART, 'work_show')"

        @switch_context="state.switch_context"
        @switch_dead_show="state.switch_dead_show"
        @switch_done_show="state.switch_done_show"
        @switch_step_show="state.switch_step_show"
        @switch_time_show="state.switch_time_show"
        @switch_work_show="state.switch_work_show"

        @pick="(node) => pick(node)"
        @create="(id, type) => source[`chart_blank_${type}`](id)"
        @update="(option, id, value) => source.node_update(option, id, value)"
        @remove="(id) => source.node_remove(id)"
      />
    </template>
  </chart-mindmap>
</template>