import Config from "../../../../config.js";

export default class File {
  constructor( loader, token, nodes_id ) {
    this.loader   = loader;
    this.token    = token;
    this.nodes_id = nodes_id;
  }

  upload() {
    return this.loader.file
      .then( file => new Promise( ( resolve, reject ) => {
        this._initRequest();
        this._initListeners( resolve, reject, file );
        this._sendRequest( file );
      }));
  }

  abort() {
    if ( this.xhr ) { this.xhr.abort(); }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();

    xhr.open( "POST", Config.API + "/api/files/upload?nodes_id=" + this.nodes_id, true );
    xhr.setRequestHeader("Authorization", "Bearer " + this.token);
    xhr.responseType = "json";
  }

  _initListeners( resolve, reject, file ) {
    const xhr              = this.xhr;
    const loader           = this.loader;
    const genericErrorText = `Couldn't upload file: ${ file.name }.`;

    xhr.addEventListener( "error", () => reject( genericErrorText ) );
    xhr.addEventListener( "abort", () => reject() );
    xhr.addEventListener( "load" , () => {
      const response = xhr.response;

      if ( !response || response.error ) {
        return reject( response && response.error ? response.error.message : genericErrorText );
      }

      resolve({ default: Config.API + "/api/files/download?id=" + response.data.files[response.data.files.length - 1].id + '&token=' + this.token, });
    });

    if ( xhr.upload ) {
      xhr.upload.addEventListener( "progress", evt => {
        if ( evt.lengthComputable ) {
          loader.uploadTotal = evt.total;
          loader.uploaded    = evt.loaded;
        }
      });
    }
  }

  _sendRequest( file ) {
    const data = new FormData();
    data.append("files[]", file);
    this.xhr.send( data );
  }
}