import { ref } from "vue";
import { defineStore } from "pinia";

import { Blank, States } from "../../../packages/states/States.js";

export const useStateStore = defineStore("planner_state", () => {
  const search = ref("");

  const detail = ref({});
  const select = ref(-1);

  const states  = ref([]);
  const manager = new States(states.value, NodeState);

  return {
    search,
    detail,
    select,

    get_state: (id, region, field) => {
      return manager.get_state(id, region, field);
    },

    switch_context: (id, region) => {
      if (id > -1) {
        manager.switch_state(id, region, "context");
      }
      else {
        manager.cleanup(["context"]);
      }
    },

    switch_dead_show: (id, region, group = []) => {
      let ids = group.length ? group : [id];

      ids.forEach(id => {
        manager.cleanup(["context"]);
        manager.cleanup(["step_show", "time_show"], id);
        manager.switch_state(id, region, "dead_show", group.length ? true : undefined);
      });
    },

    switch_step_show: (id, region, group = []) => {
      let ids = group.length ? group : [id];

      ids.forEach(id => {
        manager.cleanup(["context"]);
        manager.cleanup(["dead_show", "time_show"], id);
        manager.switch_state(id, region, "step_show", group.length ? true : undefined);
      });
    },

    switch_time_show: (id, region, group = []) => {
      let ids = group.length ? group : [id];

      ids.forEach(id => {
        manager.cleanup(["context"]);
        manager.cleanup(["dead_show", "step_show"], id);
        manager.switch_state(id, region, "time_show", group.length ? true : undefined);
      });
    },

    node_activate: (node, action = "") => {
      detail.value = node;
      detail.value.action = action;
    },

    node_deactivate: () => {
      detail.value = {};
    },
  };
})

class NodeState extends Blank {
  constructor (id, region, field, value) {
    super(id, region);

    this.context   = false;
    this.dead_show = false;
    this.step_show = false;
    this.time_show = false;

    if (value !== undefined)
      this[field] = value;
  };
}