import SYSTEM from "../system.js";

export default class Detail {
  constructor(response) {
    this.access = false;
    this.errors = {};
    this.status = {};
    this.space  = {};

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.space  = response.data.space;
      }
    }
  }
}