<script setup>
  import { ref, onMounted, nextTick, defineProps, defineEmits } from "vue";

  const props = defineProps({
    // eslint-disable-next-line vue/prop-name-casing
    modelValue : { type: String , default: () => ""     },
    label      : { type: String , default: () => ""     },
    type       : { type: String , default: () => "text" },
    placeholder: { type: String , default: () => ""     },
    description: { type: String , default: () => ""     },
    failure    : { type: Boolean, default: () => false  },
    message    : { type: String , default: () => ""     },
    autofocus  : { type: Boolean, default: () => false  }
  });

  const emit = defineEmits([
    "update:modelValue", "close"
  ]);

  const value = ref();

  onMounted(async() => {
    if (props.autofocus) {
      await nextTick();
      value.value.focus();
    }
  });
</script>

<template>
  <div class="field w-100">
    <div v-if="props.label" class="font-soft font-memo mb-8">
      {{ props.label }}
    </div>

    <input
      :ref="(element) => { value = element }"
      :type="props.type"
      :placeholder="props.placeholder"
      :class="{ failure: props.failure.length }"
      :value="props.modelValue"

      @input="emit('update:modelValue', $event.target.value)"
      @blur="emit('close')"
    >

    <div v-if="props.description" class="description font-memo">
      {{ props.description }}
    </div>

    <div v-if="props.failure" class="font-denial font-memo">
      {{ props.message }}
    </div>
  </div>
</template>