export default class Parser {
  constructor (nodes, links = [], roots = []) {
    this._nodes = nodes;
    this._links = links;
    this._roots = roots; // @TODO: Проверить входящий параметр на массив

    /**
     * Nodes in progress.
     * 
     * Level = Currently performed level.
     * Stack = List of nodes in progress.
     */
    this._pool = {
      level: 0,
      stack: [],
    };

    /**
     * Performed nodes data object.
     * 
     * Key   = node id.
     * Value = {
     *   level : <node level>,
     *   nested: <list of nested nodes>
     * }
     */
    this._tree = {};
  }

  finish() {
    return this._pool.stack.length == 0;
  }

  last() {
    return this._pool.stack.slice(-1)[0];
  }

  level() {
    return this._pool.level;
  }

  nested(id) {
    return this._tree[id].nested;
  }

  return_to_parent_branch () {
    return (
      this._pool.stack.length &&
      this._tree[this.last().entity.id].level < this._pool.level
    );
  }

  mark_node_as_done() {
    this._pool.stack.pop();
  }

  move_to_parent() {
    this._pool.level--;
  }

  append_nested(nested, parent) {
    // If args empty, the find roots,
    nested = nested || this.detect_nested();

    if (parent) {
      this._pool.level++;
      this._tree[parent.entity.id].nested = nested;
    }

    this._pool.stack = this._pool.stack.concat(nested);

    nested.forEach(node => {
      this._tree[node.entity.id] = {
        level: this._pool.level
      };
    });
    return nested;
  }

  detect_nested(parent) {
    let nested = [];

    if (!parent) {
      // Find root-level nodes.
      if (this._roots.length) {
        // When we should use sub-tree (for example "Crumbs"-plugin)
        // we need to use this._roots as tree root node.
        let root_id = +this._roots.splice(0, 1);
        nested = this._nodes.filter(node => {
          return +node.entity.id === root_id;
        });
      }
      else {
        // In the other case we just look for nodes which has no parents.
        nested = this._nodes.filter(node => {
          // Node is not mentioned in any link target.
          return !this._links.find(link =>
            link.entity.nodes_target_id === node.entity.id
          );
        });
      }
    }
    else if (parent && !+parent.option.folded) {
      // Find nested-level nodes.
      nested = this._nodes.filter(node => {
        return this._links
          .filter(link => {
            // Links where parent node = link source.
            return parent.entity.id == link.entity.nodes_source_id;
          })
          .find(link => {
            // Link where link target = target node.
            return link.entity.nodes_target_id == node.entity.id;
          })
        ;
      });
    }

    // Sort by offset using descending order.
    // nested.sort((entity1, entity2) => { if (entity1.option.offset > entity2.option.offset) return 1; if (entity1.option.offset < entity2.option.offset) return -1; return 0; });
    return nested.reverse();
  }
}