<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    string: { type: String, default: () => "" }
  });
</script>

<template>
  <div v-if="props.string"
    class="font-soft short-text my-4"

    @click.exact.stop.prevent
  >
    {{ props.string.replace(/<[^>]+>/g, " ") }}
  </div>
</template>