<script setup>
  import { defineProps } from "vue";

  import { reactive  } from "vue";
  import { useRouter } from "vue-router";

  import WidgetTabs from "../../../../_visual/widgets/tabs/Tabs.vue";

  const props = defineProps({
    id    : { type: String, default: () => "" },
    active: { type: Number, default: () => 0  }
  });

  const router = useRouter();

  const tabs = reactive([
    { id: 1, name: "Details" , active: false                  },
    { id: 2, name: "Modules" , active: false, url: "modules"  },
    { id: 3, name: "Roles"   , active: false, url: "roles"    },
    { id: 4, name: "Types"   , active: false, url: "chains"   },
    { id: 5, name: "Calendar", active: false, url: "calendar" },
    { id: 6, name: "Factors" , active: false, url: "factors"  }
  ]);

  tabs[props.active].active = true;

  function activate(tab) {
    tabs.map(function(tab) { tab.active = false; });
    tab.active = tab.id;

    let path = tab.url &&
      "/spaces/" + props.id + "/" + tab.url ||
      "/spaces/" + props.id
    ;

    router.push({ path: path });
  };
</script>

<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap base-neutral border-radius-8 mb-16 p-8">
    <div class="font-title">
      {{ $t("Organization") }}
    </div>

    <widget-tabs
      :tabs="tabs"
      @activate="activate"
    />
  </div>
</template>