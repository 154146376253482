export default {
  ru: {
    Calendar       : "Календарь",
    Rate           : "Ставка",
    insert_rate    : "Укажите ставку",
    insert_color   : "Укажите цвет",
    color_is_empty : "Цвет не указан",
    clear          : "Очистить",
    insert_duration: "Укажите продолжительность рабочего дня",
  },
  en: {
    Calendar       : "Calendar",
    Rate           : "Rate",
    insert_rate    : "Insert rate",
    insert_color   : "Insert color",
    color_is_empty : "Сolor is empty",
    clear          : "Сlear",
    insert_duration: "Insert duration",
  }
};