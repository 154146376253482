
export default class Blank {
  static bundle() {
    return {
      link: {
        entity: { id: 0, nodes_source_id: 0, nodes_target_id: 0, type: 1, created: null },
        option: { id: 0, nodes_links_id: 0, style: null }
      },
      node: {
        entity: { target: 0, type: "", id: 0, title: "", deadline: null, branch: false, state: { weight: null } },
        option: { id: 0, nodes_id: 0, level: 0, offset: 0, folded: 0, style: null }
      }
    };
  }

  static blank(chart, id, type) {
    let source_link = chart.links && chart.links.find(link => link.entity.nodes_target_id == id);
    let source_node = chart.nodes && chart.nodes.find(node => node.entity.id == id);

    Blank.cancel(chart);

    return Blank[type](id, type, source_link, source_node, chart);
  }

  static cancel(chart) {
    chart.links = chart.links && chart.links.filter(link => link.entity.id != 0);
    chart.nodes = chart.nodes && chart.nodes.filter(node => node.entity.id != 0);
    chart.roots = chart.roots && chart.roots.filter(root => root != 0);
  }

  static child(id, type, source_link, source_node, chart) {
    let bundle = Blank.bundle();

    let child_links = chart.links && chart.links.filter(link => link.entity.nodes_source_id == id).map(link => link.entity.nodes_target_id);
    let child_nodes = chart.nodes && chart.nodes.filter(node => child_links.includes(node.entity.id));

    let last_node = child_nodes.length ? child_nodes.reduce((prev, next) => prev.option.offset > next.option.offset ? prev : next) : false

    bundle.link.entity.nodes_source_id = source_link && source_link.entity.nodes_target_id || id;
    bundle.node.entity.target          = id;
    bundle.node.entity.type            = type;
    bundle.node.option.level           = source_node && source_node.option.level + 1 || 0;
    bundle.node.option.offset          = last_node && last_node.option.offset + 1 || 0;

    let index_link = last_node ?
      chart.links && chart.links.findIndex((link) => link.entity.nodes_target_id == last_node.entity.id) :
      chart.links && chart.links.findIndex((link) => link.entity.nodes_target_id == source_node.entity.id);

    let index_node = last_node ?
      chart.nodes && chart.nodes.findIndex((node) => node.entity.id == last_node.entity.id) :
      chart.nodes && chart.nodes.findIndex((node) => node.entity.id == source_node.entity.id);

    chart.links.splice(index_link + 1, 0, bundle.link);
    chart.nodes.splice(index_node + 1, 0, bundle.node);

    if (!source_link) {
      let index_root = chart.roots.findIndex((root) => root == source_node.entity.id);
      chart.roots.splice(index_root + 1, 0, 0);
    }

    return bundle;
  }

  static sibling(id, type, source_link, source_node, chart) {
    let bundle = Blank.bundle();

    bundle.link.entity.nodes_source_id = source_link && source_link.entity.nodes_source_id || null;
    bundle.node.entity.target          = id;
    bundle.node.entity.type            = type;
    bundle.node.option.level           = source_node.option.level;
    bundle.node.option.offset          = source_node.option.offset + 0.5;

    let index_link = chart.links.findIndex((link) => link.entity.nodes_target_id == source_node.entity.id);
    let index_node = chart.nodes.findIndex((node) => node.entity.id == source_node.entity.id);

    chart.links.splice(index_link + 1, 0, bundle.link);
    chart.nodes.splice(index_node + 1, 0, bundle.node);

    if (!chart.links.find(link => link.entity.nodes_target_id == source_link.entity.nodes_source_id)) {
      let index_root = chart.roots.findIndex((root) => root == source_node.entity.id);
      chart.roots.splice(index_root + 1, 0, 0);
    }

    return bundle;
  }

  static fold(chart, node, action) {
    let target = chart.nodes.find(target => target.entity.id == node.dataset.id);

    if (
      action == "hide" && target.entity.folded && !target.option.folded ||
      action == "show" && target.entity.folded && target.option.folded
    ) return target.entity.id;

    return null;
  }
}