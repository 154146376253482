import Parser from "./layout/Parser";
import Region from "./layout/Region";

export default class Layout {
  static detect(nodes, links, roots, layout) {
    let locations =
      detect_locations(nodes, links, roots, layout)
    ;

    return {
      scene: detect_scene_location(locations),
      nodes: detect_nodes_location(locations),
      links: detect_links_location(links, locations),
      folds: detect_folds_location(nodes, locations)
    };
  }
}

function detect_locations(nodes, links, roots, layout) {
  let parser = new Parser(nodes, links, roots);
  let region = new Region(function () {});

  let locations = {};

  while (roots.length) { // @TODO: Roots are spliced inside append_nested. Need to correct the algorithm

    parser.append_nested();

    while (!parser.finish()) {
      let node   = parser.last();
      let nested = parser.detect_nested(node);

      if (nested.length) {
        // Node has children.
        parser.append_nested(nested, node);
        region.create_nested(parser.level(), layout[node.entity.id].width);
      }
      else {
        // Node has NO children.
        locations[node.entity.id] = {
          left: region.detect_left(parser.level()),
          top : region.detect_next_top(parser.level(), layout[node.entity.id].height),

          width : layout[node.entity.id].width,
          height: layout[node.entity.id].height,
        };

        parser.mark_node_as_done();

        while (parser.return_to_parent_branch()) {
          // We go to level upper (nested level is processed completely).
          node = parser.last();

          parser.mark_node_as_done();
          parser.move_to_parent();

          locations[node.entity.id] = {
            left: region.detect_left(parser.level()),
            top : region.detect_next_top(parser.level(), layout[node.entity.id].height),

            width : layout[node.entity.id].width,
            height: layout[node.entity.id].height,
          };

          let difference = region.parent_bigger_than_nested(
            parser.level(), layout[node.entity.id].height
          );

          if (difference > 0) {
            // Parent bigger than nested.
            parser.nested(node.entity.id).forEach(nested => {
              // Increase nested node top to half of difference between levels.
              locations[nested.entity.id].top += difference;
            });
          }
          else if (difference < 0) {
            // Parent less than nested.
            region.equate_parent_height_to_nested_height(parser.level());

            // We need to align parent node to the vertical center of nested node group.
            locations[node.entity.id].top = region.detect_middle_top(parser.level() + 1, layout[node.entity.id].height);
          }
        }
      }
    }

  }

  return locations;
}

function detect_scene_location(locations) {
  let size = {
    width : 0,
    height: 0,
  };

  Object.values(locations).forEach(location => {
    size.width  = Math.max(size.width, location.left + location.width);
    size.height = Math.max(size.height, location.top + location.height);
  });

  return size;
}

function detect_nodes_location(locations) {
  let layout = {};

  for (var id in locations) {
    layout[id] = 'left:' + locations[id].left + 'px; top:' + locations[id].top + 'px; width:' + locations[id].width + 'px; height:' + locations[id].height + 'px;';
  }

  return layout;
}

function detect_links_location(links, locations) {
  let layout = {};

  links.forEach(link => {
    if (link.entity.nodes_source_id) {
      let source = locations[link.entity.nodes_source_id];
      let target = locations[link.entity.nodes_target_id];

      if (target && source) {
        let $svg = `M ${source.left + source.width}, ${source.top + source.height / 2} `; // @INFO: https://svg-path-visualizer.netlify.app/

        if ((source.top + source.height / 2) != (target.top + target.height / 2)) {
          $svg += `L ${source.left + source.width + 5 }, ${source.top + source.height / 2} `;
          $svg += `Q ${source.left + source.width + 15}, ${source.top + source.height / 2}
                     ${source.left + source.width + 15}, ${source.top + (source.height / 2) + ((source.top + source.height / 2) > (target.top + target.height / 2) ? -4 : 4)} `;
          $svg += `L ${source.left + source.width + 15}, ${target.top + (target.height / 2) + ((source.top + source.height / 2) < (target.top + target.height / 2) ? -4 : 4)} `;
          $svg += `Q ${source.left + source.width + 15}, ${target.top + target.height / 2}
                     ${source.left + source.width + 25}, ${target.top + target.height / 2} `;
        }

        $svg += `L ${source.left + source.width + 30}, ${target.top + target.height / 2}`;

        layout[link.entity.id] = $svg;
      }
    }
  });

  return layout;
}

function detect_folds_location(nodes, locations) {
  let layout = {};

  nodes.forEach(node => {
    if (node.entity.branch) {
      let position = locations[node.entity.id];
      if (position) {
        layout[node.entity.id] = {
          x: position.left + position.width + 15,
          y: position.top  + position.height / 2,
          r: 7,
          f: node.option.folded
        };
      }
    }
  });

  return layout;
}