import { ref, computed } from "vue";
import { defineStore } from "pinia";

import SYSTEM from "../system.js";
import SERVICE from "../services/Roster.js";

import CHART from "../../../system/chart/entities/Blank.js";

export const useSourceStore = defineStore("roadmap_source", () => {
  const access        = ref(false);
  const errors        = ref({});
  const status        = ref({});
  const projects      = ref([]);
  const milestones    = ref([]);
  const sprints       = ref([]);
  const sprints_nodes = ref([]);
  const unused        = ref([]);
  const chart         = ref([]);

  const is_access = computed(() => {
    return Object.values(access.value).includes(SYSTEM.MODULE);
  });

  const get_active = computed(() => {
    return projects.value.find(project => project.active && project.hidden != 1);
  });

  const get_visible = computed(() => {
    return projects.value.filter(project => project.hidden != 1);
  });

  function reload(response) {
    if (response.data) {
      access.value        = response.access;
      errors.value        = response.errors;
      status.value        = response.status;
      projects.value      = response.data.projects;
      milestones.value    = response.data.milestones;
      sprints.value       = response.data.sprints;
      sprints_nodes.value = response.data.sprints_nodes;
      unused.value        = response.data.unused;
      chart.value         = response.data.chart;
    }

    if (!chart.value.nodes) {
      chart.value.links = [];
      chart.value.nodes = [];
      chart.value.roots = [];

      chart_blank_child(null);
    }
  }

  function reload_unused(response) {
    if (response.data) {
      unused.value = response.data.unused;
    }
  }

  function chart_fold(node, action) {
    CHART.fold(chart.value, node, action);
  };

  async function chart_blank_child(id) {
    let blank = CHART.blank(chart.value, id, "child");
    reload_unused(await SERVICE.load_unused(get_active.value.id, blank.link.entity.nodes_source_id));
  };

  async function chart_blank_sibling(id) {
    let blank = CHART.blank(chart.value, id, "sibling");
    reload_unused(await SERVICE.load_unused(get_active.value.id, blank.link.entity.nodes_source_id));
  };

  function chart_blank_cancel() {
    CHART.cancel(chart.value);
  };

  return {
    access,
    errors,
    status,
    projects,
    milestones,
    sprints,
    sprints_nodes,
    unused,
    chart,

    is_access,

    get_active,
    get_visible,

    chart_fold,
    chart_blank_child,
    chart_blank_sibling,
    chart_blank_cancel,

    load: async () => reload(await SERVICE.load()),

    milestone_pick: async (id) => reload(await SERVICE.states(get_active.value.id, "road_pick", id)),

    milestone_create: async (value) => reload(await SERVICE.create_milestone(get_active.value.id, value)),

    milestone_shipped: async (id) => reload(await SERVICE.shipped_milestone(id)),

    milestone_remove: async (id) => reload(await SERVICE.remove_milestone(id)),

    milestone_update: async (option, id, value) => reload(await SERVICE.update_milestone(id, option, value)),

    milestone_order: async (target, source, offset) => reload(await SERVICE.order_milestone(target, source, offset)),

    sprint_create: async (id) => reload(await SERVICE.create_sprint(id)),

    sprint_break: async (id, value) => reload(await SERVICE.break_sprint(id, +value)),

    node_folded: async (id) => reload(await SERVICE.states(get_active.value.id, "node_fold", id)),

    node_child: async (target) => {
      reload(await SERVICE.node_append(get_active.value.road_pick, target.node_id));
      chart_blank_child(target.id);
    },

    node_sibling: async (target) => {
      reload(await SERVICE.node_append(get_active.value.road_pick, target.node_id));
      chart_blank_sibling(target.id);
    },

    node_move: async (event, id) => {
      if (id && id != event.dataTransfer.getData("node_id")) {
        reload(await SERVICE.node_move(id, event.dataTransfer.getData("node_id")));
      }
    },

    node_remove: async (id) => reload(await SERVICE.node_remove(id)),

    project_activate: async (id) => reload(await SERVICE.states(id, "project_active", id)),

    project_visible : async (id) => reload(await SERVICE.states(id, "project_show"  , id)),

    project_priority: async (event) => event.moved &&
      reload(await SERVICE.priority(get_visible.value.map(project => { return { id: project.id }; })))
  }
})