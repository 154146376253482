<script setup>
  import { ref, watch, onMounted, defineProps, defineEmits } from "vue";

  const props = defineProps({
    source: { type: String, default: "" },
    target: { type: Number, default: 0  }
  });

  const emit = defineEmits([
    "transfer"
  ]);

  const teleport = ref("body");

  onMounted(() => teleport.value = ".charts-canvas");

  watch([ () => props.source, () => props.target ], ([source, target]) => {
    teleport.value = target && source != target ? `.node-${target}` : ".charts-canvas";
  });

  function drop(location) {
    emit("transfer", { node_id: props.source, target_id: props.target, location: location });
  }
</script>

<template>
  <teleport :to="teleport">
    <div class="target target-above" @drop="drop('LOCATION_BEFORE')" @dragover.prevent @dragenter.prevent />
    <div class="target target-under" @drop="drop('LOCATION_AFTER' )" @dragover.prevent @dragenter.prevent />
    <div class="target target-child" @drop="drop('LOCATION_CHILD' )" @dragover.prevent @dragenter.prevent />
  </teleport>
</template>

<style lang="scss" scoped>
  div.charts-canvas {
    .target {
      width: 68%;
      height: 30px;
      border: 1px dashed grey;
      position: absolute;
      display: none;
    }

    .target-above {
      top:0;
      left: 0;
    }

    .target-under {
      left: 0;
      bottom: 0;
    }

    .target-child {
      top: 0;
      right: 0;
      width: 30%;
      height: 100%;
    }

    .target-over {
      border: 3px dashed grey;
    }

    .node {
      .target {
        display: block;
      }
    }

    .complex {
      .target-child {
        width: 0%;
        border: 0;
      }

      .target-above {
        width: 100%;
      }

      .target-under {
        width: 100%;
      }
    }
  }
</style>