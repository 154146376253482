import Server from "../../../system/server/server";
import Entity from "../entities/Roster.js";
import Chain  from "../entities/Chain.js";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("schedule"));
  }

  static async load_chains(project_id) {
    return new Chain(await Server.get("schedule/" + project_id + "/chains"));
  }

  static async move(node) {
    return new Entity(await Server.put("schedule/move/" + node.node_id, {
      target: node.target
    }));
  }

  static async create_node(day, fields) {
    return new Entity(await Server.post("schedule/create", {
      day    : day,
      title  : fields.title,
      project: fields.project,
      chain  : fields.chain
    }));
  }

  static async update_node(day, node_id) {
    return new Entity(await Server.put("schedule/update/" + node_id, {
      day: day
    }));
  }

  static async clear_node(node_id) {
    return new Entity(await Server.get("schedule/clear/" + node_id));
  }

  static async finish_node(data) {
    return new Entity(await Server.post("schedule/finish", { status_state_id: data.status_state_id }));
  }

  static async states(project_id, option, value, keyword = "") {
    return new Entity(await Server.post("schedule/states", {
      id     : project_id,
      option : option,
      value  : value,
      keyword: keyword
    }));
  }

  static async priority(projects, keyword = "") {
    return new Entity(await Server.post("schedule/priority", {
      list   : projects,
      keyword: keyword
    }));
  }
}