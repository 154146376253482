<script setup>
  import { useSourceStore } from "../../stores/SourceStore.js";
  const source = useSourceStore();

  import WidgetProjects    from "../../../../visual/widgets/projects/Projects.vue";
  import WidgetFilterCombo from "../../../../visual/widgets/filters/Combo.vue";
</script>

<template>
  <div class="d-flex justify-content-between w-100">
    <widget-projects
      :projects="source.get_visible"
      @activate="(id) => source.project_activate(id)"
      @priority="(event) => source.project_priority(event)"
    />

    <div class="d-flex align-items-center">
      <widget-filter-combo
        class="ml-8"
        :projects="source.projects"
        @projects_handler="(id) => source.project_visible(id)"
      />
    </div>
  </div>
</template>