<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    item: { type: Object, default: () => {} }
  });
</script>

<template>
  <div class="node d-flex c-pointer">
    <div class="name flex-grow">
      <div class="title">
        <div class="d-flex">
          <div class="short-title">
            {{ props.item.title }}
          </div>

          <div v-if="props.item.type" class="font-light ml-5">
            ({{ props.item.time }})
          </div>
        </div>

        <div v-if="props.item.type" class="d-flex font-light font-memo">
          <div>{{ $t(props.item.type) }}</div>

          <div v-if="props.item.deadline" class="ml-5">
            ( {{ props.item.deadline }} )
          </div>
        </div>
      </div>

      <div class="path">
        {{ props.item.path }}
      </div>
    </div>

    <div class="time">
      <div class="title">
        {{ props.item.spent }}
      </div>
    </div>
  </div>
</template>